import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link, Redirect } from 'react-router-dom'
import { loginWatcher, signupWatcher } from '../redux/actionCreators/authActions'
import $ from 'jquery';

const Login = ({ loginWatcher, isAuthenticated, signupWatcher }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })
    // const { email, password } = formData;
    const { email, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = (e) => {
        e.preventDefault();
        new Promise((resolve, reject) => {
            loginWatcher(formData, resolve, reject)
        }).catch(e => { })
        
    }

    useEffect(() => {
        $('.message a').click(function () {
            $('.toggleForm').animate({ height: "toggle", opacity: "toggle" }, "slow");
        });
    }, [])

    /*if (isAuthenticated) {
        return <Redirect to="/" />
    }*/

    return (
        <div>
            <section className="loginBox backBox">
                <div className="login-page">
                    <div className="form">
                        <form className="register-form toggleForm">
                            <input type="name"
                                name="firstName"
                                placeholder="First Name"
                                
                                onChange={e => onChange(e)}
                            />
                            <input type="text"
                                name="lastName"
                                placeholder="Last Name"
                             
                                onChange={e => onChange(e)}
                            />
                            <input type="text"
                                name="email"
                                placeholder="Email"
                                value={email}
                                onChange={e => onChange(e)}
                            />
                            <input type="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                onChange={e => onChange(e)}
                            />
                            <button>create</button>
                            <p className="message">Already registered? <a href="javascript:void(0)">Sign In</a></p>
                        </form>
                        <form className="login-form toggleForm" onSubmit={e => onSubmit(e)}>
                            <input type="email"
                                placeholder="Email"
                                name="email"
                                value={email}
                                onChange={e => onChange(e)}
                            />
                            <input type="password"
                                placeholder="Password"
                                name="password"
                                value={password}
                                onChange={e => onChange(e)}
                                minLength='6'
                            />
                            <button>login</button>
                            <p className="message">Not registered? <Link to="/signup">Create an account</Link></p>
                            <p className="message">Forget Password? <Link to="/forgetpassword">Click here</Link></p>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    requestUIHandler: state.requestUIHandler
});

const mapDispatchToProps = dispatch => bindActionCreators(
    { loginWatcher, signupWatcher }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login)
