import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
 
export const translationStrings = new LocalizedStrings({
    en: {
        cartime_users_list_heading: "User(s) List",
        resellers_users_list_heading: "Reseller User(s) List",
        common_no_data_found_msg: "No Data Found",
        common_datatable_sr_no: "Sr. No",
		common_reseller: "Reseller",
		common_lbl_name: "Name",
		common_lbl_email: "Email",
		common_lbl_role: "Role",
		common_lbl_country: "Country",
		common_lbl_city: "City",
		common_datatable_actions: "Actions",
		common_lbl_new_password: "New Password",
		common_lbl_confirm_new_password: "Confirm New Password",
		common_lbl_password_match_error_msg:"New Password and Confirm New Password does not match.",
		common_lbl_description:"Description",
		common_lbl_total_pricing_count:"Total Pricing Count",
		common_lbl_first_name:"First Name",
		common_lbl_last_name:"Last Name",
		common_lbl_contact_number:"Contact Number",
		common_lbl_language_preference:"Language Preference",
		common_lbl_company_name:"Company Name",
		common_lbl_contract_info:"Contract info",
		common_lbl_vat:"CVR",
		common_lbl_vat_percentage:"VAT Percentage",
		common_lbl_contact_email:"Contact Email",
		common_lbl_website:"Website",
		common_lbl_address_line_1:"Address Line 1",
		common_lbl_address_line_2:"Address Line 2",
		common_lbl_address_line_3:"Address Line 3",
		common_lbl_datatable_no_records_found:"No Records Found",
		common_datatable_lbl_showing:"Showing",
		common_datatable_lbl_to:"to",
		common_datatable_lbl_of:"of",
		common_datatable_lbl_entries:"entries",
		common_lbl_success:"Success",
		common_lbl_password:"Password",
		common_lbl_confirm_password:"Confirm Password",
		common_lbl_code:"Code",
		common_lbl_country_coverage:"Country Coverage",
		common_lbl_show:"Show",
		common_lbl_hide:"Hide",
		default_loading_msg : "Please wait...",
		reseller_car_license_plate_label:"License Plate",
		reseller_car_telemetry_label:"Telemetry IMEI",
		reseller_car_name_label:"Make and Model",
		datatable_coloumn_current_assigned_fleet_customer:"Current Assigned Fleet Customer",
		datatable_coloumn_current_allocated_end_user:"Current Allocated Driver",
		reseller_car_label:"Car",
		reseller_car_vin_label:"VIN",
		reseller_car_alloted_start_date_label:"Alloted Start Date",
		reseller_car_alloted_end_date_label:"Alloted End Date",
		total_mapped_fleet_customers_label:"Total Mapped Fleet Customers",
		total_mapped_end_users_label:"Total Mapped  Drivers",
		datatable_search_placeholder_for_cartime_in_reseller : "Search by reseller, name, email, role, country and other fields",
		datatable_search_placeholder_for_reseller:"Search by name, email, role, country and other fields",
		datatable_search_placeholder_for_car_parking_provider_list:"Search by code, name, coverage country, currency",
		datatable_search_placeholder_for_cartime_pricing_plan: "Search by name, description",
		datatable_search_placeholder_for_cartime_pricing_plan_pricing_dtls: "Search by Pricing Plan",
		datatable_search_placeholder_for_resellers:"Search by company name, CVR, contact email, currency and other related fields",
		datatable_search_placeholder_for_reseller_user_pricing_plan:"Search by reseller, name, description",
		datatable_search_placeholder_for_fleet_customer_list:"Search by reseller, company name, CVR, contact email, country",
		datatable_search_placeholder_for_fleet_customer_list_for_reseller:"Search by company name, CVR, contact email, country",
		datatable_search_placeholder_for_fleet_customer_users_list:"Search by reseller, fleet customer, name, email",
		datatable_search_placeholder_for_fleet_customer_users_list_for_fleet:"Search by name, email, role, country",
		datatable_search_placeholder_for_end_users_list_for_fleet:"Search by name, email, country",
		datatable_search_placeholder_for_reseller_list:"Search by fleet customer, name, email, role, country",
		datatable_search_placeholder_for_end_user_list:"Search by reseller, fleet customer, name, email, country",
		datatable_search_placeholder_for_end_user_for_reseller_list:"Search by fleet customer, name, email, country",
		datatable_search_placeholder_fleet_customer_car_map_list:"Search by reseller, fleet customer, car, license plate",
		datatable_search_placeholder_end_user_car_map_list:"Search by reseller, fleet customer, driver, car, license plate",
		datatable_search_placeholder_fleet_customer_car_map_list_for_reseller:"Search by fleet customer, car, license plate",
		datatable_search_placeholder_fleet_customer_car_map_list_for_fleet_customer:"Search by car, license plate",
		datatable_search_placeholder_reseller_car_map_list:"Search by reseller, make and model, license plate, telemetry IMEI",
		datatable_search_placeholder_car_map_list_for_reseller:"Search by make and model, license plate, telemetry IMEI",
		btn_title_delete: "Delete",
		btn_title_edit: "Edit",
		btn_title_edit_pricing_details: "Edit Pricing Details",
		btn_title_deactivate: "Deactivate",
		btn_title_activate: "Activate",
		btn_title_reset_pwd: "Reset Password",
		lbl_select: "---Select---",
		btn_title_yes:"Yes",
		btn_title_no:"No",
		btn_title_search:"Search",
		btn_add: "ADD",		
		btn_submit:"Submit",
		btn_cancle:"Cancel",
		common_lbl_priority:"Priority",
		common_lbl_currency:"Currency",
		common_lbl_start_time:"Start Time",
		common_lbl_end_time:"End Time",
		car_parking_provider_list_page_title:"Car Parking Provider(s) List",
		add_cartime_pricing_plan_datatable_title: "Pricing Plan(s) List",
		add_cartime_pricing_plan_pricing_dtls_datatable_title: "Pricing Details List",
		mapped_fleet_customers_list_datatable_title: "Fleet Customer(s) Car(s) Map List",
		mapped_end_users_list_datatable_title: "Driver(s) Car(s) Map List",
		add_cartime_pricing_plan_page_title: "Add Pricing Plan",
		edit_cartime_pricing_plan_page_title: "Edit Pricing Plan",
		add_cartime_pricing_plan_field_name: "Pricing Plan Name",
		add_cartime_pricing_plan_field_description: "Description",
		add_cartime_pricing_plan_field_system_use_fee: "System Use Fee",
		add_cartime_pricing_plan_field_system_use_fee_reseller: "System Use Fee For Reseller",
		add_cartime_pricing_plan_field_system_use_fee_fleet_customer: "System Use Fee For Fleet Customer",
		add_cartime_pricing_plan_field_signon_fee:"Sign On fee",
		add_cartime_pricing_plan_field_signon_fee_new_registered_cars:"Sign On Fee For New Registered Cars",
		add_cartime_pricing_plan_field_signon_fee_for_reseller:"Sign On Fee For Reseller",
		add_cartime_pricing_plan_field_signon_fee_for_fleet_customer:"Sign On Fee For Fleet Customer",
		add_cartime_pricing_plan_field_subscription_fee_per_active_car:"Subscription Fee per Active Car",
		add_cartime_pricing_plan_field_subscription_fee_per_active_user:"Subscription Fee Per Active Reseller System User of CPS",
		add_cartime_pricing_plan_field_subscription_fee_per_active_fleet_user:"Subscription Fee Per Active Fleet Customer System User of CPS",
		add_cartime_pricing_plan_field_subscription_fee_for_private_parking: "Subscription Fee for Private Parking",
		add_cartime_pricing_plan_field_subscription_fee_for_invoicing_of_fleet:"Subscription fee for Fleet Customer Invoice",
		add_cartime_pricing_plan_field_fees_type: "Fees Type",
		add_cartime_pricing_plan_field_fix_commission_fee_per_parking: "Fix Commission Fee per Parking",
		add_cartime_pricing_plan_field_fix_commission_fee_per_hour_parking: "Fix Commission Fee per hour per Parking",
		add_cartime_pricing_plan_field_percentage_add_on_per_parking: "Percentage Add-on per Parking",
		add_cartime_pricing_plan_field_fees_value:"Fees Value",
		add_cartime_pricing_plan_field_fees_percentage: "Fees Percentage",
		add_cartime_pricing_plan_field_monthly_recurring_fees: "Recurring Fees",
		add_cartime_pricing_plan_field_signon_fees: "One Time Fees",
		add_cartime_pricing_plan_field_transaction_fees: "Transaction Fees",
		add_cartime_pricing_plan_field_from_date: "From Date",
		add_cartime_pricing_plan_field_to_date: "To Date",
		add_cartime_pricing_plan_datatable_coloumn_start_date: "Start Date",
		add_cartime_pricing_plan_datatable_coloumn_end_date: "End Date",
		edit_car_parking_provider_page_title:"Edit Car Parking Provider",
		car_location_provider_list_page_title:"Car Location Provider(s) List",
		edit_car_location_provider_page_title:"Edit Car Location Provider",
		reseller_pricing_plan_list_page_title:"Reseller Pricing Plan(s) List",
		reseller_pricing_plan_pricing_dtls_list_page_title:"Reseller Pricing Details List",
		edit_profile_page_title:"Edit Profile",
		edit_company_profile_page_title:"Edit Company Profile",
		change_password_page_title:"Change Password",
		common_lbl_old_password:"Old Password",
		menu_lbl_for_admin_users: "Admin Users",
		menu_lbl_for_suppliers: "Suppliers",
		menu_lbl_for_customers: "Customers",
		menu_lbl_for_products: "Products",
		menu_lbl_for_data_mgmt:"Data Management",
		menu_lbl_for_manage_pricing_plans:"Manage Pricing Plans",
		menu_lbl_for_car_parking_providers:"Car Parking Providers",
		menu_lbl_for_car_location_providers:"Car Location Providers",
		menu_lbl_for_manage_cars:"Manage Cars",
		menu_lbl_for_reports:"Reports",
		menu_lbl_for_parking_reports:"Parking Reports",
		menu_lbl_for_periodic_job_reports:"Periodic Jobs",
		menu_lbl_for_users:"Users",
		menu_lbl_for_resellers:"Resellers",
		menu_lbl_for_manage_resellers:"Manage Resellers",
		menu_lbl_for_manage_reseller_users:"Manage Reseller Users",
		menu_lbl_for_manage_reseller_cars:"Manage Reseller Cars",
		menu_lbl_for_manage_reseller_pricing_plans:"Manage Reseller Pricing Plans",
		menu_lbl_for_fleet_customers:"Fleet Customers",
		form_lbl_for_fleet_customer:"Fleet Customer",
		menu_lbl_for_manage_fleet_customers:"Manage Fleet Customers",
		menu_lbl_for_manage_fleet_customer_users:"Manage Fleet Customer Users",
		menu_lbl_for_logout:"Logout",
		menu_lbl_for_end_users:"Drivers",
		lbl_for_end_user:"Driver",
		text_hi:"Hi",
		forgot_password:"Forgot Password",
		forgot_password_login_text:"Forgot Password?",
		edit_user_page_title:"Edit User",
		add_user_page_title:"Add User",
		edit_end_user_page_title:"Edit Driver",
		add_end_user_page_title:"Add Driver",
		edit_reseller_user_page_title:"Edit Reseller User",
		add_reseller_user_page_title:"Add Reseller User",
		edit_reseller_car_page_title:"Edit Reseller Car",
		edit_reseller_car_page_title_for_reseller:"Edit Car",
		add_fleet_customer_car_map_page_title:"Fleet Customer Car Map",
		add_end_user_car_map_page_title:"Driver Car Map",
		edit_fleet_customer_car_map_page_title:"Edit Fleet Customer Car Map",
		edit_end_user_car_map_page_title:"Edit Driver Car Map",
		edit_car_map_page_title:"Edit Mapped Car",
		add_reseller_car_page_title:"Add Reseller Car",
		add_reseller_car_page_title_for_reseller:"Add Car",
		edit_reseller_pricing_plan_page_title:"Edit Reseller Pricing Plan",
		add_reseller_pricing_plan_page_title:"Add Reseller Pricing Plan",
		state_province:"State/Province",
		common_lbl_zip_code:"Zip Code",
		lbl_go_to_login_text:"Go to Login",
		login_text:"Login",
		lbl_login_as_user:"Login as user",
		edit_reseller_page_title:"Edit Reseller",
		add_reseller_page_title:"Add Reseller",
		add_fleet_customer_page_title:"Add Fleet Customer",
		add_fleet_customer_user_page_title:"Add Fleet Customer User",
		edit_fleet_customer_user_page_title:"Edit Fleet Customer User",
		edit_fleet_customer_page_title:"Edit Fleet Customer",
		add_reseller_form_lbl_information_source:"Information Source",
		add_reseller_form_lbl_allow_private_parking:"Allow Private Parking",
		add_reseller_form_lbl_service_start_date:"Service Start Date",
		add_reseller_form_lbl_service_end_date:"Service End Date",
		add_fleet_customer_form_lbl_business_days:"Business Days",
		add_fleet_customer_form_lbl_business_hours:"Business Hours",
		// label for popup heading starts
		confirmation_popup_heading_activate_pricing_plan:"Activate Pricing Plan",
		confirmation_popup_heading_deactivate_pricing_plan:"Deactivate Pricing Plan",
		confirmation_popup_heading_delete_pricing_plan:"Delete Pricing Plan",
		confirmation_popup_heading_delete_pricing_plan_pricing_details:"Delete Pricing Details",
		confirmation_popup_heading_activate_parking_provider:"Activate Parking Provider",
		confirmation_popup_heading_deactivate_parking_provider:"Deactivate Parking Provider",
		confirmation_popup_heading_activate_location_provider:"Activate Location Provider",
		confirmation_popup_heading_deactivate_location_provider:"Deactivate Location Provider",
		confirmation_popup_heading_activate_user:"Activate User",
		confirmation_popup_heading_deactivate_user:"Deactivate User",
		confirmation_popup_heading_delete_user:"Delete User",
		confirmation_popup_heading_activate_reseller:"Activate Reseller",
		confirmation_popup_heading_deactivate_reseller:"Deactivate Reseller",
		confirmation_popup_heading_reset_user_password:"Reset User Password",
		confirmation_popup_heading_delete_reseller:"Delete Reseller",
		confirmation_popup_heading_activate_fleet_customer:"Activate Fleet Customer",
		confirmation_popup_heading_deactivate_fleet_customer:"Deactivate Fleet Customer",
		confirmation_popup_heading_delete_fleet_customer:"Delete Fleet Customer",
		confirmation_popup_heading_activate_end_user:"Activate Driver",
		confirmation_popup_heading_deactivate_end_user:"Deactivate Driver",
		confirmation_popup_heading_delete_end_user:"Delete Driver",
		confirmation_popup_heading_delete_fleet_customer_car_map:"Delete Fleet Customer Car Map",
		confirmation_popup_heading_delete_end_user_car_map:"Delete Driver Car Map",
		confirmation_popup_heading_activate_reseller_car:"Activate Reseller Car",
		confirmation_popup_heading_deactivate_reseller_car:"Deactivate Reseller Car",
		confirmation_popup_heading_delete_reseller_car:"Delete Reseller Car",
		// label for popup heading ends
		// confirmation msg for popup starts
		confirmation_msg_for_delete_reseller_car:"Are you sure you want to delete this reseller car?",
		confirmation_msg_for_activate_reseller_car:"Are you sure you want to activate this reseller car?",
		confirmation_msg_for_deactivate_reseller_car:"Are you sure you want to deactivate this reseller car?",
		confirmation_msg_for_delete_fleet_customer_car_map:"Are you sure you want to delete this fleet customer car map?",
		confirmation_msg_for_delete_end_user_car_map:"Are you sure you want to delete this Driver car map?",
		confirmation_msg_for_delete_pricing_plan:"Are you sure you want to delete this pricing plan?",
		confirmation_msg_for_delete_pricing_plan_pricing_dtls:"Are you sure you want to delete this pricing details?",
		confirmation_msg_for_activate_pricing_plan:"Are you sure you want to activate this pricing plan?",
		confirmation_msg_for_deactivate_pricing_plan:"Are you sure you want to deactivate this pricing plan?",
		confirmation_msg_for_activate_car_parking_provider:"Are you sure you want to activate this parking provider?",
		confirmation_msg_for_deactivate_car_parking_provider:"Are you sure you want to deactivate this parking provider?",
		confirmation_msg_for_activate_car_location_provider:"Are you sure you want to activate this location provider?",
		confirmation_msg_for_deactivate_car_location_provider:"Are you sure you want to deactivate this location provider?",
		confirmation_msg_for_delete_user:"Are you sure you want to delete this user?",
		confirmation_msg_for_activate_user:"Are you sure you want to activate this user?",
		confirmation_msg_for_deactivate_user:"Are you sure you want to deactivate this user?",
		confirmation_msg_for_delete_reseller:"Are you sure you want to delete this reseller?",
		confirmation_msg_for_activate_reseller:"Are you sure you want to activate this reseller?",
		confirmation_msg_for_deactivate_reseller:"Are you sure you want to deactivate this reseller?",
		confirmation_msg_for_delete_fleet_customer:"Are you sure you want to delete this fleet customer?",
		confirmation_msg_for_activate_fleet_customer:"Are you sure you want to activate this fleet customer?",
		confirmation_msg_for_deactivate_fleet_customer:"Are you sure you want to deactivate this fleet customer?",
		confirmation_msg_for_delete_end_user:"Are you sure you want to delete this driver?",
		confirmation_msg_for_activate_end_user:"Are you sure you want to activate this driver?",
		confirmation_msg_for_deactivate_end_user:"Are you sure you want to deactivate this driver?",
		required_email_validation_msg:"The Email field is required.",
		add_edit_reseller_field_location_provider:"Location Provider",
		add_edit_reseller_field_parking_provider:"Parking Provider",
		datatable_header_lbl_cartime_pricing_plan: "Cartime Pricing Plan",
		datatable_header_lbl_reseller_pricing_plan: "Reseller Pricing Plan",
		datatable_header_lbl_no_of_fleet_customers:"No. of Fleet Customers",
		datatable_header_lbl_no_of_allocated_cars:"No. of Alloted Cars",
		datatable_header_lbl_no_of_end_users:"No. of Drivers",
		reseller_list_page_title:"Reseller(s) List",
		reseller_cars_list_page_title:"Reseller Car(s) List",
		reseller_cars_list_page_title_for_reseller:"Car(s) List",
		fleet_customer_list_page_title:"Fleet Customer(s) List",
		end_user_list_page_title:"Driver(s) List",
		fleet_customer_user_list_page_title:"Fleet Customer User(s) List",
		action_lbl_edit_reseller_setting:"Edit Reseller Settings",
		action_lbl_edit_fleet_customer_car_map_setting:"Manage Fleet Customer Car Map",
		action_lbl_edit_end_user_car_map_setting:"Manage Driver Car Map",
		action_lbl_edit_fleet_customer_setting:"Manage Settings",
		action_lbl_edit_fleet_customer_basic:"Edit Details",
		action_lbl_manage_users:"Manage Users",
		action_lbl_manage_end_users:"Manage Drivers",
		action_lbl_manage_fleet_customers:"Manage Fleet Customers",
		add_edit_reseller_cartime_to_reseller_invoice_section_heading:"Cartime to Reseller Invoice",
		add_reseller_form_lbl_source:"Source",
		add_reseller_form_lbl_period:"Period",
		add_edit_reseller_to_fleet_customers_invoice_section_heading:"Reseller to Fleet Customers Invoice",
		add_edit_reseller_field_mode:"Mode",
		btn_lbl_previous:"Previous",
		add_edit_reseller_tab_heading_basic_details:"Basic Details",
		add_edit_reseller_tab_heading_settings:"Settings",
		add_edit_tab_heading_cars_settings:"Car and Settings",
		add_edit_tab_heading_fleet_customer_settings:"Fleet Customer Car Map",
		add_edit_tab_heading_end_user_settings:"Driver Car Map",
		add_edit_pricing_plan_tab_heading_pricing_details:"Pricing Details",
		add_edit_reseller_tab_heading_user:"User",
		common_lbl_next:"Next",
		common_lbl_skip:"Skip",
		common_lbl_you_are_currently_loggedin:"You are currently logged in as",
		lbl_click:"Click",
		lbl_here:"here",
		to_login_as:"to login as",
		add_pricing_plan_page_title:"Pricing Plan",
		day_field_validation :"Day field is required",
		max_length_validation_msg:"The :attribute may not be greater than End Time characters",
		min_length_validation_msg:"The :attribute Must be at least Start Time characters",
		business_hours_validation: "The End Time field value must be greater than Start Time",
		ald_private_parking_available_label: "Private Parking Available",
		ald_business_parking_available_label: "Business Parking Available",
		ald_request_id_label: "Request ID",
		ald_start_parking_url_label: "ALD Start Parking Request URL",
		ald_end_parking_url_label: "ALD End Parking Request URL",
		ald_vin_number_label: "VIN Number",
		ald_latitude_label: "Latitude",
		ald_longitude_label: "Longitude",
		ald_zone_label: "Zone",
		ald_address_label: "Address",
		ald_hourly_price_currency_label: "Hourly Price Currency",
		ald_hourly_price_label: "Hourly Price",
		ald_total_price_currency_label: "Total Price Currency",
		ald_total_price_label: "Total Price",
		parking_report_label_current_status: "Current Status",
		parking_report_label_parking_start_time: "Parking Start Time",
		parking_report_label_parking_end_time: "Parking End Time",
		parking_report_label_parking_zone: "Parking Zone",
		parking_report_label_parking_type: "Parking Type",
		parking_report_label_total_charge: "Total Charge",
		parking_report_list_heading: "Parking(s) List",
		cron_job_list_heading: "Periodic Jobs",
		parking_report_start_parking_request:"Start Parking User Response",
		parking_report_label_initiation_time:"Initiation Time",
		parking_report_label_day_timings:"Day Timings",
		parking_report_label_day_per_hour_parking_charge:"Day Per Hour Parking Charge",
		parking_report_label_evening_timings:"Evening Timings",
		parking_report_label_evening_per_hour_parking_charge:"Evening Per Hour Parking Charge",
		parking_report_label_night_timings:"Night Timings",
		parking_report_label_night_per_hour_parking_charge:"Night Per Hour Parking Charge",
		parking_report_label_status:"Status",
		parking_report_label_export:"Export",
		parking_report_label_reponse:"Response",
		parking_report_label_reponse_accept:"Accept",
		parking_report_label_reponse_cancel:"Cancel",
		parking_report_label_start_parking:"Start Parking",
		parking_report_label_start_parking_info:"Info",
		parking_report_label_parking_type_private:"Private",
		parking_report_label_parking_type_business:"Business",
		parking_report_label_business_projected_id:"Business Projected Id",
		datatable_search_placeholder_parking_report:"Search by make and model, status, reseller, fleet customer, driver, parking zone, parking type",
		datatable_search_placeholder_parking_report_reseller:"Search by make and model, status, fleet customer, driver, parking zone, parking type",
		datatable_search_placeholder_parking_report_fleet:"Search by make and model, status, driver, parking zone, parking type",
		datatable_search_placeholder_parking_report_end:"Search by make and model, status, parking zone, parking type",
		datatable_search_placeholder_cron_job_report:"Search by status",
		datatable_search_placeholder_cron_job_report_first_layer:"Search by reseller, status",
		datatable_search_placeholder_cron_job_report_first_layer_for_reseller:"Search by status",
		datatable_search_placeholder_cartime_to_reseller_for_admin:"Search by reseller, fleet customer, status",
		datatable_search_placeholder_cartime_to_reseller_for_reseller:"Search by fleet customer, status",
		timeline_page_title:"Parking Request Log",
		lbl_reference_id:"Reference ID",
		lbl_engine_id: "Engine ID",
		lbl_latitude: "Latitude",
		lbl_longitude: "Longitude",
		lbl_recorded_at: "Recorded At",
		lbl_received_at: "Received At",
		lbl_day_parking_time:"Day Parking Time",
		lbl_evening_parking_time:"Evening Parking Time",
		lbl_night_parking_time:"Night Parking Time",
		lbl_parking_start_time: "Parking Start Time",
		lbl_parking_provider_day_parking_per_hour_charge:"Parking Provider Day Parking Per Hour Charge",
		lbl_parking_provider_evening_parking_per_hour_charge:"Parking Provider Evening Parking Per Hour Charge",
		lbl_parking_provider_night_parking_per_hour_charge:"Parking Provider Night Parking Per Hour Charge",
		lbl_cartime_to_reseller_pricing_plan:"Cartime to Reseller Pricing Plan",
		lbl_cartime_to_reseller_day_parking_per_hour_charge:"Cartime to Reseller Day Parking Per Hour Charge",
		lbl_cartime_to_reseller_evening_parking_per_hour_charge:"Cartime to Reseller Evening Parking Per Hour Charge",
		lbl_cartime_to_reseller_night_parking_per_hour_charge:"Cartime to Reseller Night Parking Per Hour Charge",
		lbl_reseller_to_fleet_customer_hourly_charges:"Reseller to Fleet Customer Hourly Charges",
		lbl_reseller_to_fleet_customer_pricing_plan:"Reseller to Fleet Customer Pricing Plan",
		lbl_reseller_to_fleet_customer_day_parking_per_hour_charge:"Reseller to Fleet Customer Day Parking Per Hour Charge",
		lbl_reseller_to_fleet_customer_evening_parking_per_hour_charge:"Reseller to Fleet Customer Evening Parking Per Hour Charge",
		lbl_reseller_to_fleet_customer_night_parking_per_hour_charge:"Reseller to Fleet Customer Night Parking Per Hour Charge",
		lbl_user_start_parking_request:"User Start Parking Request",
		lbl_request_initiated_at:"Request Initiated At",
		lbl_request_initiation_response:"Request Initiation Response",
		lbl_user_start_parking_response:"User Start Parking Response",
		lbl_parking_response:"Parking Response",
		lbl_user_response_recorded_at:"User Response Recorded At",
		lbl_parking_response_received_at:"Parking Response Received At",
		lbl_parking_type:"Parking Type",
		lbl_business_project_number:"Business Project Number",
		lbl_cartime_process:"Cartime Proces",
		lbl_parking_end_time:"Parking End Time",
		lbl_total_calculated_day_parking_minutes:"Total Parking Minutes",
		lbl_total_calculated_evening_parking_minutes:"Total Billable Evening Parking Minutes",
		lbl_total_calculated_night_parking_minutes:"Total Billable Night Parking Minutes",
		lbl_parking_charges:"Parking Charge",
		lbl_parking_provider_total_charge:"Parking Provider Total Charge",
		lbl_cartime_to_reseller_total_charge:"Cartime to Reseller Total Charge",
		lbl_reseller_to_fleet_customer_total_charge:"Reseller to Fleet Customer Total Charge",
		user_communication_source:"User Communication Source",
		engine_off:"Engine Off",
		cartime_process_start_process:"Cartime Process (Start Parking)",
		parking_provider_hourly_charges:"Parking Provider Hourly Charges",
		cartime_to_reseller_hourly_charges:"Cartime to Reseller Hourly Charges",
		reseller_to_fleet_customer_hourly_charges:"Reseller to Fleet Customer Hourly Charges",
		cartime_process_end_parking:"Cartime Process (End Parking)",
		parking_charges:"Parking Charges",
		end_parking:"End Parking",
		user_end_parking:"User End Parking",
		request_initiation:"Request Initiation",
		parking_id:"Parking ID",
		parking_status:"Parking Status",
		lbl_type:"Type",
		lbl_license_plate:"License Plate",
		lbl_license_plate_type:"License Plate Type",
		btn_lbl_export_PLO_reconciliation:"Export PLO Reconciliation",
		total_parking_charge:"Total Parking Charge",
		btn_lbl_export_private_parking_details:"Export Private Parking Details",
		cartime_to_reseller_invoice:"Cartime to Reseller Invoice Jobs",
		reseller_to_fleet_customer_invoice:"Reseller to Fleet Customer Invoice Jobs",
		cartime_ald_invoice_jobs:"Cartime to ALD Invoice Jobs",
		cartime_to_ald_invoice_jobs :"Cartime to ALD Invoice Jobs",
		cron_job_reports:"Periodic Job Reports",
		total_processed_resellers:"Total Processed Resellers",
		total_generated_invoices:"Total Generated Invoices",
		total_processed_fleet_customers:"Total Processed Fleet Customers",
		cron_job_status:"Current Job Status",
		invoice_period_from:"Invoice Period From",
		invoice_period_to:"Invoice Period To",
		total_invoice_parking_count:"Total Invoice Parking Count",
		total_invoice_amount:"Total Invoice Amount",
		product_code:"Product Code",
		product_description:"Product Description",
		quantity:"Quantity",
		unit:"Unit",
		unit_price_ex_vat:"Unit Price Excl VAT",
		total_price_ex_vat:"Total Price Excl VAT",
		invoice_id:"Invoice ID",
		btn_title_view:"View",
		lbl_device:"Device",
		btn_lbl_view_parking_request:"View Parking Requests",
		parking_page_header:"Currently you are viewing parkings for invoice with ID",
		parking_page_header_reseller:"Currently you are viewing parking requests for invoice for",
		from:"from",
		to:"to",
		export_ALD_tooltip:"Export ALD Invoice Details",
		export_cartime_reseller_invoice_summary:"Export Cartime To Reseller Invoices Summary",
		some_error_occured:"Some error occured while processing your request. Please try after some time.",
		error:"Error",
		session_expired:"Your session has been expired",
		page_not_found:"Page not found.",
		invalid_request:"Invalid request",
		validation_error:"Validation errors found from server end",
		cartime_reseller_commision_value:"Cartime to Reseller Commission Value",
		parking_provider_total_charges:"Parking Provider Total Charges",
		cartime_reseller_total_charges:"Cartime to Reseller Total Charges",
		reseller_fleet_customer_total_charges:"Reseller to Fleet Customer Total Charges",
		total_charge_ex_VAT:"Total Charge Excl VAT",
		total_VAT:"Total VAT",
		total_charge_incl_VAT:"Total Charge Incl VAT",
		commision_VAT_percentage:"Commision VAT Percentage",
		total_commission_ex_VAT:"Total Commision Excl VAT",
		total_commission_incl_VAT:"Total Commision Incl VAT",


		admin_users_list_heading: "Admin User(s) List",
		common_lbl_company_tax_reg_number: "Company Tax Registration Number",
		common_lbl_office_contact_number: "Office Contact Number",
		common_lbl_address: "Address",
		// Suppliers
		suppliers_list_heading: "Supplier(s) List",
		edit_supplier_page_title:"Edit Supplier",
		add_supplier_page_title:"Add Supplier",
		edit_customer_page_title:"Edit Customer",
		customer_list_heading: "Customer(s) List",
		add_customer_page_title:"Add Customer",
		product_list_heading: "Product(s) List",
		add_product_page_title:"Add Product",
		edit_product_page_title:"Edit Product",

		cart_add_msg: "Item added to cart successfully",
		cart_remove_msg: "Item removed from cart successfully",
		item_update_msg: "Item updated successfully",
		item_remove_msg: "Item cancelled successfully",
		item_return_msg: "Item returned successfully",
		order_cancel_msg: "Order cancelled successfully",
		order_update_msg: "Order updated successfully",
		order_return_msg: "Order returned successfully",
		password_success_change: "Passsword changed successfully",
		password_request_success: "Reset password request sent successfully",
		reset_password_success_change: "Password reset successfully"
	},
    da: {
        cartime_users_list_heading: "Liste over brugere (r)",
        resellers_users_list_heading: "Liste over forhandlerbruger (er)",
        common_no_data_found_msg: "Ingen data fundet",
        common_datatable_sr_no: "Sr. Nej",
		common_reseller: "Forhandler",
		common_lbl_name: "Navn",
		common_lbl_email: "E-mail",
		common_lbl_role: "Rolle",
		common_lbl_country: "Land",
		common_lbl_city: "By",
		common_datatable_actions: "Handlinger",
		common_lbl_new_password: "Ny adgangskode",
		common_lbl_confirm_new_password: "Bekræft nyt kodeord",
		common_lbl_password_match_error_msg: "Ny adgangskode og bekræft ny adgangskode stemmer ikke overens.",
		common_lbl_description: "Beskrivelse",
		common_lbl_total_pricing_count:"Samlet prisantælling",
		common_lbl_first_name: "Fornavn",
		common_lbl_last_name: "Efternavn",
		common_lbl_contact_number: "Kontaktnummer",
		common_lbl_language_preference: "Sprogpræference",
		common_lbl_company_name: "Firmanavn",
		common_lbl_contract_info:"Kontraktsinfo",
		common_lbl_vat: "moms",
		common_lbl_vat_percentage:"Momsprocent",
		common_lbl_contact_email: "Kontakt e-mail",
		common_lbl_website: "Website",
		common_lbl_address_line_1: "Adresselinie 1",
		common_lbl_address_line_2: "Adresselinie 2",
		common_lbl_address_line_3: "Adresselinie 3",
		common_lbl_datatable_no_records_found: "Ingen poster blev fundet",
		common_datatable_lbl_showing: "Viser",
		common_datatable_lbl_to: "til",
		common_datatable_lbl_of: "af",
		common_datatable_lbl_entries: "poster",
		common_lbl_success: "Succes",
		common_lbl_password: "Password",
		common_lbl_confirm_password: "Bekræft adgangskode",
		common_lbl_code: "Kode",
		common_lbl_country_coverage: "Landsdækning",
		common_lbl_show: "Show",
		common_lbl_hide: "Skjul",
		default_loading_msg: "Vent venligst ...",
		reseller_car_license_plate_label:"Nummerplade",
		reseller_car_telemetry_label:"Telemetri ID",
		reseller_car_name_label:"mærke og model",
		datatable_coloumn_current_assigned_fleet_customer:"Nuværende Tildelt Fleet-kunde",
		datatable_coloumn_current_allocated_end_user:"Aktuel tildelt chauffør",
		reseller_car_label:"Bil",
		reseller_car_vin_label:"VIN",
		reseller_car_alloted_start_date_label:"Tildelt startdato",
		reseller_car_alloted_end_date_label:"Tildelt slutdato",
		total_mapped_fleet_customers_label:"Samlede kortlagte flådekunder",
		total_mapped_end_users_label:"Samlede kortlagte chaufføre",
		datatable_search_placeholder_for_cartime_in_reseller: "Søg efter forhandler, navn, e-mail, rolle, land og andre felter",
		datatable_search_placeholder_for_reseller: "Søg efter navn, e-mail, rolle, land og andre felter",
		datatable_search_placeholder_for_car_parking_provider_list: "Søg efter kode, navn, dækningsland, valuta",
		datatable_search_placeholder_for_cartime_pricing_plan: "Søg efter navn, beskrivelse",
		datatable_search_placeholder_for_cartime_pricing_plan_pricing_dtls: "Søg efter prisplan",
		datatable_search_placeholder_for_resellers: "Søg efter firmanavn, moms, kontakt e-mail, valuta og andre relaterede felter",
		datatable_search_placeholder_for_reseller_user_pricing_plan: "Søg efter forhandler, navn, beskrivelse",
		datatable_search_placeholder_for_fleet_customer_list:"Søg efter forhandler, firmanavn, moms, kontakt e-mail, land",
		datatable_search_placeholder_for_fleet_customer_list_for_reseller:"Søg efter, firmanavn, moms, kontakt e-mail, land",
		datatable_search_placeholder_for_fleet_customer_users_list:"Søg efter forhandler, flådekunde, navn, e-mail, rolle",
		datatable_search_placeholder_for_fleet_customer_users_list_for_fleet:"Søg efter navn, e-mail, rolle, land",
		datatable_search_placeholder_for_end_users_list_for_fleet:"Søg efter navn, e-mail, land",
		datatable_search_placeholder_for_reseller_list:"Søg efter flådekunde, navn, e-mail, rolle",
		datatable_search_placeholder_for_end_user_list:"Søg efter forhandler, flådekunde, navn, e-mail, land",
		datatable_search_placeholder_for_end_user_for_reseller_list:"Søg efter flådekunde, navn, e-mail, land",
		datatable_search_placeholder_end_user_car_map_list:"Søg efter forhandler, flådekunde, chauffør, bil, Nummerplade",

		datatable_search_placeholder_fleet_customer_car_map_list_for_reseller:"Søg efter flådekunde, chauffør, bil, Nummerplade",
		datatable_search_placeholder_fleet_customer_car_map_list_for_fleet_customer:"Søg efter chauffør, bil, Nummerplade",

		datatable_search_placeholder_fleet_customer_car_map_list:"Søg efter forhandler, flådekunde, bil, Nummerplade",
		datatable_search_placeholder_reseller_car_map_list:"Søg efter forhandler, bilnavn, nummerplade, telemetri-id",
		datatable_search_placeholder_car_map_list_for_reseller:"Søg efter bilnavn, nummerplade, telemetri-id",
		btn_title_delete: "Slet",
		btn_title_edit: "Rediger",
		btn_title_edit_pricing_details: "Rediger prisoplysninger",
		btn_title_deactivate: "Deaktiver",
		btn_title_activate: "Aktivér",
		btn_title_reset_pwd: "Nulstil adgangskode",
		lbl_select: "--- Vælg ---",
		btn_title_yes: "Ja",
		btn_title_no: "Nej",
		btn_title_search: "Søg",
		btn_add: "TILFØJ",
		btn_submit: "Indsend",
		btn_cancle: "Afbestille",
		car_parking_provider_list_page_title: "Liste over udbydere af parkeringspladser",
		common_lbl_priority: "Prioritet",
		common_lbl_currency: "Valuta",
		common_lbl_start_time:"Starttidspunkt",
		common_lbl_end_time:"Sluttid",
		add_cartime_pricing_plan_datatable_title: "Liste over prisplaner (r)",
		add_cartime_pricing_plan_pricing_dtls_datatable_title: "Liste over prisoplysninger",
		mapped_fleet_customers_list_datatable_title: "Flådekunde (r) Bil (er) Kortliste",
		mapped_end_users_list_datatable_title: "chauffør (r) Bil (er) Kortliste",
		add_cartime_pricing_plan_page_title: "Tilføj prisplan",
		edit_cartime_pricing_plan_page_title: "Rediger prisplan",
		add_cartime_pricing_plan_field_name: "Prisen Plannavn",
		add_cartime_pricing_plan_field_description: "Beskrivelse",
		add_cartime_pricing_plan_field_system_use_fee: "Gebyr for systembrug",
		add_cartime_pricing_plan_field_system_use_fee_reseller: "Gebyr for systembrug for forhandler",
		add_cartime_pricing_plan_field_system_use_fee_fleet_customer: "Gebyr for systembrug for flådekunder",
		add_cartime_pricing_plan_field_signon_fee:"Sign On gebyr",
		add_cartime_pricing_plan_field_signon_fee_new_registered_cars:"Tilmeldingsgebyr for nye registrerede biler",
		add_cartime_pricing_plan_field_signon_fee_for_reseller:"Sign On gebyr For forhandlere",
		add_cartime_pricing_plan_field_signon_fee_for_fleet_customer:"Sign On gebyr For flådekunder",
		add_cartime_pricing_plan_field_subscription_fee_per_active_car: "Abonnementsgebyr pr. aktiv bil",
		add_cartime_pricing_plan_field_subscription_fee_per_active_user: "Abonnementsgebyr pr. aktiv bruger",
		add_cartime_pricing_plan_field_subscription_fee_for_private_parking: "Abonnementsgebyr for privat parkering",
		add_cartime_pricing_plan_field_subscription_fee_for_invoicing_of_fleet: "Abonnementsgebyr for Fleet Customer Faktura",
		add_cartime_pricing_plan_field_fees_type: "Gebyrtype",
		add_cartime_pricing_plan_field_fix_commission_fee_per_parking: "Fix provision fee per Parking",
		add_cartime_pricing_plan_field_fix_commission_fee_per_hour_parking: "Fastgør kommissionsgebyr pr. Time pr. Parkering",
		add_cartime_pricing_plan_field_percentage_add_on_per_parking: "Procentdel tilføjelse per parkering",
		add_cartime_pricing_plan_field_fees_value: "Gebyrværdi",
		add_cartime_pricing_plan_field_fees_percentage: "Gebyrprocent",
		add_cartime_pricing_plan_field_monthly_recurring_fees: "tilbagevendende gebyrer",
		add_cartime_pricing_plan_field_signon_fees: "Engangsgebyrer",
		add_cartime_pricing_plan_field_transaction_fees: "Transaktionsgebyrer",
		add_cartime_pricing_plan_field_from_date: "Fra dato",
		add_cartime_pricing_plan_field_to_date: "Til dato",
		add_cartime_pricing_plan_datatable_coloumn_start_date: "Start dato",
		add_cartime_pricing_plan_datatable_coloumn_end_date: "Slutdato",
		edit_car_parking_provider_page_title: "Rediger bilparkeringsudbyder",
		car_location_provider_list_page_title: "Liste over udbydere af bilplaceringer (r)",
		edit_car_location_provider_page_title: "Rediger udbyder af bilplacering",
		reseller_pricing_plan_list_page_title: "Liste over forhandlerpriser Plan (er)",
		reseller_pricing_plan_pricing_dtls_list_page_title:"Forhandler prisliste detaljer liste",
		edit_profile_page_title: "Rediger profil",
		edit_company_profile_page_title: "Rediger firmaprofil",
		change_password_page_title: "Skift adgangskode",
		common_lbl_old_password: "Gammelt kodeord",
		menu_lbl_for_admin_users: "Admin Users",
		menu_lbl_for_data_mgmt: "Datastyring",
		menu_lbl_for_manage_pricing_plans: "Administrer prisplaner",
		menu_lbl_for_car_parking_providers: "Udbydere af parkeringspladser",
		menu_lbl_for_car_location_providers: "Udbydere af bilplaceringer",
		menu_lbl_for_manage_cars: "Administrer biler",
		menu_lbl_for_reports: "Rapporter",
		menu_lbl_for_parking_reports: "Parkeringsrapporter",
		menu_lbl_for_periodic_job_reports: "Periodiske jobrapporter",
		menu_lbl_for_users: "Brugere",
		menu_lbl_for_resellers: "Forhandlere",
		menu_lbl_for_manage_resellers: "Administrer forhandlere",
		menu_lbl_for_manage_reseller_users: "Administrer forhandlerbrugere",
		menu_lbl_for_manage_reseller_cars: "Administrer forhandlerbiler",
		menu_lbl_for_manage_reseller_pricing_plans: "Administrer forhandlerprisplaner",
		menu_lbl_for_fleet_customers: "flåde kunder",
		form_lbl_for_fleet_customer:"flåde kunder",
		menu_lbl_for_manage_fleet_customers: "Administrer flådekunder",
		menu_lbl_for_manage_fleet_customer_users: "Administrer Fleet-kundebrugere",
		menu_lbl_for_logout: "Logout",
		menu_lbl_for_end_users: "chaufføre",
		lbl_for_end_user:"chaufføre",
		text_hi: "Hej",
		forgot_password: "Glemt password",
		forgot_password_login_text: "Glemt password?",
		edit_user_page_title: "Rediger bruger",
		add_user_page_title: "Tilføj bruger",
		edit_end_user_page_title:"Rediger chauffør",
		add_end_user_page_title:"Tilføj chauffør",
		edit_reseller_user_page_title: "Rediger forhandlerbruger",
		add_reseller_user_page_title: "Tilføj forhandlerbruger",
		edit_reseller_car_page_title:"Rediger forhandlerbil",
		edit_reseller_car_page_title_for_reseller:"Rediger bil",
		add_fleet_customer_car_map_page_title:"Kortflådekunde",
		add_end_user_car_map_page_title:"chauffør af kort",
		edit_fleet_customer_car_map_page_title:"Rediger kortlagt flådekunde",
		edit_end_user_car_map_page_title:"Rediger kortlagt chauffør",
		edit_car_map_page_title:"Rediger kortlagt bil",
		add_reseller_car_page_title:"Tilføj forhandlerbil",
		add_reseller_car_page_title_for_reseller:"Tilføj bil",
		edit_reseller_pricing_plan_page_title: "Rediger plan for forhandlerpriser",
		add_reseller_pricing_plan_page_title: "Tilføj forhandlerprisplan",
		state_province: "Stat / provins",
		common_lbl_zip_code: "Postnummer",
		lbl_go_to_login_text: "Gå til login",
		login_text: "Log ind",
		lbl_login_as_user: "Login som bruger",
		edit_reseller_page_title: "Rediger forhandler",
		add_reseller_page_title: "Tilføj forhandler",
		add_fleet_customer_page_title:"Tilføj Fleet kunde",
		edit_fleet_customer_page_title:"Rediger Fleet kunde",
		add_fleet_customer_user_page_title:"Tilføj Fleet kunde bruger",
		edit_fleet_customer_user_page_title:"Rediger Fleet kunde bruger",
		add_reseller_form_lbl_information_source: "Informationskilde",
		add_reseller_form_lbl_allow_private_parking: "Tillad privat parkering",
		add_reseller_form_lbl_service_start_date: "Service-startdato",
		add_reseller_form_lbl_service_end_date: "Service-slutdato",
		add_fleet_customer_form_lbl_business_days:"Arbejdsdage",
		add_fleet_customer_form_lbl_business_hours:"Arbejdstimer",
		// label for popup heading starts
		confirmation_popup_heading_activate_pricing_plan: "Aktivér prisplan",
		confirmation_popup_heading_deactivate_pricing_plan: "Deaktiver prisfastsættelsesplan",
		confirmation_popup_heading_delete_pricing_plan: "Slet prisplan",
		confirmation_popup_heading_delete_pricing_plan_pricing_details:"Slet prisoplysninger",
		confirmation_popup_heading_activate_parking_provider: "Aktivér parkeringsudbyder",
		confirmation_popup_heading_deactivate_parking_provider: "Deaktiver parkeringsudbyder",
		confirmation_popup_heading_activate_location_provider: "Aktivér lokaliseringsudbyder",
		confirmation_popup_heading_deactivate_location_provider: "Deaktiver placeringsudbyder",
		confirmation_popup_heading_activate_user: "Aktivér bruger",
		confirmation_popup_heading_deactivate_user: "Deaktiver bruger",
		confirmation_popup_heading_delete_user: "Slet bruger",
		confirmation_popup_heading_activate_reseller: "Aktivér forhandler",
		confirmation_popup_heading_deactivate_reseller: "Deaktiver forhandler",
		confirmation_popup_heading_reset_user_password: "Nulstil brugeradgangskode",
		confirmation_popup_heading_delete_reseller: "Slet forhandler",
		confirmation_popup_heading_activate_fleet_customer:"Aktivér Fleet-kunde",
		confirmation_popup_heading_deactivate_fleet_customer:"Deaktiver Fleet-kunde",
		confirmation_popup_heading_delete_fleet_customer:"Slet Fleet-kunde",
		confirmation_popup_heading_activate_end_user:"Aktivér chauffør",
		confirmation_popup_heading_deactivate_end_user:"Deaktiver chauffør",
		confirmation_popup_heading_delete_end_user:"Slet chauffør",
		confirmation_popup_heading_delete_fleet_customer_car_map:"Slet kortlagt bilens flådekunde",
		confirmation_popup_heading_delete_end_user_car_map:"Slet den kortlagte bils chauffør",
		// label for popup heading ends
		// confirmation msg for popup starts
		confirmation_msg_for_delete_fleet_customer_car_map:"Er du sikker på, at du vil slette dette flådekundebilkort?",
		confirmation_msg_for_delete_end_user_car_map:"Er du sikker på, at du vil slette dette chaufførbilkort?",
		confirmation_msg_for_delete_pricing_plan: "Er du sikker på, at du vil slette denne prisplan?",
		confirmation_msg_for_activate_pricing_plan: "Er du sikker på, at du vil aktivere denne prisplan?",
		confirmation_msg_for_deactivate_pricing_plan: "Er du sikker på, at du vil deaktivere denne prisplan?",
		confirmation_msg_for_activate_car_parking_provider: "Er du sikker på, at du vil aktivere denne parkeringsudbyder?",
		confirmation_msg_for_deactivate_car_parking_provider: "Er du sikker på, at du vil deaktivere denne parkeringsudbyder?",
		confirmation_msg_for_activate_car_location_provider: "Er du sikker på, at du vil aktivere denne placeringsudbyder?",
		confirmation_msg_for_deactivate_car_location_provider: "Er du sikker på, at du vil deaktivere denne placeringsudbyder?",
		confirmation_msg_for_delete_user: "Er du sikker på, at du vil slette denne bruger?",
		confirmation_msg_for_activate_user: "Er du sikker på, at du vil aktivere denne bruger?",
		confirmation_msg_for_deactivate_user: "Er du sikker på, at du vil deaktivere denne bruger?",
		confirmation_msg_for_delete_reseller: "Er du sikker på, at du vil slette denne forhandler?",
		confirmation_msg_for_activate_reseller: "Er du sikker på, at du vil aktivere denne forhandler?",
		confirmation_msg_for_deactivate_reseller: "Er du sikker på, at du vil deaktivere denne forhandler?",
		confirmation_msg_for_delete_fleet_customer:"Er du sikker på, at du vil slette denne flådekunde?",
		confirmation_msg_for_activate_fleet_customer:"Er du sikker på, at du vil aktivere denne flådekunde?",
		confirmation_msg_for_deactivate_fleet_customer:"Er du sikker på, at du vil deaktivere denne flådekunde?",
		required_email_validation_msg: "E-mail-feltet er påkrævet.",
		add_edit_reseller_field_location_provider: "Placeringsudbyder",
		add_edit_reseller_field_parking_provider: "Parkeringsudbyder",
		datatable_header_lbl_cartime_pricing_plan: "Cartime Prisplan",
		datatable_header_lbl_reseller_pricing_plan: "Forhandlerprisplan",
		datatable_header_lbl_no_of_fleet_customers: "Nr. af flåde kunder",
		datatable_header_lbl_no_of_allocated_cars:"Antal tildelte biler",
		datatable_header_lbl_no_of_end_users: "Antal chaufføre",
		reseller_list_page_title: "Liste over forhandlere (r)",
		reseller_cars_list_page_title:"Forhandlerbil (er) Liste",
		reseller_cars_list_page_title_for_reseller:"bil (er) Liste",
		fleet_customer_list_page_title:"Fleet Kunder Liste",
		end_user_list_page_title:"chauffør (er) Liste",
		action_lbl_edit_reseller_setting: "Rediger forhandlerindstillinger",
		action_lbl_edit_fleet_customer_car_map_setting:"Flådekundebilkort",
		action_lbl_edit_end_user_car_map_setting:"chaufførbilkort",
		action_lbl_edit_fleet_customer_setting:"Administrer indstillinger",
		action_lbl_edit_fleet_customer_basic:"Rediger detaljer",
		action_lbl_manage_users: "Administrer brugere",
		action_lbl_manage_end_users:"Administrer chaufføre",
		action_lbl_manage_fleet_customers: "Administrer flåde kunder",
		add_edit_reseller_cartime_to_reseller_invoice_section_heading: "Cartime til forhandlerfaktura",
		add_reseller_form_lbl_source: "Source",
		add_reseller_form_lbl_period: "Periode",
		add_edit_reseller_to_fleet_customers_invoice_section_heading: "Forhandler til flådekunders faktura",
		add_edit_reseller_field_mode: "Mode",
		btn_lbl_previous: "Forrige",
		add_edit_reseller_tab_heading_basic_details: "Grundlæggende detaljer",
		add_edit_reseller_tab_heading_settings: "Indstillinger",
		add_edit_tab_heading_cars_settings:"Biler og indstillinger",
		add_edit_tab_heading_fleet_customer_settings:"Fleet kundeoplysninger",
		add_edit_tab_heading_end_user_settings:"chaufførdetaljer",
		add_edit_pricing_plan_tab_heading_pricing_details:"Prisoplysninger",
		add_edit_reseller_tab_heading_user: "Bruger",
		common_lbl_next: "Næste",
		common_lbl_skip:"Springe",
		common_lbl_you_are_currently_loggedin: "Du er i øjeblikket logget ind som",
		lbl_click: "Klik",
		lbl_here: "her",
		to_login_as: "at logge ind som",
		add_pricing_plan_page_title: "Prisplan",
		day_field_validation :"Dagsfelt er påkrævet",
		max_length_validation_msg:"Attributten er måske ikke større end End Time-tegn",
		min_length_validation_msg:"Attributten skal mindst være Starttidstegn",
		business_hours_validation: "Feltværdien for sluttid skal være større end starttidspunktet",
		ald_private_parking_available_label : "Privat parkering tilgængelig",
		ald_business_parking_available_label : "Forretningsparkering tilgængelig",
		ald_request_id_label: "Anmodnings-id",
		ald_start_parking_url_label: "ALD Start URL til anmodning om parkering",
		ald_end_parking_url_label: "ALD URL til slutparkeringsanmodning",
		ald_vin_number_label: "VIN-nummer",
		ald_latitude_label: "Breddegrad",
		ald_longitude_label: "Længde",
		ald_zone_label: "Zone",
		ald_address_label: "Adresse",
		ald_hourly_price_currency_label: "Timeprisvaluta",
		ald_hourly_price_label: "Timepris",
		ald_total_price_currency_label: "Samlet prisvaluta",
		ald_total_price_label: "Total pris",
		parking_report_label_current_status: "Aktuel status",
		parking_report_label_parking_start_time: "Starttidspunkt for parkering",
		parking_report_label_parking_end_time: "Parkering sluttid",
		parking_report_label_parking_zone: "Parkeringszone",
		parking_report_label_parking_type: "Parkeringstype",
		parking_report_label_total_charge: "Samlet gebyr",
		parking_report_list_heading: "Liste over parkeringspladser",
		cron_job_list_heading: "Periodiske job",
		parking_report_start_parking_request: "Start brugersvar ved parkering",
		parking_report_label_initiation_time: "Initiation Time",
		parking_report_label_day_timings: "Day Timings",
		parking_report_label_day_per_hour_parking_charge: "Parkeringsgebyr per dag",
		parking_report_label_evening_timings: "Aftentider",
		parking_report_label_evening_per_hour_parking_charge: "Parkeringsafgift om aftenen i timen",
		parking_report_label_night_timings: "Night Timings",
		parking_report_label_night_per_hour_parking_charge: "Parkeringsafgift pr. nat",
		parking_report_label_status:"Status",
		parking_report_label_export:"Eksport",
		parking_report_label_reponse: "Svar",
		parking_report_label_start_parking:"Start parkering",
		parking_report_label_start_parking_info:"Info",
		parking_report_label_business_projected_id:"Forretningsprojekteret id",
		datatable_search_placeholder_parking_report:"Søg efter bilnavn, status, forhandler, flådekunde, chauffør, placering, parkeringstype",
		datatable_search_placeholder_parking_report_reseller:"Søg efter bilnavn, status, flådekunde, chauffør, placering, parkeringstype",
		datatable_search_placeholder_parking_report_fleet:"Søg efter bilnavn, status, chauffør, placering, parkeringstype",
		datatable_search_placeholder_cron_job_report:"Søg efter status",
		datatable_search_placeholder_cron_job_report_first_layer:"Søg efter status, forhandler",
		datatable_search_placeholder_cron_job_report_first_layer_for_reseller:"Søg efter status",
		datatable_search_placeholder_cartime_to_reseller_for_admin:"Søg efter forhandler, flådekunde, status",
		datatable_search_placeholder_cartime_to_reseller_for_reseller:"Søg efter flådekunde, status",
		timeline_page_title:"Parkeringsanmodningslog",
		lbl_reference_id: "Reference-id",
		lbl_engine_id: "Motor-id",
		lbl_latitude: "Breddegrad",
		lbl_longitude: "Longitude",
		lbl_recorded_at: "Optaget kl.",
		lbl_received_at: "Modtaget kl.",
		lbl_day_parking_time: "Dagsparkeringstid",
		lbl_evening_parking_time: "Aftenparkeringstid",
		lbl_night_parking_time: "Natparkeringstid",
		lbl_parking_start_time: "Starttidspunkt for parkering",
		lbl_parking_provider_day_parking_per_hour_charge: "Parkeringsudbyder dagparkering pr. times opladning",
		lbl_parking_provider_evening_parking_per_hour_charge: "Parkeringsudbyder aftenparkering pr. times opladning",
		lbl_parking_provider_night_parking_per_hour_charge: "Parkeringsudbyder Natparkering pr. times opladning",
		lbl_cartime_to_reseller_pricing_plan: "Cartime til forhandlerens prisplan",
		lbl_cartime_to_reseller_day_parking_per_hour_charge: "Cartime til forhandler dagsparkering pr. Times opladning",
		lbl_cartime_to_reseller_evening_parking_per_hour_charge: "Cartime til forhandler aftenparkering pr. times opladning",
		lbl_cartime_to_reseller_night_parking_per_hour_charge: "Cartime til forhandler natparkering pr. times opladning",
		lbl_reseller_to_fleet_customer_hourly_charges: "Forhandler til Fleet-kundegebyrer pr. Time",
		lbl_reseller_to_fleet_customer_pricing_plan: "Forhandler til Fleet Customer Pricing Plan",
		lbl_reseller_to_fleet_customer_day_parking_per_hour_charge: "Forhandler til flådekundedagsparkering pr. Times opladning",
		lbl_reseller_to_fleet_customer_evening_parking_per_hour_charge: "Forhandler til flådekundeparkering pr. Time",
		lbl_reseller_to_fleet_customer_night_parking_per_hour_charge: "Forhandler til Fleet Customer Night Parkering pr. Times opladning",
		lbl_user_start_parking_request: "Brugerstartparkeringsanmodning",
		lbl_request_initiated_at: "Forespørgsel startet kl.",
		lbl_request_initiation_response: "Anmod om initieringssvar",
		lbl_user_start_parking_response: "Brugers startparkeringsrespons",
		lbl_parking_response: "Parkeringsrespons",
		lbl_user_response_recorded_at: "Brugersvar registreret kl.",
		lbl_parking_response_received_at: "Parkeringssvar modtaget kl.",
		lbl_parking_type: "Parkeringstype",
		lbl_business_project_number: "Forretningsprojektnummer",
		lbl_cartime_process: "Cartime Proces",
		lbl_parking_end_time: "Sluttid for parkering",
		lbl_total_calculated_day_parking_minutes: "Samlet beregnet parkeringsminutters dag",
		lbl_total_calculated_evening_parking_minutes: "Samlede beregnede parkeringsminutter om aftenen",
		lbl_total_calculated_night_parking_minutes: "Samlede beregnede natparkeringsminutter",
		lbl_parking_charges: "Parkeringsafgift",
		lbl_parking_provider_total_charge: "Total gebyr for parkeringsudbyder",
		lbl_cartime_to_reseller_total_charge: "Total gebyr for Cartime til forhandler",
		lbl_reseller_to_fleet_customer_total_charge: "Forhandler til Fleet Customer Total Charge",
		user_communication_source: "Brugerkommunikationskilde",
		engine_off: "Motor slukket",
		cartime_process_start_process: "Cartime-proces (start parkering)",
		parking_provider_hourly_charges: "Parkeringsudbyder timeafgifter",
		cartime_to_reseller_hourly_charges: "Cartime to Reseller Hourly Charges",
		reseller_to_fleet_customer_hourly_charges: "Reseller to Fleet Customer Hourly Charges",
		cartime_process_end_parking: "Cartime Process (End Parking)",
		parking_charges: "Parkeringsafgifter",
		end_parking: "End Parking",
		user_end_parking: "Endeparkering af bruger",
		request_initiation: "Anmod om initiering",
		parking_id:"Parkerings-id",
		parking_status:"Parkeringsstatus",
		lbl_type: "Type",
		lbl_license_plate: "Nummerplade",
		lbl_license_plate_type:"Nummerpladetype",
		btn_lbl_export_PLO_reconciliation:"Eksporter PLO-afstemning",
		total_parking_charge:"Samlet parkeringsafgift",
		btn_lbl_export_private_parking_details:"Eksporter detaljer om privat parkering",
		cartime_to_reseller_invoice: "Cartime til forhandlerfakturajob",
		reseller_to_fleet_customer_invoice:"Forhandler til Fleet-kundefakturajob",
		cartime_ald_invoice_jobs:"Cartime til ALD fakturajob",
		cartime_to_ald_invoice_jobs :"Cartime til ALD fakturajob",
		cron_job_reports:"Periodic jobrapporter",
		total_processed_resellers: "Samlede behandlede forhandlere",
		total_generated_invoices: "Samlede genererede fakturaer",
		total_processed_fleet_customers:"Samlede forarbejdede flådekunder",
		cron_job_status:"Cron jobstatus",
		invoice_period_from: "Faktura periode fra",
		invoice_period_to: "Fakturaperiode til",
		total_invoice_parking_count: "Samlet antal fakturaparkeringer",
		total_invoice_amount: "Samlet fakturabeløb",
		product_code: "Produktkode",
		product_description: "Produktbeskrivelse",
		quantity: "Mængde",
		unit: "Enhed",
		unit_price_ex_vat: "Enhedspris ekskl. moms",
		total_price_ex_vat: "Samlet pris ekskl. moms",
		invoice_id:"Faktura-ID",
		btn_title_view:"Udsigt",
		lbl_device:"Enhed",
		btn_lbl_view_parking_request:"Se parkeringsanmodninger",
		parking_page_header:"I øjeblikket ser du parkeringspladser til faktura med ID",
		parking_page_header_reseller:"I øjeblikket ser du parkeringsanmodninger til faktura for",
		from:"fra",
		to:"til",
		export_ALD_tooltip:"Eksportér ALD-fakturaoplysninger",
		export_cartime_reseller_invoice_summary:"Eksportér Cartime til resellerfakturaoversigt",
		some_error_occured:"Der opstod en fejl under behandlingen af din anmodning. Prøv efter et stykke tid.",
		error:"Fejl",
		session_expired:"Din session er udløbet",
		page_not_found:"Siden blev ikke fundet",
		invalid_request:"Ugyldig forespørgsel",
		validation_error:"Valideringsfejl fundet fra serverenden",
		cartime_reseller_commision_value:"Cartime til forhandler Provisionsværdi",
		parking_provider_total_charges: "Parkeringsudbyder samlede gebyrer",
		cartime_reseller_total_charges:"Cartime til forhandler samlede gebyrer",
		reseller_fleet_customer_total_charges:"Forhandler til Fleet-kunde samlede gebyrer",
		total_charge_ex_VAT: "Samlet gebyr ekskl. moms",
		total_VAT: "Samlet moms",
		total_charge_incl_VAT: "Samlet gebyr inkl. moms",
		commision_VAT_percentage: "Provisions-momsprocent",
		total_commission_ex_VAT: "Total provision ekskl. moms", 
		total_commission_incl_VAT: "Total provision inkl. moms",


		admin_users_list_heading: "Admin User(s) List",
		common_lbl_company_tax_reg_number: "Company Tax Registration Number",
		common_lbl_office_contact_number: "Office Contact Number",
		common_lbl_address: "Address"

    }
});


export default translationStrings;
