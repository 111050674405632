import React, { useState, useEffect } from 'react'
import CartCard from '../components/CartCard';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import { base_url } from '../utils/axios';
import { cartWatcher } from '../redux/actionCreators/cartActions'
import { showNotification } from "../utils/utils";
import { translationStrings} from '../utils/locales';
import * as constants from '../constants/constants';

const Cart = (props) => {

   const [cart, setCart] = React.useState([]);
   const [cartTotal, setCartTotal] = React.useState(0);
   const [cartTaxTotal, setCartTaxTotal] = React.useState(0);
   const [cartFinalTotal, setCartFinalTotal] = React.useState(0);
   const [showPlaceOrderButton, setShowPlaceOrderButton] = React.useState(false);
   const [buyQty, setBuyQty] = React.useState([]);

   /*const uniqueLocalStorageObject = () => {
      if (localStorage.getItem('cartItems')) {
         JSON.parse(localStorage.getItem('cartItems')).map((img, index) => {
            const search = obj => obj.id === img.id;
            let check = uniqueTags.findIndex(search);
            if (check === -1) {
               uniqueTags.push(img);
            }
         })
      }
   }

   uniqueLocalStorageObject();*/

   const total = () => {
      let totalVal = 0;
      let taxAmount = 0;
      let finalAmount = 0;
      if (localStorage.getItem('cartItems')) {
         let cart = JSON.parse(localStorage.getItem('cartItems'));
         if(cart.length > 0){
            setShowPlaceOrderButton(true);
            for (let i = 0; i < cart.length; i++) {
               if(cart[i].discount_percentage && cart[i].discount_percentage!==null){
                  let calcAmount = parseFloat(cart[i].unit_price*((100+cart[i].discount_percentage)/100));
                  calcAmount = parseFloat(calcAmount * cart[i].buy_qty);
                  totalVal += calcAmount;
               } else {
                  totalVal += parseFloat(cart[i].final_unit_price * cart[i].buy_qty);
               }
            }
            taxAmount = parseFloat(totalVal) * (constants.TAX_PERCENTAGE/100);
            finalAmount = parseFloat(totalVal) + parseFloat(taxAmount);
         } else {
            setShowPlaceOrderButton(false);
         }
      }
      setCartTotal(totalVal.toFixed(2));
      setCartTaxTotal(taxAmount.toFixed(2));
      setCartFinalTotal(finalAmount.toFixed(2));
   };

   const placeOrder = () => {
      var finalLocalStorage = [];
      if (localStorage.getItem('cartItems')) {
         JSON.parse(localStorage.getItem('cartItems')).map((singleItem) => {
            let quanityInputName = "product_quantity_input" + singleItem.id;
            let quanityInputValue = parseInt(document.getElementById(quanityInputName).value);
            let totalProductPrice = 0;
            let finalUnitPrice = 0;
            if(singleItem.discount_percentage && singleItem.discount_percentage!==null){
               totalProductPrice = parseFloat(singleItem.calc_price * quanityInputValue);
               finalUnitPrice = parseFloat(singleItem.calc_price);
            } else {
               totalProductPrice = parseFloat(quanityInputValue * singleItem.final_unit_price);
               finalUnitPrice = parseFloat(singleItem.final_unit_price);
            }
            
            var singleProductData = {
               part_number: singleItem.part_number,
               make: singleItem.brand,
               description: singleItem.description,
               weight: singleItem.weight,
               unit_price: singleItem.unit_price,
               final_unit_price: finalUnitPrice.toFixed(2),
               order_qty: quanityInputValue,
               supplier: singleItem.user_id,
               total_price: totalProductPrice.toFixed(2),
            }
            finalLocalStorage.push(singleProductData);
         })

         localStorage.setItem('finalCartItems', JSON.stringify(finalLocalStorage));
         localStorage.setItem('cartItems', []);

         const headers = {
            'x-auth-token': localStorage.getItem('accessToken')
         };

         let totalCartAmount = document.getElementById('subtotalCount').innerHTML;
         const placeOrderRequest = {
            total_amount: totalCartAmount,
            order_details: JSON.parse(localStorage.getItem('finalCartItems'))
         }

         axios.post(base_url + 'order', placeOrderRequest, { headers })
            .then(response => {
               localStorage.setItem('finalCartItems', []);
               window.location.href = '/thankyou';
            });

      } else {
         window.location.href = '/product';
      }

   }

   const incrementQuantity = (productId, maxQty) => {
      let quanityInputName = "product_quantity_input" + productId;
      let quanityInputValue = parseInt(document.getElementById(quanityInputName).value);
      let updatedValue = parseInt(quanityInputValue + 1);
      if(updatedValue <= maxQty){
         document.getElementById(quanityInputName).value = updatedValue;
      }
      updateTotalPrice();
   }

   const decrementQuantity = (productId, maxQty) => {
      let quanityInputName = "product_quantity_input" + productId;
      let quanityInputValue = parseInt(document.getElementById(quanityInputName).value);
      let updatedValue = parseInt(quanityInputValue - 1);
      if(updatedValue >= 1){
         document.getElementById(quanityInputName).value = updatedValue;
      }
      updateTotalPrice();
   }

   const updateTotalPrice = () => {
      var arr = [...buyQty];
      var unit_price_arr = document.getElementsByName('unit_price');
      var sub_total = document.getElementsByClassName('sub_total');
      var tot = 0;
      var perProductTotal = 0;
      for (var i = 0; i < arr.length; i++) {
         if (parseFloat(arr[i])){
            perProductTotal = arr[i] * unit_price_arr[i].innerHTML;
            sub_total[i].innerHTML = perProductTotal.toFixed(2);
         }
         tot += parseFloat(perProductTotal);
      }
      document.getElementById('totalCount').innerHTML = tot.toFixed(2);
   }

   const clearCart = (e) => {
      if(localStorage.getItem('cartItems')){
         new Promise((resolve, reject) => {
            props.cartWatcher([], resolve, reject)
         }).catch(e => { })
         showNotification('success', 'Success', translationStrings.cart_remove_msg);
         setTimeout(function(){
             window.location.reload();
         }, 1000);
      }
   }

   useEffect(() => {
      total();
      if(localStorage.getItem('cartItems') && JSON.parse(localStorage.getItem('cartItems')).length > 0) {
         setCart(JSON.parse(localStorage.getItem('cartItems')));
      }
   }, []);

   return (
      <React.Fragment>
         <section className="section-cart">
            <div className="features pt-5" id="features">
               <div className="container py-md-3">
                  {cart.length > 0 &&
                     <div className="row">
                        <div className="col-sm-12 pr-0 text-right pl-0">
                           <button type="button" className="btn btn-theme btn-multicart" onClick={(e) => clearCart(e)}>Clear Cart</button>
                         </div>
                     </div>
                  }
                  <div className="fea-gd-vv text-center row mt-3 pt-3">
                     <CartCard
                     cartData={cart}
                     incrementQty={incrementQuantity}
                     decrementQty={decrementQuantity}
                     cartTotal={cartTotal}
                     cartTaxTotal={cartTaxTotal}
                     cartFinalTotal={cartFinalTotal}
                     buyQty={buyQty}
                     setBuyQty={setBuyQty}
                     updateTotalPrice={updateTotalPrice}
                     />
                  </div>
                  <div className="row">
                     <div className="text-right" style={{width:'100%'}}>
                     {localStorage.getItem('accessToken') && showPlaceOrderButton ?
                        <button type="submit" onClick={() => placeOrder()}>Place Order</button>
                        :
                        null
                     }
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </React.Fragment>
   )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    requestUIHandler: state.requestUIHandler,
    cart: state.cart
})

const mapDispatchToProps = dispatch => bindActionCreators(
    { cartWatcher }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cart)