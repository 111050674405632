import React from 'react'

const CreditNoteCard = ({creditNoteData,creditNoteId}) => {
    // Create our number formatter.
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'AED',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    var dateFormatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });

    return (
       <table className="table table-striped table-responsive-md">
            <thead className="thead-theme">
                <tr>
                    <th>Part Number</th>
                    <th>Make</th>
                    <th>Description</th>
                    <th>Weight</th>
                    <th>Order Qty</th>
                    <th>Cancelled Qty</th>
                    <th>Buy Qty</th>
                    <th>Price</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {creditNoteData.length > 0 ?
                    creditNoteData.map((item, index) => {
                        if(item.credit_note_reference_id==creditNoteId){
                            return (
                                <tr key={index}>
                                    <td>{item.part_number}</td>
                                    <td>{item.make}</td>
                                    <td>{item.description}</td>
                                    <td>{item.weight.toFixed(3)}</td>
                                    <td>{item.order_qty}</td>
                                    <td>{item.cancelled_qty}</td>
                                    <td>{item.available_qty}</td>
                                    <td>AED {item.total_price.toFixed(2)}</td>
                                    {item.is_returned === 1 ?
                                        <td>RETURNED</td> : <td></td>
                                    }
                                </tr>
                            )
                        }
                    }) : <tr><td colSpan="9"><center>No record found!</center></td></tr>
                }
            </tbody>
        </table>
    )
}

export default CreditNoteCard;