import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import {customer_base_url} from '../utils/axios';
import * as constants from '../constants/constants';
import { showNotification } from "../utils/utils";
import { translationStrings} from '../utils/locales';

const ForgetPassword = ({ isAuthenticated }) => {

    const onChange = e => {
        e.target.name = e.target.value;
    }

    const goBack = e => window.location.href="/";

    const onSubmit = (e) => {
        e.preventDefault();

        const headers = {
            'x-auth-token': localStorage.getItem('accessToken')
        };
        axios.request({
            method: 'post',
            headers: headers,
            url: customer_base_url+constants.URLS.FORGOT_PASSWORD,
            data: {
                'email': document.getElementById('email').value
            }
            }).then(response =>{
                showNotification('success', 'Success', translationStrings.password_request_success);
                setTimeout(function(){
                    window.location.href='/login';
                }, 1000);
            });
    }

    useEffect(() => {
    }, []) 

    return (
        <React.Fragment>
            <section class="profileBox backBox">
                <div class="profileWrapper container">
                    <div class="form">
                        <h5 class="title my-0 font-weight-normal">Forget Password</h5>
                        <form class="profileForm flex flex-wrap col-sm-12" onSubmit={e => onSubmit(e)}>
                            <div class="col-sm-3 flex flex-column field-box my-2">
                            </div>
                            <div class="col-sm-6 flex flex-column field-box my-2">
                                <label> Email</label>
                                <input type="text"
                                    placeholder="Email address"
                                    id="email"
                                    name="email"
                                    required="true"
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <div class="col-sm-3 flex flex-column field-box my-2">
                            </div>
                            <div class="col-sm-12 field-box my-3 text-center">
                                <button className="btn btn-sm btn-primary">Send</button>&nbsp;&nbsp;
                                <button className="btn btn-sm btn-danger" onClick={(e) => goBack(e)}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    requestUIHandler: state.requestUIHandler,
    data: state.data
});

export default connect(mapStateToProps, null)(ForgetPassword)
