import React from 'react'
import {currencyFormatter, dateFormatter} from '../utils/utils';

const AccountDetailsCard = ({ledgerData, accountBalance}) => {

    var setAccountBalance = 0;

    return (
       <table className="table table-striped table-responsive-md">
            <thead className="thead-theme">
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Invoice/Payment Reference</th>
                    <th scope="col">Date</th>
                    <th scope="col">Description</th>
                    <th scope="col">Invoice Amount</th>
                    <th scope="col">Payment Amount</th>
                    <th scope="col">Account Balance</th>
                </tr>
            </thead>
            <tbody>
                {ledgerData.length > 0 ?
                    ledgerData.map((item, index) => {
                        if(index <= ledgerData.length-1 && item.type=='CREDIT' && item.amount > 0){
                            setAccountBalance += item.amount;
                        } else if(index <= ledgerData.length-1 && item.type=='DEBIT') {
                            setAccountBalance -= Math.abs(item.amount);
                        }
                        return (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    {item.type=='DEBIT' &&
                                        <td>I-{item.debit_reference_id}</td>
                                    }
                                    {item.type=='CREDIT' && item.credit_reference_id &&
                                        <td>D-{item.credit_reference_id}</td>
                                    }
                                    {item.type=='CREDIT' && item.credit_note_reference_id &&
                                        <td>D-{item.credit_note_reference_id}</td>
                                    }
                                    <td>{dateFormatter.format(new Date(item.entry_date))}</td>
                                    <td>{item.description}</td>
                                    {item.type=='DEBIT' &&
                                        <>
                                            <td>{currencyFormatter.format(item.amount)}</td>
                                            <td>-</td>
                                        </>
                                    }
                                    {item.type=='CREDIT' &&
                                        <>
                                            <td>-</td>
                                            <td>{currencyFormatter.format(item.amount)}</td>
                                        </>
                                    }
                                    {/*<td>{currencyFormatter.format(setAccountBalance)}</td>*/}
                                    <td>{currencyFormatter.format(item.account_balance)}</td>
                                </tr>
                            )
                    }) : <tr><td colSpan="7"><center>No record found!</center></td></tr>
                }
            </tbody>
        </table>
    )
}

export default AccountDetailsCard;