import React, { useState, useEffect } from 'react';
import AccountDetailsCard from '../components/AccountDetailsCard';
import axios from 'axios'
import { base_url, customer_base_url } from '../utils/axios';
import $ from 'jquery';

const AccountDetails = () => {

    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [sortOrder, setSortOrder] = React.useState(0);
    const [sortField, setSortField] = React.useState(null);
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [rows, setRows] = React.useState(10);
    const [totalPageCount, setTotalPageCount] = React.useState(0);
    const [previousDisable, setPreviousDisable] = React.useState(true);
    const [nextDisable, setNextDisable] = React.useState(false);
    const [fetchedStatement, setFetchedStatement] = React.useState([]);
    const [customerDtls, setCustomerDtls] = React.useState([]);
    const [accountBalance, setAccountBalance] = React.useState(0.00);
    const [openingBalance, setOpeningBalance] = React.useState(0.00);
    const [check, setCheck] = React.useState(false);

    const fetchLedger = () => {
        const headers = {
            'x-auth-token': localStorage.getItem('accessToken')
        };

        // Set Defined Values
        setRows(10);
        //setSortOrder(-1);

        const FetchStatementRequest = {
            event: {
                first: offset,
                rows: rows,
                page: page,
                pageCount: 1,
                sortOrder: sortOrder,
                sortField: sortField,
                filters: {},
                globalFilter: globalFilter,
                customFilters: ""
            }
        }

        axios.post(customer_base_url + 'account-statement/list', FetchStatementRequest, { headers })
            .then(response => {
                setFetchedStatement(response.data.data);
                setCustomerDtls(response.data.customerDtls);
                setAccountBalance(response.data.accountBalance.toFixed(2));
                setOpeningBalance(response.data.openingBalance.toFixed(2));
                setTotalPageCount(Math.ceil(response.data.recordsTotal / rows))
            });
    }

    const onChange = e => setGlobalFilter(e.target.value)

    const paginate = (pageId) => {
      var arr = document.getElementsByClassName('page-item');
      for (var i = 0; i < arr.length; i++) {
         arr[i].classList.remove('active');
      }

      document.getElementById('pagination_button_' + pageId).classList.add('active');
      if (pageId < 1) {
         setPreviousDisable(true);
         setOffset(0);
      } else {
         setPreviousDisable(false);
         setRows(10);
         setOffset(parseInt(pageId * rows));
      }

      if (pageId + 1 == totalPageCount) {
         setNextDisable(true);
      } else {
         setNextDisable(false);
      }
      
      setPage(pageId + 1);

      setCheck(true);
   }

   const previousClick = () => {
      let newPage;
      var arr = document.getElementsByClassName('page-item');
      for (var i = 0; i < arr.length; i++) {
         arr[i].classList.remove('active');
      }
      if (page == 2) {
         setPreviousDisable(true);
         setOffset(0);
         newPage = 1;
         let newPaginateElement = newPage - 1;
         document.getElementById('pagination_button_' + newPaginateElement).classList.add('active');
      } else {
         newPage = page - 1;
         let newPaginateElement = newPage - 1;
         document.getElementById('pagination_button_' + newPaginateElement).classList.add('active');
         setOffset(parseInt((newPaginateElement) * 10));
      }

      setPage(newPage);

      
      if ((page - 1) == totalPageCount) {
         setNextDisable(true);
      } else {
         setNextDisable(false);
      }

      setRows(10);
      setCheck(true);
   }

   const nextClick = () => {
      let newPage;
      var arr = document.getElementsByClassName('page-item');
      for (var i = 0; i < arr.length; i++) {
         arr[i].classList.remove('active');
      }
      if (page == 0) {
         if (page + 1 !== totalPageCount) {
            newPage = 1;
            setPage(newPage);
            document.getElementById('pagination_button_' + newPage).classList.add('active');
            setOffset(0);
         }
      } else {
         newPage = page + 1;
         let newPaginateElement = newPage - 1;
         document.getElementById('pagination_button_' + newPaginateElement).classList.add('active');
         setOffset(parseInt((newPaginateElement) * 10));
         setPage(newPage);
         setPreviousDisable(false);
      }

      if ((page + 1) == totalPageCount) {
         setNextDisable(true);
      } else {
         setNextDisable(false);
      }

      setRows(10);
      setCheck(true);
   }

    const onSort = (e) => {
       setSortField('entry_date');
       if(e.target.value==2){
           setSortOrder(2);
       } else {
           setSortOrder(1);
       }
       setCheck(true);
   }

    /*useEffect(() => {
        //fetchProduct();
    }, [])*/

    useEffect(() => {
        if (page == totalPageCount) {
            setNextDisable(true);
        } else {
            setNextDisable(false);
        }
    },[totalPageCount]);

    useEffect(() => {
        if(check==true){

        }
        fetchLedger();
        setCheck(false);
    }, [check])

    // Create our number formatter.
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'AED',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return (
        <section className="profileBox backBox orderDetailsBox">
            <div className="profileWrapper container">
               <div className="form">
                  <h5 className="title my-0 font-weight-normal">Account Statement</h5>
                   <div className="accountDetilsWrapper">
                      <div className="customerInfoBox row">
                         <div className="col-6">
                            <p className="d-flex flex-column mb-2">
                               <span className="title">Customer ID </span>
                               <span className="value">{customerDtls.user_id}</span>
                            </p>
                            <p className="d-flex flex-column mb-2">
                               <span className="title">Customer Name </span>
                               <span className="value">{customerDtls.first_name} {customerDtls.last_name}</span>
                            </p>
                         </div>
                         <div className="col-6">
                            <p className="text-right d-flex flex-column mb-2">
                               <span className="title">Account Balance </span>
                               <span className="value">{formatter.format(accountBalance)}</span>
                            </p>
                            {/*<p className="text-right d-flex flex-column mb-2">
                               <span className="title">Opening Balance </span>
                               <span className="value">{formatter.format(openingBalance)}</span>
                            </p>*/}
                         </div>
                      </div>
                   </div>
                  <div className="filterWrapper">
                   <div className="filter">
                       <span className="filterTitle">Sort By: </span>
                       <select onChange={e => onSort(e)} defaultValue={'2'}>
                            <option value='2'>Created Desc</option>
                            <option value='1'>Created Asc</option>
                        </select>
                   </div>
               </div>
                    <div className="cartWrapper orderWrapper">
                        <div className="container-fluid">
                            <AccountDetailsCard
                                ledgerData={fetchedStatement}
                                openingBalance={openingBalance}
                                accountBalance={accountBalance}
                            />
                        </div>
                    </div>
                    <section className="text-right paginationBox mb-4">
                        <nav aria-label="...">
                            <ul className="pagination">
                                {previousDisable ?
                                    <li className="page-item disabled">
                                        <span className="page-link">Previous</span>
                                    </li> :
                                    <li className="page-item">
                                        <span className="page-link" style={{ cursor: 'pointer' }} onClick={() => previousClick()}>Previous</span>
                                    </li>
                                }

                                {
                                    Array.apply(null, { length: totalPageCount }).map((e, index) => {
                                        let buttonId = "pagination_button_" + index;
                                        return (
                                            index==0 ?
                                            <li className="page-item active" id={buttonId} key={index}><button className="page-link" style={{ cursor: 'pointer' }} onClick={() => paginate(index)}>{index + 1}</button></li>
                                            : <li className="page-item " id={buttonId} key={index}><button className="page-link" style={{ cursor: 'pointer' }} onClick={() => paginate(index)}>{index + 1}</button></li>
                                        )
                                    })
                                }

                                {nextDisable ?
                                    <li className="page-item disabled">
                                        <button className="page-link" style={{ cursor: 'pointer' }}>Next</button>
                                    </li> :
                                    <li className="page-item">
                                        <button className="page-link" style={{ cursor: 'pointer' }} onClick={() => nextClick()}>Next</button>
                                    </li>
                                }
                            </ul>
                        </nav>
                    </section>
                </div>
            </div>
        </section>
    )
}

export default AccountDetails;