import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from "react-redux";
import 'react-notifications-component/dist/theme.css'
import ReactNotification from 'react-notifications-component'
import PublicRoute from "./PublicRouter";
import PrivateRoute from "./PrivateRouter";
import store from './redux/store';
import Layout from './containers/Layout';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Home from './containers/Home';
import About from './containers/About';
import Contanct from './containers/Contanct';
import Footer from './containers/Footer';
import Blog from './containers/Blog';
import Profile from './components/Profile';
import Product from './containers/Products';
import ThankYou from './containers/ThankYou';
import AccountDetails from './containers/AccountDetails';
import OrderList from './containers/OrderList';
import Cart from './containers/Cart';
import CreditNote from './containers/CreditNote';
import ChangePassword from './components/ChangePassword';
import ForgetPassword from'./components/ForgetPassword';
import ResetPassword from'./components/ResetPassword';
import Spinner from "./components/common/Spinner";
import SingleBlog from './containers/SingleBlog';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ReactNotification />
        <Spinner />
        <Layout />
        <PublicRoute exact path="/" component={Home} />
        <PublicRoute exact path="/about" component={About} />
        <PublicRoute exact path="/contact" component={Contanct} />
        <PublicRoute exact path="/login" component={Login} />
        <PublicRoute exact path="/signup" component={SignUp} />
        <PublicRoute exact path="/blog" component={Blog} />
        <PublicRoute exact path="/single-blog/:id" component={SingleBlog}/>
        <PrivateRoute exact path='/profile' component={Profile} />
        <PublicRoute exact path='/product' component={Product} />
        <PrivateRoute exact path='/thankyou' component={ThankYou} />
        <PrivateRoute exact path='/accountdetails' component={AccountDetails} />
        <PrivateRoute exact path='/orderlist' component={OrderList} />
        <PublicRoute exact path='/cart' component={Cart} />
        <PrivateRoute exact path='/credit-note/:orderId' component={CreditNote} />
        <PrivateRoute exact path='/changepassword' component={ChangePassword} />
        <PublicRoute exact path='/forgetpassword' component={ForgetPassword}/>
        <PublicRoute exact path='/resetpassword/:id/:token' component={ResetPassword}/>
      </Router>
      <Footer />
    </Provider>
  );
}

export default App;
