import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { base_url, customer_base_url, download_file_url } from '../utils/axios';
import * as utils from "../utils/utils";
const Footer = () => {

   window.onscroll =  () => {
      scrollFunction()
   };
   const [ftfetchedNews, setftFetchedNews] = React.useState([]);
   const [ftoffset, setftOffset] = React.useState(0);
   const [ftpage, setftPage] = React.useState(1);
   const [ftsortOrder, setftSortOrder] = React.useState(0);
   const [ftsortField, setftSortField] = React.useState(null);
   const [ftglobalFilter, setftGlobalFilter] = React.useState("");
   const [fttotalPageCount, setftTotalPageCount] = React.useState(0);
   const [ftrows, setftRows] = React.useState(10);
   const ftfetchNews = () => {
        const headers = {
            'x-auth-token': localStorage.getItem('accessToken')
        };

        // Set Defined Values
        setftRows(10);
        //setSortOrder(-1);

        const FetchNewsRequest = {
            event: {
                first: ftoffset,
                rows: ftrows,
                page: ftpage,
                pageCount: 1,
                sortOrder: ftsortOrder,
                sortField: ftsortField,
                filters: {},
                globalFilter: ftglobalFilter,
                customFilters: ""
            }
        }

      axios.post(customer_base_url + 'latest-news/list', FetchNewsRequest, { headers })
            .then(response => {
                setftFetchedNews(response.data.data);
                setftTotalPageCount(Math.ceil(response.data.recordsTotal / ftrows))
            });
   }

   const scrollFunction = () => {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
         document.getElementById("movetop").style.display = "block";
      } else {
         document.getElementById("movetop").style.display = "none";
      }
   }

   // When the user clicks on the button, scroll to the top of the document
   const topFunction = () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
   }

   useEffect(() => {
      ftfetchNews();
   }, [])
   
   return (
      <React.Fragment>
         <section className="section-footer-29-main" id="footer">
            <div className="footer-29">
               <div className="container">
                  <div className="d-grid grid-col-4 footer-top-29">
                     <div className="footer-list-29 footer-1">
                        <h6 className="footer-title-29">Connect with us</h6>
                        <div className="main-social-footer-29">
                           <a href="#facebook" className="facebook"><span className="fa fa-facebook"></span></a>
                           <a href="#twitter" className="twitter"><span className="fa fa-twitter"></span></a>
                           <a href="#instagram" className="instagram"><span className="fa fa-instagram"></span></a>
                           <a href="#google-plus" className="google-plus"><span className="fa fa-google-plus"></span></a>
                           <a href="#linkedin" className="linkedin"><span className="fa fa-linkedin"></span></a>
                        </div>
                     </div>
                     <div className="footer-list-29 footer-4">
                        <ul>
                           <h6 className="footer-title-29">Quick Links</h6>
                           <li><a href="/">Home</a></li>
                           <li><a href="/about">About</a></li>
                           <li><a href="/product">Products</a></li>
                           <li><a href="/blog"> Blog</a></li>
                           <li><a href="/contact">Contact</a></li>
                        </ul>
                     </div>
                     <div className="footer-list-29 footer-3">
                        <div className="properties">
                           <h6 className="footer-title-29">Trending News</h6>
                           {ftfetchedNews &&
                              ftfetchedNews.map((item, i) => {
                                 if(i<3){
                                    let itemId = `/single-blog/${item.id}`;
                                    return (
                                       <a key={i} href={itemId}>
                                          <img src={item.news_image ? item.news_image : download_file_url+'/no-preview.jpg'} className="img-responsive" alt=""/>
                                          <p>{item.news_title}</p>
                                       </a>
                                    )
                                 }
                              })
                           }
                        </div>
                     </div>
                     <div className="footer-list-29 footer-3">
                        <div className="properties">
                           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462562.65102636546!2d54.94754444558808!3d25.075759435668097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43348a67e24b%3A0xff45e502e1ceb7e2!2sBurj%20Khalifa!5e0!3m2!1sen!2sin!4v1625549921626!5m2!1sen!2sin" width="250" height="220" style={{border:0}} allowFullScreen="" loading="lazy"></iframe>
                        </div>
                     </div>
                  </div>
                  <div className="bottom-copies text-center">
                     <p className="copy-footer-29">Copyright All Rights Reserved © 2021</p>
                  </div>
               </div>
            </div>
            <button onClick={() => topFunction()} id="movetop" title="Go to top">
            <span className="fa fa-angle-up"></span>
            </button>
         </section>
      </React.Fragment>
   )
}

export default Footer
