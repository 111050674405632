import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { cartWatcher } from '../redux/actionCreators/cartActions'
import * as constants from '../constants/constants';

const CartCard = (props) => {

   const [cartItems, setCartItems] = React.useState([]);
   const [finalTotal, setFinalTotal] = React.useState(0);
   const [subTotal, setSubTotal] = React.useState(0);
   const [taxTotal, setTaxTotal] = React.useState(0);

   const removeFromCart = (productId) => {
      if(localStorage.getItem('cartItems')){
         let newCopy = JSON.parse(localStorage.getItem('cartItems'));
         if(newCopy.length > 0){
            newCopy = newCopy.filter((cartItem) => cartItem.id !== productId);
            new Promise((resolve, reject) => {
               props.cartWatcher(newCopy, resolve, reject)
               setCartItems(newCopy);
               let totalVal = 0;
               let taxAmount = 0;
               let finalAmount = 0;
               if (newCopy.length > 0) {
                  for (let i = 0; i < newCopy.length; i++) {
                     let quantityInputValue = document.getElementById("product_quantity_input" + newCopy[i].id).value;
                     if(newCopy[i].discount_percentage && newCopy[i].discount_percentage!==null){
                        let calcAmount = parseFloat(newCopy[i].unit_price*((100+newCopy[i].discount_percentage)/100));
                        calcAmount = parseFloat(calcAmount * quantityInputValue);
                        totalVal += calcAmount;
                     } else {
                        totalVal += parseFloat(newCopy[i].final_unit_price * quantityInputValue);
                     }
                  }
               }
               setSubTotal(totalVal.toFixed(2));
               taxAmount = parseFloat(totalVal) * (constants.TAX_PERCENTAGE/100);
               setTaxTotal(taxAmount.toFixed(2));
               finalAmount = parseFloat(totalVal) + parseFloat(taxAmount);
               setFinalTotal(finalAmount.toFixed(2));
            }).catch(e => { })
         }
         //window.location.reload();   
      }
   }

   const calcPrice = (item) => {
        if(item.discount_percentage && item.discount_percentage!==null){
            let calcAmount = parseFloat(item.unit_price*((100+item.discount_percentage)/100));
            return calcAmount.toFixed(2);
        } else {
            return item.final_unit_price.toFixed(2);
        }
   }

   const getBuyQty = (itemId) => {
        let currentObj = props.cart.data.find(e => e.id === itemId);
        return currentObj.buy_qty;
   }

   const handleChange = (e, index) => {
        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if(value != ""){
            if(parseInt(value) < parseInt(target.min)){
                value = target.min;
            }
            if(parseInt(value) > parseInt(target.max)){
               value = target.max;
            }
        } else {
            value = 0;
        }

        let items = [...props.buyQty];
        items[index] = value;
        props.setBuyQty(items);
        //props.updateTotalPrice();
    }

    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    useEffect(() => {
      var arr = document.getElementsByName('qty');
      var unit_price_arr = document.getElementsByName('unit_price');
      var sub_total = document.getElementsByClassName('sub_total');
      var tax_amount = document.getElementsByClassName('tax_amount');
      var final_amount = document.getElementsByClassName('final_amount');
      var tot = 0;
      var tax_tot = 0;
      var final_tot = 0;
      var perProductTotal = 0;
      for (var i = 0; i < arr.length; i++) {
         if (parseFloat(arr[i].value)){
            perProductTotal = arr[i].value * unit_price_arr[i].innerHTML;
            sub_total[i].innerHTML = perProductTotal.toFixed(2);
            tax_amount[i].innerHTML = parseFloat(perProductTotal * (constants.TAX_PERCENTAGE/100)).toFixed(2);
            var calc_final_amount = parseFloat(perProductTotal) + parseFloat(tax_amount[i].innerHTML);
            final_amount[i].innerHTML = calc_final_amount.toFixed(2);
         }
         tot += parseFloat(sub_total[i].innerHTML);
         tax_tot  += parseFloat(tax_amount[i].innerHTML);
         final_tot += parseFloat(final_amount[i].innerHTML);
      }
      document.getElementById('subtotalCount').innerHTML = tot.toFixed(2);
      document.getElementById('taxtotalCount').innerHTML = tax_tot.toFixed(2);
      document.getElementById('totalCount').innerHTML = final_tot.toFixed(2);
    }, [props.buyQty]);

    useEffect(() => {
       setCartItems(props.cartData);
       setFinalTotal(props.cartFinalTotal);
       setSubTotal(props.cartTotal);
       setTaxTotal(props.cartTaxTotal);
    },[props.cartData]);

   return (
      <React.Fragment>
         <table className="table table-bordered table-responsive-md">
            <thead className="thead-theme">
               <tr>
                  <th width="10%">Make</th>
                  <th width="10%">Part Number</th>
                  <th width="10%">Supplier</th>
                  <th width="13%">Handling Time</th>
                  <th width="13%">Description</th>
                  <th width="8%">Weight</th>
                  <th width="8%">Availability</th>
                  <th width="8%">Price</th>
                  <th width="5%">Qty</th>
                  <th width="8%">Sub Total</th>
                  <th width="8%">Tax</th>
                  <th width="8%">Final Amount</th>
                  <th width="7%"></th>
               </tr>
            </thead>
            <tbody>
               {cartItems.length > 0 ?
                  cartItems.map((cartItem, index) => {
                     let quantityInputId = "product_quantity_input" + cartItem.id;
                     return (
                        <tr key={index}>
                           <td>{cartItem.brand}</td>
                           <td>{cartItem.part_number}</td>
                           <td>{cartItem.supplier_code_name}</td>
                           <td>{cartItem.handling_time}</td>
                           <td>{renderHTML(cartItem.description)}</td>
                           <td>{cartItem.weight.toFixed(3)}</td>
                           <td>{cartItem.available_qty}</td>
                           <td>AED <span name="unit_price">{calcPrice(cartItem)}</span></td>
                           <td>
                              <div>
                                 {/*<button className="button-count" onClick={() => props.decrementQty(cartItem.id, cartItem.available_qty)}>-</button>*/}
                                 <input type="number" className="number-product" id={quantityInputId} min="1" max={cartItem.available_qty} name="qty" onChange={(e) => handleChange(e, index)} value={props.buyQty.length > 0 && props.buyQty[index] ? props.buyQty[index]: cartItem.buy_qty} />
                                 {/*<button className="button-count" onClick={() => props.incrementQty(cartItem.id, cartItem.available_qty)}>+</button>*/}
                              </div>
                           </td>
                           <td><span className="sub_total">{cartItem.sub_price}</span></td>
                           <td><span className="tax_amount">{cartItem.tax_amount}</span></td>
                           <td><span className="final_amount">{cartItem.final_amount}</span></td>
                           <td><button className="remove-cart" onClick={() => removeFromCart(cartItem.id)}><i className="fa fa-close"></i></button></td>
                        </tr>
                     )
                  }) : <tr><td colSpan="13"><center>Your cart is empty!<br/><a className="btn btn-theme1 my-2" href="/product">Shop Now</a></center></td></tr>
               }
               <tr>
                  <td colSpan="9" className="totalPriceCount">Total (AED):</td>
                  <td className="text-left"><span id="subtotalCount">{subTotal}</span></td>
                  <td className="text-left"><span id="taxtotalCount">{taxTotal}</span></td>
                  <td colSpan="2" className="text-left"><span id="totalCount">{finalTotal}</span></td>
               </tr>
            </tbody>
         </table>
      </React.Fragment>
   )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    requestUIHandler: state.requestUIHandler,
    cart: state.cart
})

const mapDispatchToProps = dispatch => bindActionCreators(
    { cartWatcher }, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(CartCard)