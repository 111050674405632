import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { base_url, customer_base_url } from '../utils/axios';

const About = () => {
    const [fetchedAboutUs, setFetchedAboutUs] = React.useState([]);
    const fetchAboutUs = () => {
        const headers = {
            'x-auth-token': localStorage.getItem('accessToken')
        };

       
        axios.get(customer_base_url + 'about-us/1', { headers })
            .then(response => {
                setFetchedAboutUs(response.data.data);
            });
    }

    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    useEffect(() => {
        fetchAboutUs();
    }, [])

    return (
        <React.Fragment>
            <section className="section-about-breadcrum">
                <div className="breadcrum-bg">
                    <div className="container py-5">
                        <p><a href="index.html">Home</a> &nbsp; / &nbsp; About</p>
                        <h2 className="my-3">About Us</h2>
                        {/* <p>Nulla dolorem perferendis inventore! posuere cubilia Curae; Nunc non risus in justo convallis feugiat. consectetur adipisicing elit.</p> */}
                    </div>
                </div>
            </section>
            <section className="section-content-with-photo-4" id="about">
                <div id="content-with-photo4-block" className="pt-5">
                    <div className="container py-md-3">
                        <div className="cwp4-two row">
                            <div className="cwp4-text col-lg-6">
                                <h3>Who We Are</h3>
                                <p>{renderHTML(fetchedAboutUs.about_content)}</p>
                            </div>
                            <div className="cwp4-image col-lg-6 pl-lg-5 mt-lg-0 mt-5">
                                <img src="assets/images/5.jpg" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-features-5">
                <div className="features py-4">
                    <div className="container pb-5">
                        <div className="fea-gd-vv row ">
                            <div className="float-lt feature-gd col-lg-4 col-sm-6">
                                <div className="icon-info">
                                    <h5>Our History</h5>
                                    <p>{renderHTML(fetchedAboutUs.our_history)}</p>
                                </div>
                            </div>
                            <div className="float-mid feature-gd col-lg-4 col-sm-6 mt-sm-0 mt-4">
                                <div className="icon-info">
                                    <h5>Our Milestones</h5>
                                    <p>{renderHTML(fetchedAboutUs.our_milestones)}</p>
                                </div>
                            </div>
                            <div className="float-rt feature-gd col-lg-4 col-sm-6 mt-lg-0 mt-4">
                                <div className="icon-info">
                                    <h5>Our Customer service</h5>
                                    <p>{renderHTML(fetchedAboutUs.our_customer_service)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default About
