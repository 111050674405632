import React, {useEffect} from 'react'
import $ from 'jquery';
import {currencyFormatter, dateFormatter} from '../utils/utils';
import * as constants from '../constants/constants';

const OrderListCard = ({
    orderAll,
    getOrderDetails,
    returnOrder,
    cancelOrder,
    updateOrderItem,
    returnOrderItem,
    cancelOrderItem,
    singleOrderDetails,
    creditNote
}) => {

    const [orderDetail, setOrderDetail] = React.useState([]);
    const handleChange = (e, itemObj) => {
        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if(value != ""){
            if(parseInt(value) < parseInt(target.min)){
                value = target.min;
            }
            if(parseInt(value) > parseInt(target.max)){
               value = target.max;
            }
        } else {
            value = 0;
        }
        let i = 0;
        orderDetail.forEach(function(singleItem) {
            if(singleItem.id==itemObj.id){
              let items = [...orderDetail];
              let item = {...items[i]};
              item.available_qty = parseInt(value);
              item.cancelled_qty = item.order_qty - item.available_qty;
              let calc_total_price = itemObj.final_unit_price * parseInt(value);
              item.total_price = calc_total_price.toFixed(2);
              items[i] = item;
              setOrderDetail(items);
            }
          i++;
      });
      /*setOrderDetail({...orderDetail, [item.id]: value});
      let calcItemPrice = item.final_unit_price * value;
      document.getElementById('itemTotal'+item.id).innerHTML = calcItemPrice.toFixed(2);
      let totalAmount = 0;
      $('.itemTotal').each(function(){
          totalAmount += parseFloat($(this).html());
      });
      document.getElementById('totalAmount').innerHTML = totalAmount.toFixed(2);*/
    }

    const displayCancelledQty = (index, item) => {
        if(orderDetail.length > 0 && orderDetail[index]){
            return orderDetail[index].cancelled_qty;
        } else {
            return item.cancelled_qty;
        }
    }

    const displayTotalAmount = (itemObj) => {
        if(orderDetail.length > 0){
            let calcTotalPrice = 0;
            orderDetail.map((singleItem, index) => {
                if(singleItem.is_returned==0 && singleItem.is_active==1){
                    calcTotalPrice += parseFloat(singleItem.total_price);
                }
            });
            return parseFloat(calcTotalPrice).toFixed(2);
        } else {
            return parseFloat(itemObj.total_amount).toFixed(2);
        }
    }

    const displayTaxAmount = (itemObj) => {
        if(orderDetail.length > 0){
            let calcTotalPrice = 0;
            orderDetail.map((singleItem, index) => {
                if(singleItem.is_returned==0 && singleItem.is_active==1){
                    calcTotalPrice += parseFloat(singleItem.total_price);
                }
            });
            let calcTax = calcTotalPrice * (constants.TAX_PERCENTAGE/100);
            return parseFloat(calcTax).toFixed(2);
        } else {
            return parseFloat(0).toFixed(2);
        }
    }

    const displayFinalAmount = (itemObj) => {
        if(orderDetail.length > 0){
            let calcTotalPrice = 0;
            orderDetail.map((singleItem, index) => {
                if(singleItem.is_returned==0 && singleItem.is_active==1){
                    calcTotalPrice += parseFloat(singleItem.total_price);
                }
            });
            let calcTax = calcTotalPrice * (constants.TAX_PERCENTAGE/100);
            alert(calcTotalPrice);
            alert(calcTax);
            let finalTotal = calcTotalPrice + calcTax;
            alert(finalTotal);
            return parseFloat(finalTotal).toFixed(2);
        } else {
            return parseFloat(0).toFixed(2);
        }
    }

    const displayItemPrice = (i, item) => {
        if(orderDetail.length > 0 && orderDetail[i]){
            return parseFloat(orderDetail[i].total_price).toFixed(2);
        } else {
            return parseFloat(item.total_price).toFixed(2);
        }
    }

    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    useEffect(()=> {
        if(singleOrderDetails){
            setOrderDetail(singleOrderDetails.order_dtls);
        }
    },[singleOrderDetails]);

    return (
        <React.Fragment>
            <table className="table table-bordered table-responsive-md">
                <thead className="thead-theme">
                    <tr>
                        <th>Order ID</th>
                        <th>Status</th>
                        <th>Invoice No</th>
                        <th>Date&Time</th>
                        <th>Sub Total</th>
                        <th>Tax</th>
                        <th>Final Total</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {orderAll.length > 0 ?
                        orderAll.map((singleOrderItem, index) => {
                            return (
                                <tr key={index}>
                                    <td>{singleOrderItem.id}</td>
                                    <td>{singleOrderItem.status}</td>
                                    <td>{singleOrderItem.invoice_no}</td>
                                    <td>{dateFormatter.format(new Date(singleOrderItem.order_date))}</td>
                                    <td>{currencyFormatter.format(singleOrderItem.total_amount.toFixed(2))}</td>
                                    <td>{currencyFormatter.format(singleOrderItem.tax_amount.toFixed(2))}</td>
                                    <td>{currencyFormatter.format(singleOrderItem.final_amount.toFixed(2))}</td>
                                    <td>
                                        <button className="btn btn-theme1" type="button" data-toggle="modal" data-target="#orderDetailsModal" onClick={(e) => getOrderDetails(singleOrderItem.id, e)}><i className="fa fa-eye"></i></button>&nbsp;&nbsp;
                                        {singleOrderItem.status=='PENDING' &&
                                            <>
                                            <button className="btn btn-danger" type="button" onClick={(e) => cancelOrder(singleOrderItem.id, e)}><i className="fa fa-times"></i> CANCEL</button>
                                            </>
                                        }
                                        {/*singleOrderItem.status=='COMPLETED' &&
                                            <>
                                            &nbsp;&nbsp;<button className="btn btn-danger" type="button" onClick={(e) => returnOrder(singleOrderItem.id, e)}><i className="fa fa-cart-arrow-down"></i> RETURN</button>
                                            </>
                                        }
                                        {singleOrderItem.status=='RETURNED' &&
                                            <a className="btn btn-primary" onClick={(e) => creditNote(singleOrderItem.id, e)}><i className="fa fa-sticky-note"></i> CREDIT NOTE</a>
                                        */}
                                    </td>
                                </tr>
                            )
                        }): <tr><td colSpan="6">No order history found!</td></tr>
                    }
                </tbody>
            </table>
            <div className="modal fade" id="orderDetailsModal" tabIndex="-1" role="dialog" aria-labelledby="orderDetailsModalLabel" aria-hidden="true">
                <div className="modal-dialog checkoutBoxWrapper" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Order details for #{singleOrderDetails ? singleOrderDetails.id : 'N/A'}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {singleOrderDetails &&
                                <React.Fragment>
                                <p><strong>Order Id: </strong>{singleOrderDetails.id}</p>
                                <p><strong>Status: </strong>{singleOrderDetails.status}</p>
                                <p><strong>Date&Time: </strong>{dateFormatter.format(new Date(singleOrderDetails.order_date))}</p>
                                <p><strong>Sub Total: </strong><span>{currencyFormatter.format(singleOrderDetails.total_amount.toFixed(2))}</span></p>
                                <p><strong>VAT: </strong><span>{currencyFormatter.format(singleOrderDetails.tax_amount.toFixed(2))}</span></p>
                                <p><strong>Total: </strong><span>{currencyFormatter.format(singleOrderDetails.final_amount.toFixed(2))}</span></p>
                                {singleOrderDetails.order_dtls &&
                                    <table className="table table-bordered table-responsive-md">
                                        <thead>
                                        <tr>
                                            <th>Part Number</th>
                                            <th>Make</th>
                                            <th>Supplier</th>
                                            <th>Handling Time</th>
                                            <th>Description</th>
                                            <th>Weight</th>
                                            <th>Unit Price</th>
                                            <th>Order Qty</th>
                                            <th>Cancelled Qty</th>
                                            <th>Buy Qty</th>
                                            <th>Price</th>
                                            {/*<th></th>*/}
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {singleOrderDetails.order_dtls &&
                                                singleOrderDetails.order_dtls.map((item, index) => {
                                                    let buy_qty_id = 'buy_qty'+item.id;
                                                    return(
                                                        <tr key={index}>
                                                            <td>{item.part_number}</td>
                                                            <td>{item.make}</td>
                                                            <td>{item.supplier_dtls.supplier_code_name}</td>
                                                            <td>{item.supplier_dtls.handling_time}</td>
                                                            <td>{renderHTML(item.description)}</td>
                                                            <td>{item.weight.toFixed(3)}</td>
                                                            <td>{item.final_unit_price.toFixed(2)}</td>
                                                            <td>{item.order_qty}</td>
                                                            <td>{displayCancelledQty(index, item)}</td>
                                                            {/*<td><input type="number" className="number-product" disabled={item.is_active==0 || item.is_returned==1 ? true : false} min={1} max={item.available_qty} id={buy_qty_id} name={buy_qty_id} onChange={(e) => handleChange(e, item)} value={orderDetail.length > 0 && orderDetail[index] ? orderDetail[index].available_qty: item.available_qty}/><br/>Availability: {item.available_qty}</td>*/}
                                                            <td><input type="number" className="number-product" disabled={true} min={1} max={item.available_qty} id={buy_qty_id} name={buy_qty_id} onChange={(e) => handleChange(e, item)} value={orderDetail.length > 0 && orderDetail[index] ? orderDetail[index].available_qty: item.available_qty}/></td>
                                                            <td><span>{currencyFormatter.format(displayItemPrice(index, item))}</span></td>
                                                            {/*<td>
                                                                {item.is_active==0 && item.is_returned==0 &&
                                                                    <span>CANCELLED</span>
                                                                }
                                                                {item.is_active==1 && item.is_returned==1 &&
                                                                    <span>RETURNED</span>
                                                                }
                                                                {item.is_active==1 && item.is_returned==0 &&
                                                                    <><button className="btn btn-danger" type="button" onClick={(e) => returnOrderItem(singleOrderDetails.id,item.id, item.available_qty, e)}><i className="fa fa-cart-arrow-down"></i></button>
                                                                    &nbsp;&nbsp;<button className="btn btn-danger" type="button" onClick={(e) => cancelOrderItem(singleOrderDetails.id,item.id, item.available_qty, e)}><i className="fa fa-times"></i></button></>
                                                                }
                                                                {singleOrderDetails.status=='PENDING' && item.is_active==1 && item.is_returned==0 &&
                                                                    <button className="btn btn-danger" type="button" onClick={(e) => cancelOrderItem(singleOrderDetails.id,item.id, item.available_qty, e)}><i className="fa fa-times"></i></button>
                                                                }
                                                            </td>*/}
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {/*singleOrderDetails.status!=='PROCESSED' && singleOrderDetails.status!=='COMPLETED' && singleOrderDetails.status!=='CANCELLED' && displayTotalAmount(singleOrderDetails) > 0 &&
                                                <tr>
                                                    <td colSpan="12" align="right"><button className="btn btn-theme" type="button" onClick={(e) => updateOrderItem(singleOrderDetails.id, orderDetail, e)}>Update Order</button></td>
                                                </tr>
                                            */}
                                        </tbody>
                                    </table>
                                }
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default OrderListCard