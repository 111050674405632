import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import {customer_base_url} from '../utils/axios';
import * as constants from '../constants/constants';
import { showNotification } from "../utils/utils";
import { translationStrings} from '../utils/locales';

const ResetPassword = (params) => {

    const onChange = e => {
        e.target.name = e.target.value;
    }

    const goBack = e => window.location.href="/";

    const onSubmit = (e) => {
        e.preventDefault();

        
        if(document.getElementById('password').value!=document.getElementById('confirm_password').value){
            alert('Password and Confirm Password does not match');
            return;
        } else {
            const headers = {
                'x-auth-token': localStorage.getItem('accessToken')
            };
            axios.request({
                method: 'post',
                headers: headers,
                url: customer_base_url+constants.URLS.RESET_PASSWORD,
                data: {
                    'id': params.match.params.id,
                    'forgotPwdToken': params.match.params.token,
                    'newPwd': document.getElementById('password').value,
                    'confirmNewPwd': document.getElementById('confirm_password').value
                }
                }).then(response =>{
                    showNotification('success', 'Success', translationStrings.reset_password_success_change);
                    setTimeout(function(){
                        window.location.href='/';
                    }, 1000);
                }, err => {
                    showNotification('danger', 'Error', err.response.data.message);
                });
        }
    }

    useEffect(() => {
    }, []) 

    return (
        <React.Fragment>
            <section class="profileBox backBox">
                <div class="profileWrapper container">
                    <div class="form">
                        <h5 class="title my-0 font-weight-normal">Reset Password</h5>
                        <form class="profileForm flex flex-wrap col-sm-12" onSubmit={e => onSubmit(e)}>
                            <div class="col-sm-6 flex flex-column field-box my-2">
                                <label> Password</label>
                                <input type="password"
                                    placeholder="Password"
                                    id="password"
                                    name="password"
                                    required="true"
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <div class="col-sm-6 flex flex-column field-box my-2">
                                <label> Confirm Password</label>
                                <input type="password"
                                    placeholder="Confirm Password"
                                    id="confirm_password"
                                    name="confirm_password"
                                    required="true"
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <div class="col-sm-12 field-box my-3 text-center">
                                <button className="btn btn-sm btn-primary">Send</button>&nbsp;&nbsp;
                                <button className="btn btn-sm btn-danger" onClick={(e) => goBack(e)}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    requestUIHandler: state.requestUIHandler,
    data: state.data
});

export default connect(mapStateToProps, null)(ResetPassword)
