import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { signupWatcher, checkEmailWatcher } from '../redux/actionCreators/authActions'
import { translationStrings } from '../utils/locales';
import * as constants from '../constants/constants';

const SignUp = (props) => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: ''
    });

    const { first_name, last_name, email, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = (e) => {
        e.preventDefault()
        new Promise((resolve, reject) => {
            props.signupWatcher(formData, resolve, reject)
        }).catch(e => { })
    }
    if (props.isAuthenticated) {
        return <Redirect to="/" />
    }

    const validateEmail=(e)=>{
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!e.target.value){
        }
        if(e.target.name=='email' && e.target.value && re.test(String(e.target.value).toLowerCase())){
            var response = new Promise((resolve, reject) => {
               props.checkEmailWatcher(e.target.value,resolve,reject);
            }).catch(e => {
              //this.setState({isLoaded: false});
              //this.setState({defaultLoadingMessage: translationStrings.common_no_data_found_msg});
            });
            response.then(() => {
              if (props.signUp.error && props.signUp.error.status==constants.EMAIL_ALREADY_EXISTS) {
                /*this.setState(prevState => {
                  let fields = Object.assign({}, prevState.fields);  // creating copy of state variable fields
                  fields.email = '';        // update the name property, assign a new value                 
                  return { fields };        // return new object fields object
                });*/
              }
            });
        }

    }

    return (
        <React.Fragment>
            <section className="loginBox backBox">
                <div className="login-page">
                    <div className="form">
                        <h1 className="auth__title">Sign Up</h1>
                        <p className="auth__lead">Create your Account</p>
                        <form className="register-form toggleForm" style={{display: 'block'}} onSubmit={e => onSubmit(e)}>
                            <input type="name"
                                name="first_name"
                                placeholder="First Name"
                                value={first_name}
                                required
                                onChange={e => onChange(e)}
                            />
                            <input type="text"
                                name="last_name"
                                placeholder="Last Name"
                                value={last_name}
                                required
                                onChange={e => onChange(e)}
                            />
                            <input type="text"
                                name="email"
                                placeholder="Email"
                                value={email}
                                required
                                onChange={e => onChange(e)}
                                onBlur={e => validateEmail(e)}
                            />
                            <input type="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                required
                                onChange={e => onChange(e)}
                                minLength='6'
                            />
                            <button>create</button>
                            <p className="message">Already registered? <a href="/login">Sign In</a></p>
                        </form>
                    </div>
                </div>
            </section>

            {/* <form className="auth__form" onSubmit={e => onSubmit(e)}>
                <div className="auth__form__group">
                    <input className="auth__form__input"
                        type="text" placeholder="Name" name="name"
                        value={name} onChange={e => onChange(e)} />
                </div>
                <div className="auth__form__group">
                    <input className="auth__form__input"
                        type="email" placeholder="Email" name="email"
                        value={email} onChange={e => onChange(e)} />
                </div>
                <div className="auth__form__group">
                    <input className="auth__form__input"
                        type="password" placeholder="Password" name="password"
                        value={password} onChange={e => onChange(e)} minLength="6" />
                </div>
                <div className="auth__form__group">
                    <input className="auth__form__input"
                        type="password" placeholder="Confirm Password" name="password2"
                        value={password2} onChange={e => onChange(e)} minLength="6" />
                </div>
                <button className="auth__form__button">Register</button>
            </form> */}
            {/* <p className="auth__authtext">
                Already have an account <Link className="auth__authtext__link" to="/login">Sing In</Link>
            </p> */}
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    requestUIHandler: state.requestUIHandler,
    signUp: state.auth
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        signupWatcher, checkEmailWatcher
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
