import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import requestUIHandler from './requestUIHandler';
import auth from './auth';
import adminUsers from './adminUsers';
import cart from './cart';


const rootReducer = (history) => combineReducers({
	router: connectRouter(history),
	auth, requestUIHandler, adminUsers, cart
});

export default rootReducer;