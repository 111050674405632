import React, { useState, useEffect } from 'react'
import OrderListCard from '../components/OrderListCard';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios'
import { base_url, customer_base_url } from '../utils/axios';
import { showNotification } from "../utils/utils";
import { translationStrings} from '../utils/locales';

const OrderList = () => {

   const [offset, setOffset] = React.useState(0);
   const [page, setPage] = React.useState(0);
   const [sortOrder, setSortOrder] = React.useState(0);
   const [sortField, setSortField] = React.useState(null);
   const [globalFilter, setGlobalFilter] = React.useState("");
   const [filters, setFilters] = React.useState({});
   const [rows, setRows] = React.useState(10);
   const [totalPageCount, setTotalPageCount] = React.useState(0);
   const [previousDisable, setPreviousDisable] = React.useState(true);
   const [nextDisable, setNextDisable] = React.useState(true);
   const [fetchedOrderDetails, setFetchedOrderDetails] = React.useState([]);
   const [orderDetails, setOrderDetails] = React.useState(null);
   const [check, setCheck] = React.useState(false);
   //let searchArr = {};

   const fetchOrders = () => {
      const headers = {
         'x-auth-token': localStorage.getItem('accessToken')
      };

      // Set Defined Values
      setRows(10);
      setSortOrder(-1);

      const FetchOrderRequest = {
         event: {
            first: offset,
            rows: rows,
            page: page,
            pageCount: 1,
            sortOrder: sortOrder,
            sortField: sortField,
            filters: filters,
            globalFilter: globalFilter,
            customFilters: ""
         }
      }

      axios.post(customer_base_url + 'order/list', FetchOrderRequest, { headers })
         .then(response => {
            setFetchedOrderDetails(response.data.data);
            setTotalPageCount(Math.ceil(response.data.recordsTotal / rows))
         });
   }

   const paginate = (pageId) => {
      var arr = document.getElementsByClassName('page-item');
      for (var i = 0; i < arr.length; i++) {
         arr[i].classList.remove('active');
      }

      document.getElementById('pagination_button_' + pageId).classList.add('active');
      if (pageId < 1) {
         setPreviousDisable(true);
         setOffset(0);
      } else {
         setPreviousDisable(false);
         setRows(10);
         setOffset(parseInt(pageId * rows));
      }

      if (pageId + 1 == totalPageCount) {
         setNextDisable(true);
      } else {
         setNextDisable(false);
      }

      setPage(pageId + 1);

      setCheck(true);
   }

   const previousClick = () => {
      let newPage;
      var arr = document.getElementsByClassName('page-item');
      for (var i = 0; i < arr.length; i++) {
         arr[i].classList.remove('active');
      }
      if (page == 2) {
         setPreviousDisable(true);
         setOffset(0);
         newPage = 1;
         let newPaginateElement = newPage - 1;
         document.getElementById('pagination_button_' + newPaginateElement).classList.add('active');
      } else {
         newPage = page - 1;
         let newPaginateElement = newPage - 1;
         document.getElementById('pagination_button_' + newPaginateElement).classList.add('active');
         setOffset(parseInt((newPaginateElement) * 10));
      }

      setPage(newPage);

      
      if ((page - 1) == totalPageCount) {
         setNextDisable(true);
      } else {
         setNextDisable(false);
      }

      setRows(10);
      setCheck(true);
   }

   const nextClick = () => {
      let newPage;
      var arr = document.getElementsByClassName('page-item');
      for (var i = 0; i < arr.length; i++) {
         arr[i].classList.remove('active');
      }
      if (page == 0) {
         if (page + 1 !== totalPageCount) {
            newPage = 1;
            setPage(newPage);
            document.getElementById('pagination_button_' + newPage).classList.add('active');
            setOffset(0);
         }
      } else {
         newPage = page + 1;
         let newPaginateElement = newPage - 1;
         document.getElementById('pagination_button_' + newPaginateElement).classList.add('active');
         setOffset(parseInt((newPaginateElement) * 10));
         setPage(newPage);
         setPreviousDisable(false);
      }

      if ((page + 1) == totalPageCount) {
         setNextDisable(true);
      } else {
         setNextDisable(false);
      }

      setRows(10);
      setCheck(true);
   }

   const getOrderDetails = (orderId, e) => {
      const headers = {
         'x-auth-token': localStorage.getItem('accessToken')
      };
      axios.get(customer_base_url + 'order/' +orderId, { headers })
         .then(response => {
            setOrderDetails(response.data.data);
         });
   }

   const creditNote = (orderId, e) => {
      window.location.href='credit-note/'+orderId
   }

   const returnOrder = (orderId, e) => {
      confirmAlert({
         title: 'Confirmation',
         message: 'Are you sure want to return this order?',
         buttons: [
           {
             label: 'Yes',
             onClick: () => {
                const headers = {
                     'x-auth-token': localStorage.getItem('accessToken')
                  };
                  axios.get(customer_base_url + 'order/return/' +orderId, { headers })
                     .then(response => {
                        showNotification('success', 'Success', translationStrings.order_cancel_msg);
                        fetchOrders();
                     });
             }
           },
           {
             label: 'No',
             //onClick: () => alert('Click No')
           }
         ]
      });
   }

   const cancelOrder = (orderId, e) => {
      confirmAlert({
         title: 'Confirmation',
         message: 'Are you sure want to cancel this order?',
         buttons: [
           {
             label: 'Yes',
             onClick: () => {
                const headers = {
                     'x-auth-token': localStorage.getItem('accessToken')
                  };
                  axios.get(customer_base_url + 'order/cancel/' +orderId, { headers })
                     .then(response => {
                        showNotification('success', 'Success', translationStrings.order_cancel_msg);
                        fetchOrders();
                     });
             }
           },
           {
             label: 'No',
             //onClick: () => alert('Click No')
           }
         ]
      });
   }

   const updateOrderItem = (orderId, orderDetail, e) => {
      const headers = {
         'x-auth-token': localStorage.getItem('accessToken')
      };
      axios.post(customer_base_url + 'order/update-item/' +orderId, {'orderDetail':orderDetail}, { headers })
      .then(response => {
         showNotification('success', 'Success', translationStrings.order_update_msg);
         setTimeout(function(){
             window.location.reload();
         }, 1000);
      });
   }

   const returnOrderItem = (orderId, itemId, e) => {
      confirmAlert({
         title: 'Confirmation',
         message: 'Are you sure want to return this item?',
         buttons: [
           {
             label: 'Yes',
             onClick: () => {
                const headers = {
                     'x-auth-token': localStorage.getItem('accessToken')
                  };
                  axios.get(customer_base_url + 'order/return-item/' +orderId+'/'+itemId, { headers })
                     .then(response => {
                        showNotification('success', 'Success', translationStrings.item_return_msg);
                        setTimeout(function(){
                            window.location.reload();
                        }, 1000);
                     });
             }
           },
           {
             label: 'No',
             //onClick: () => alert('Click No')
           }
         ],
      });
   }

   const cancelOrderItem = (orderId, itemId, e) => {
      confirmAlert({
         title: 'Confirmation',
         message: 'Are you sure want to cancel this item?',
         buttons: [
           {
             label: 'Yes',
             onClick: () => {
                const headers = {
                     'x-auth-token': localStorage.getItem('accessToken')
                  };
                  axios.get(customer_base_url + 'order/cancel-item/' +orderId+'/'+itemId, { headers })
                     .then(response => {
                        showNotification('success', 'Success', translationStrings.item_remove_msg);
                        setTimeout(function(){
                            window.location.reload();
                        }, 1000);
                     });
             }
           },
           {
             label: 'No',
             //onClick: () => alert('Click No')
           }
         ],
      });
   }

   const onSort = (e) => {
      if(e.target.value === '') {
         setSortField(null);
      } else if(e.target.value==1){
         setSortField('id');
         setSortOrder(-1);
      } else if(e.target.value==2){
         setSortField('id');
         setSortOrder(1);
      }
      setCheck(true);   
   }

   const onSearch = (e) => {
      if(e.target.value === '') {
         setGlobalFilter("");
      } else {
         setGlobalFilter(e.target.value);;
      }
      setCheck(true);
   }

   const onDateSearch = (e) => {
      let name = e.target.name;
      let val = e.target.value;
      let searchArr = filters;
      if(e.target.value === '') {
         setGlobalFilter("");
         if(name=='from_date'){
            searchArr['from_date'] = "";
         } else if(name=='to_date'){
            searchArr['to_date'] = "";
         }
         setFilters(searchArr);
      } else {
         if(name=='from_date'){
            searchArr['from_date'] = val;
         } else if(name=='to_date'){
            searchArr['to_date'] = val;
         }
         setFilters(searchArr);
         //setGlobalFilter(e.target.value);
      }
      setCheck(true);
   }

   useEffect(() => {
        if (page == totalPageCount) {
            setNextDisable(true);
        } else {
           setNextDisable(false);
        }
    },[totalPageCount]);

   useEffect(() => {
      fetchOrders();
   }, [])

   useEffect(() => {
      if(check==true){
         fetchOrders();
      }
      setCheck(false);
   }, [check])

   return (
      <section className="profileBox backBox orderDetailsBox">
         <div className="profileWrapper container">
            <div className="form">
               <h5 className="title my-0 font-weight-normal">Orders</h5>
               <div className="filterWrapper">
                  <div className="filter">
                     <div className="row" style={{marginBottom:'10px'}}>
                        <div className="col-sm-6">
                           <input type="text" id="searchText" name="searchText" onChange={(e) => onSearch(e)} className="form-control" placeholder="Search by part number, make, order number"/>
                        </div>
                        <div className="col-sm-3">
                           <input type="date" id="searchFromDate" name="from_date" onChange={(e) => onDateSearch(e)} className="form-control"/>
                        </div>
                        <div className="col-sm-3">
                           <input type="date" id="searchToDate" name="to_date" onChange={(e) => onDateSearch(e)} className="form-control"/>
                        </div>
                     </div>
                     <span className="filterTitle">Sort By: </span>
                     <select onChange={e => onSort(e)} defaultValue={''}>
                        <option value={1}>Newest First</option>
                        <option value={2}>Oldest First</option>
                     </select>
                  </div>
               </div>
               <div className="cartWrapper orderWrapper">
                  <div className="container-fluid">
                     <OrderListCard
                        // imgPath = "assets/images/product1.jpg"
                        orderAll={fetchedOrderDetails}
                        getOrderDetails={getOrderDetails}
                        updateOrderItem={updateOrderItem}
                        returnOrder={returnOrder}
                        cancelOrder={cancelOrder}
                        returnOrderItem={returnOrderItem}
                        cancelOrderItem={cancelOrderItem}
                        singleOrderDetails={orderDetails}
                        creditNote={creditNote}
                     />
                  </div>
               </div>
               <div className="paginationBox mb-4">
                  <div className="text-right">
                     <nav aria-label="...">
                        <ul className="pagination">
                           {previousDisable ?
                                 <li className="page-item disabled">
                                     <span className="page-link">Previous</span>
                                 </li> :
                                 <li className="page-item">
                                     <span className="page-link" style={{ cursor: 'pointer' }} onClick={() => previousClick()}>Previous</span>
                                 </li>
                             }

                             {
                                 Array.apply(null, { length: totalPageCount }).map((e, index) => {
                                     let buttonId = "pagination_button_" + index;
                                     return (
                                         index==0 ?
                                         <li className="page-item active" id={buttonId} key={index}><button className="page-link" style={{ cursor: 'pointer' }} onClick={() => paginate(index)}>{index + 1}</button></li>
                                         : <li className="page-item " id={buttonId} key={index}><button className="page-link" style={{ cursor: 'pointer' }} onClick={() => paginate(index)}>{index + 1}</button></li>
                                     )
                                 })
                             }

                             {nextDisable ?
                                 <li className="page-item disabled">
                                     <button className="page-link" style={{ cursor: 'pointer' }}>Next</button>
                                 </li> :
                                 <li className="page-item">
                                     <button className="page-link" style={{ cursor: 'pointer' }} onClick={() => nextClick()}>Next</button>
                                 </li>
                             }
                        </ul>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}

export default OrderList;