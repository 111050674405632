import * as actions from '../types';

// Worker triggering actionCreators
export function cartWatcher(payload, resolve,reject) {
  return { type: actions.MANAGE_CART_WATCHER, payload: payload, resolve, reject};
}

export function fillCart(payload) {
  return { type: actions.FILL_CART, payload: payload };
}

export function setError(error) {
  return { type: actions.SET_CART_ERRORS, payload: error };
}
export function clearError() {
  return { type: actions.CLEAR_CART_ERRORS, payload: null };
}