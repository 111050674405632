import React, { useEffect, useState } from 'react'
import { updateProfileWatcher, getUserProfileWatcher } from '../redux/actionCreators/authActions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import {customer_base_url} from '../utils/axios';

const Profile = ({ updateProfileWatcher, isAuthenticated }) => {

    const [formData, setFormData] = useState({
        customerNumber: '',
        first_name: '',
        last_name: '',
        company_name: '',
        company_tax_registration_number: '',
        email: '',
        office_phone_number: '',
        mobile_number: '',
        address: ''
    });

    const { customerNumber, first_name, last_name, company_name, company_tax_registration_number,
        email, office_phone_number, mobile_number, address } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const goBack = e => window.location.href="/";

    const onSubmit = (e) => {
        e.preventDefault()
        new Promise((resolve, reject) => {
            updateProfileWatcher(localStorage.getItem('id'), formData, resolve, reject)
        }).catch(e => { })
    }

    useEffect(() => {
        const headers = {
            'x-auth-token': localStorage.getItem('accessToken')
        };

        axios.get(customer_base_url+'profile', {headers})
        .then(response =>{
            if(response.data.user.customer_dtls){
                setFormData({
                    first_name: response.data.user.customer_dtls.first_name,
                    last_name: response.data.user.customer_dtls.last_name,
                    company_name: response.data.user.customer_dtls.company_name,
                    company_tax_registration_number: response.data.user.customer_dtls.company_tax_registration_number,
                    email: response.data.user.email,
                    office_phone_number: response.data.user.customer_dtls.office_phone_number,
                    mobile_number: response.data.user.customer_dtls.mobile_number,
                    address: response.data.user.customer_dtls.address
                })
            } else if(response.data.user.email) {
                setFormData({
                    email: response.data.user.email
                })
            }
        });
    }, []) 

    return (
        <React.Fragment>
            <section class="profileBox backBox">
                <div class="profileWrapper container">
                    <div class="form">
                        <h5 class="title my-0 font-weight-normal">Profile Details</h5>
                        <form class="profileForm flex flex-wrap col-sm-12" onSubmit={e => onSubmit(e)}>
                            <div class="col-sm-6 flex flex-column field-box my-2">
                                <label> First name</label>
                                <input type="text"
                                    placeholder="First name"
                                    name="first_name"
                                    required="true"
                                    value={first_name}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <div class="col-sm-6 flex flex-column field-box my-2">
                                <label>Last name</label>
                                <input type="text"
                                    placeholder="Last name"
                                    name="last_name"
                                    required="true"
                                    value={last_name}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <div class="col-sm-6 flex flex-column field-box my-2">
                                <label> Company name</label>
                                <input type="text"
                                    placeholder="Company name"
                                    name="company_name"
                                    value={company_name}
                                    disabled={true}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <div class="col-sm-6 flex flex-column field-box my-2">
                                <label>Company Tax Registration Number</label>
                                <input type="text"
                                    placeholder="Company tax registration number"
                                    name="company_tax_registration_number"
                                    value={company_tax_registration_number}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <div class="col-sm-6 flex flex-column field-box my-2">
                                <label>Email address</label>
                                <input type="email"
                                    placeholder="Email address"
                                    name="email"
                                    value={email}
                                    disabled={true}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <div class="col-sm-6 flex flex-column field-box my-2">
                                <label>Office phone number</label>
                                <input type="tel"
                                    placeholder="Office phone number"
                                    name="office_phone_number"
                                    value={office_phone_number}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <div class="col-sm-6 flex flex-column field-box my-2">
                                <label>Mobile number</label>
                                <input type="text"
                                    placeholder="Mobile number"
                                    name="mobile_number"
                                    required="true"
                                    value={mobile_number}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <div class="col-sm-6 flex flex-column field-box my-2">
                                <label>Address</label>
                                <input type="text"
                                    placeholder="Address"
                                    name="address"
                                    value={address}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <div class="col-sm-12 field-box my-3 text-right">
                                <button className="btn btn-sm btn-primary">Save</button>&nbsp;&nbsp;
                                <button className="btn btn-sm btn-danger" onClick={(e) => goBack(e)}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    requestUIHandler: state.requestUIHandler,
    data: state.data
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        updateProfileWatcher,
        getUserProfileWatcher
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
