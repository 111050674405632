import React from 'react'

const Contanct = () => {
    return (
        <React.Fragment>
            <section className="section-contact-breadcrum">
                <div className="breadcrum-bg">
                    <div className="container py-5">
                        <p><a href="index.html">Home</a> &nbsp; / &nbsp; Contact</p>
                        <h2 className="my-3">Contact Us</h2>
                    </div>
                </div>
            </section>
            <section className="section-contacts-2" id="contact">
                <div className="contacts-main">
                    <div className="contant11-top-bg py-5">
                        <div className="container py-md-3">
                            <div className="d-grid contact section-gap">
                                <div className="contact-info-left d-grid text-center">
                                    <div className="contact-info">
                                        <span className="fa fa-map-marker" aria-hidden="true"></span>
                                        <h4>Address</h4>
                                        <p>485 Pretty View Lane, Lorem ipsum, UAE</p>
                                    </div>
                                    <div className="contact-info">
                                        <span className="fa fa-mobile" aria-hidden="true"></span>
                                        <h4>Phone</h4>
                                        <p><a href="tel:+44 7834 857829">+44 7834 857829</a></p>
                                        <p><a href="tel:+44 987 654 321">+44 987 654 321</a></p>
                                    </div>
                                    <div className="contact-info">
                                        <span className="fa fa-envelope-o" aria-hidden="true"></span>
                                        <h4>Mail</h4>
                                        <p><a href="mailto:mail@example.com" className="email">mail@example.com</a></p>
                                        <p><a href="mailto:mail@example.com" className="email">mail@example.com</a></p>
                                    </div>
                                    <div className="contact-info">
                                        <span className="fa fa-print" aria-hidden="true"></span>
                                        <h4>Fax</h4>
                                        <p><a href="tel:+44 7834 857829">+44 7834 857829</a></p>
                                        <p><a href="tel:+44 987 654 321">+44 987 654 321</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-41-mian pb-5">
                        <div className="container pb-md-3">
                            <div className="d-grid align-form-map">
                                <div className="form-inner-cont">
                                    <h3 className="cont-head">Send Message Us</h3>
                                    <form className="main-input">
                                        <div className="top-inputs d-grid">
                                            <input type="text" placeholder="Name" name="sectionName" id="sectionName" required="" />
                                            <input type="email" name="email" placeholder="Email" id="sectionSender" required="" />
                                        </div>
                                        <input type="text" placeholder="Phone Number" name="sectionName" id="sectionName" required="" />
                                        <textarea placeholder="Message" name="sectionMessage" id="sectionMessage" required=""></textarea>
                                        <div className="text-right">
                                            <button type="submit" className="btn btn-theme1">Send Now</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="contact-right">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462562.65102636546!2d54.94754444558808!3d25.075759435668097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43348a67e24b%3A0xff45e502e1ceb7e2!2sBurj%20Khalifa!5e0!3m2!1sen!2sin!4v1625549921626!5m2!1sen!2sin" style={{border:"0"}} allowFullScreen="" loading="lazy"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Contanct
