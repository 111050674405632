import * as actions from '../types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  user: null,
  error: null,
};

const auth = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case actions.LOGIN_SUCCESS:
      localStorage.setItem('token', payload.access);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        token: payload.access
      }
    case actions.SIGNUP_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        loading: true,
      }
    case actions.SIGNUP_FAIL:
    case actions.LOGIN_FAIL:
    case actions.LOGOUT:
      localStorage.removeItem('token')
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
      }
    case actions.FILL_USER_DATA:
      return {
        ...state,
        user: { ...payload }
      };
    case actions.FILL_USER_PROFILE:
      return {
        ...state,
        user: { ...payload }
      };

    case actions.CLEAR_SESSION:
      return { ...initialState };

    case actions.AUTH_ERROR:
      return {
        ...state,
        error: payload
      };
    case actions.CHANGE_USER_PROFILE:
      return {
        ...state,
        user: { ...payload }
      };
    default:
      return state;
  }
};

export default auth;