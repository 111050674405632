import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import axios, { customer_base_url } from '../../utils/axios';
//import * as alert from '../../utils/alert';
import * as actions from '../types';
import * as constants from '../../constants/constants';
import { loadUI, setUIError, clearUIError } from '../actionCreators/UIActions';
import { 
  authError, 
  fillUserProfile, 
  clearSession,
  changeUserProfile,
  fillUserData 
} from '../actionCreators/authActions';

import { fillCart, setError, clearError } from '../actionCreators/cartActions';

// React Notification
import { showNotification } from "../../utils/utils";
import { translationStrings} from '../../utils/locales';

/**
 * Cart Operation using saga
 */

// Saga function that handles the side effect when the cartActionWatcher is triggered
export function* cartActionEffect(cartAction) {
let { payload, resolve, reject } = cartAction;
try {
  yield put(loadUI());
  /*if(data.data) {
    Object.keys(data.data).forEach(key => {
      localStorage.setItem(key, data.data[key]);
    });
  }*/

  localStorage.setItem('cartItems', JSON.stringify(payload));

  yield put(fillCart(payload));
  yield put(clearUIError());
  if (resolve) resolve();
} catch (e) {
  yield put(setUIError(e));
  yield put(authError(e));
  if (reject) reject(e);
}
}
// Saga function that is initiated in the beginning to be able to listen to CART_WATCHER action
export function* cartActionWatcher() {
yield takeLatest(actions.MANAGE_CART_WATCHER, cartActionEffect);
}