import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { base_url, customer_base_url, download_file_url } from '../utils/axios';
import * as utils from "../utils/utils";

const SingleBlog = (newsId) => {
    const [fetchedNews, setFetchedNews] = React.useState([]);
    const fetchNews = () => {
        const headers = {
            'x-auth-token': localStorage.getItem('accessToken')
        };

        axios.get(customer_base_url + 'news/'+newsId.match.params.id)
            .then(response => {
                setFetchedNews(response.data.data);
            });
    }

    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    useEffect(() => {
        fetchNews();
    }, [])

    return (
        <React.Fragment>
            <section className="section-contact-breadcrum section-blog-breadcrum">
                <div className="breadcrum-bg">
                    <div className="container py-5">
                        <p><a href="index.html">Home</a> &nbsp; / &nbsp; Blog</p>
                        <h2 className="my-3">Latest News</h2>
                    </div>
                </div>
            </section>
            <section className="news-1 blogBox" id="blog">
                <div className="news py-5">
                    <div className="container py-md-3">
                        <div className="blog-grids row">
                            {fetchedNews &&
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="blog-grid">
                                        <figure><img src={fetchedNews.news_image ? fetchedNews.news_image : download_file_url+'/no-preview.jpg'} className="img-fluid" alt=""/></figure>
                                        <div className="blog-info content-area">
                                            <h3><a href="#">{fetchedNews.news_title}</a> </h3>
                                            <p>{renderHTML(fetchedNews.news_content)}</p>
                                            <p><span className="fa fa-calendar mr-2"></span>{fetchedNews.created_at ? utils.dateFormatter.format(new Date(fetchedNews.created_at)) : ''}</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default SingleBlog
