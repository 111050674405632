/* STATUS */
export const SUCCESS = 'SUCCESS';
/* DATATABLE */
export const ROWS_PER_PAGE = 10;
export const ROWS_PER_PAGE_OPTIONS = [10,25,50,100];
export const TAX_PERCENTAGE = 5;

export const EDIT_TYPE='edit';
export const TEXT_ALIGN_CSS='left';
export const EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS';
export const SETTING = 'setting';
export const PASSWORD = 'password';
export const TEXT = 'text';

export const ACTIVATE_STATUS = 'ACTIVATE';
export const DEACTIVATE_STATUS = 'DEACTIVATE';
export const DEVELOPMENT = 'development';
export const PRODUCTION = 'production';
/* ROLES */
export const ROLES = [
	{'id': 'MASTER_ADMIN', 'label': 'Master Admin'},
	{'id': 'ADMIN_USER', 'label': 'Admin User'}
];
/* USER TYPES */
export const MASTER_ADMIN = 'MASTER_ADMIN';
export const ADMIN_USER = 'ADMIN_USER';

export const ADMIN = 'Admin';
export const SUPPORT = 'Support';

/* USER ACTIONS */
export const ACTIVATE = 'ACTIVATE';
export const DEACTIVATE = 'DEACTIVATE';
export const DELETE = 'DELETE';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const EXPORT = 'EXPORT';

export const HOME_PAGE_URLS = {'MASTER_ADMIN': '/autoparts/adminUser/list/', 'ADMIN_USER': '/autoparts/adminUser/list/'};

export const URLS = {
	
	'AUTOPARTS_EDIT_PROFILE': '/autoParts/profile',
	'CHANGE_PASSWORD': 'change-password/',
	'LOGIN': 'login',
	'SIGNUP': '/signup',
	'FORGOT_PASSWORD': 'forgotPwd',
	'RESET_PASSWORD': 'resetPwd',
	'UNDERCONSTRUCTION':'/underConstruction',
	'HOME': '/',
	'REGISTER': 'register',
	
	'API_URL_FOR_PROFILE': 'profile',
	'API_URL_FOR_LOGOUT':'/logout',
	'API_URL_FOR_FORGOT_PASSWORD':'user/forgotPwd',
	'API_URL_FOR_CHECK_TOKEN_VALIDTION':'user/valResetPwdReqData/',
	'API_URL_FOR_RESET_PASSWORD':'user/resetPwd',
	'API_URL_FOR_CHANGE_PASSWORD':'user/changePwd',
	'API_URL_FOR_LOGIN_AS':'/user/loginAsUser/',
	'API_URL_FOR_AUTOPARTS_COMMON_URL':'autoParts/user/',
	'STATUS':'/status/',
	'USER_EMAIL':'/user/email/',
	'CHECK':'/check',
	'CHECK_USER':'/check/user/',
	'ADD':'add',
	'VIEW':'view',
	'USER':'user/',
	'SUPPLIER_LIST': '/autoparts/supplier/list',
	'GET_SUPPLIER_ADD': '/autoparts/supplier/add',
	'SUPPLIER_EDIT': '/autoparts/supplier/:id/edit',
	'CUSTOMER_LIST': '/autoparts/customer/list',
	'GET_CUSTOMER_ADD': '/autoparts/customer/add',
	'CUSTOMER_EDIT': '/autoparts/customer/:id/edit',
	'PRODUCT_LIST': '/autoparts/product/list',
	'GET_PRODUCT_ADD': '/autoparts/product/add',
	'PRODUCT_EDIT': '/autoparts/product/:id/edit',
	'ADMIN_USER_LIST': '/autoparts/adminUser/list',
	'GET_ADMIN_USER_ADD': '/autoparts/adminUser/add',
	'ADMIN_USER_ADD': '/admin-user',
	'ADMIN_USER_EDIT': '/autoparts/adminUser/:id/edit',
	'ADMIN_USER_EMAIL':'/email/',
	'API_URL_FOR_ADMIN_USER_LIST':'admin-user/list',
	'API_URL_FOR_ADMIN_RESET_PASSWORD_URL': '/change-password/'
};
