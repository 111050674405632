import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { base_url, customer_base_url, download_file_url } from '../utils/axios';
import * as utils from "../utils/utils";

const Blog = () => {
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [sortOrder, setSortOrder] = React.useState(0);
    const [sortField, setSortField] = React.useState(null);
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [rows, setRows] = React.useState(10);
    const [totalPageCount, setTotalPageCount] = React.useState(0);
    const [previousDisable, setPreviousDisable] = React.useState(true);
    const [nextDisable, setNextDisable] = React.useState(false);
    const [fetchedNews, setFetchedNews] = React.useState([]);
    const fetchNews = () => {
        const headers = {
            'x-auth-token': localStorage.getItem('accessToken')
        };

        // Set Defined Values
        setRows(10);
        //setSortOrder(-1);

        const FetchNewsRequest = {
            event: {
                first: offset,
                rows: rows,
                page: page,
                pageCount: 1,
                sortOrder: sortOrder,
                sortField: sortField,
                filters: {},
                globalFilter: globalFilter,
                customFilters: ""
            }
        }

        axios.post(customer_base_url + 'latest-news/list', FetchNewsRequest, { headers })
            .then(response => {
                setFetchedNews(response.data.data);
                setTotalPageCount(Math.ceil(response.data.recordsTotal / rows))
            });
    }

    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    useEffect(() => {
        fetchNews();
    }, [])

    return (
        <React.Fragment>
            <section className="section-contact-breadcrum section-blog-breadcrum">
                <div className="breadcrum-bg">
                    <div className="container py-5">
                        <p><a href="index.html">Home</a> &nbsp; / &nbsp; Blog</p>
                        <h2 className="my-3">Latest News</h2>
                    </div>
                </div>
            </section>
            <section className="news-1 blogBox" id="blog">
                <div className="news py-5">
                    <div className="container py-md-3">
                        <div className="blog-grids row">
                            {fetchedNews &&
                                fetchedNews.map(item => {
                                    let itemId = `/single-blog/${item.id}`;
                                    return (
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="blog-grid blog-box" id="zoomIn">
                                                <a href={itemId}>
                                                    <figure><img src={item.news_image ? item.news_image : download_file_url+'/no-preview.jpg'} className="img-fluid" alt="" /></figure>
                                                </a>
                                                <div className="blog-info">
                                                    <h3><a href="#">{item.news_title}</a> </h3>
                                                    <ul>
                                                        <li><a href="#author"><span className="fa fa-calendar mr-2"></span>{utils.dateFormatter.format(new Date(item.created_at))}</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Blog
