import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { base_url, customer_base_url, download_file_url } from '../utils/axios';
import Slider from "react-slick-slider";
import $ from 'jquery';
import * as utils from "../utils/utils";

const Home = () => {
   var featureSettings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      //prevArrow:"<a className='navigator leftMove'><span className='fa fa-chevron-left'></span></a>",
      //nextArrow:"<a className='navigator rightMove'><span className='fa fa-chevron-right'></span></a>",
      // changes:: start
      responsive: [
         {
           breakpoint: 800,
           settings: {
             slidesToShow: 2
           }
         },
         {
           breakpoint: 600,
           settings: {
             slidesToShow: 1
           }
         }
      ]
   };

   var settings = {
      centerMode: false,
      slidesToShow: 7,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 4
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2
            }
         }
      ]
   };

   const [productList, setProductList] = React.useState([]);
   const [fetchedNews, setFetchedNews] = React.useState([]);
   const [fetchFeatureList, setFetchFeatureList] = React.useState([]);
   const [fetchTestimonialList, setFetchTestimonialList] = React.useState([]);
   const [homePage, setHomePage] = React.useState([]);
   const [fetchedAboutUs, setFetchedAboutUs] = React.useState([]);
   const [offset, setOffset] = React.useState(0);
   const [page, setPage] = React.useState(1);
   const [sortOrder, setSortOrder] = React.useState(0);
   const [sortField, setSortField] = React.useState(null);
   const [globalFilter, setGlobalFilter] = React.useState("");
   const [totalPageCount, setTotalPageCount] = React.useState(0);
   const [rows, setRows] = React.useState(10);

   const fetchNews = () => {
        const headers = {
            'x-auth-token': localStorage.getItem('accessToken')
        };

        // Set Defined Values
        setRows(10);
        //setSortOrder(-1);

        const FetchNewsRequest = {
            event: {
                first: offset,
                rows: rows,
                page: page,
                pageCount: 1,
                sortOrder: sortOrder,
                sortField: sortField,
                filters: {},
                globalFilter: globalFilter,
                customFilters: ""
            }
        }

      axios.post(customer_base_url + 'latest-news/list', FetchNewsRequest, { headers })
            .then(response => {
                setFetchedNews(response.data.data);
                setTotalPageCount(Math.ceil(response.data.recordsTotal / rows))
            });
   }

   const fetchProductList = () => {
      const headers = {
         'x-auth-token': localStorage.getItem('accessToken')
      };

      axios.get(customer_base_url + 'most-ordered-products', { headers })
         .then(response => {
            setProductList(response.data.chartData);
      });
   }

   const fetchAboutUs = () => {
      const headers = {
         'x-auth-token': localStorage.getItem('accessToken')
      };

      axios.get(customer_base_url + 'about-us/1', { headers })
         .then(response => {
            setFetchedAboutUs(response.data.data);
      });
   }

   const fetchHome = () => {
      const headers = {
         'x-auth-token': localStorage.getItem('accessToken')
      };

      axios.get(customer_base_url + 'home/1', {headers}).then(response => {
         setHomePage(response.data.data);
      });
   }

   const fetchFeatures = () => {
      const headers = {
         'x-auth-token': localStorage.getItem('accessToken')
      };

      
        const FetchFeaturesRequest = {
            event: {
                first: offset,
                rows: rows,
                page: page,
                pageCount: 1,
                sortOrder: sortOrder,
                sortField: sortField,
                filters: {},
                globalFilter: globalFilter,
                customFilters: ""
            }
        }

      axios.post(customer_base_url + 'features/list', FetchFeaturesRequest, { headers })
            .then(response => {
                setFetchFeatureList(response.data.data);
                setTotalPageCount(Math.ceil(response.data.recordsTotal / rows))
            });
   }

   const fetchTestimonials = () => {
      const headers = {
         'x-auth-token': localStorage.getItem('accessToken')
      };

      
        const FetchTestimonialsRequest = {
            event: {
                first: offset,
                rows: rows,
                page: page,
                pageCount: 1,
                sortOrder: sortOrder,
                sortField: sortField,
                filters: {},
                globalFilter: globalFilter,
                customFilters: ""
            }
        }

      axios.post(customer_base_url + 'testimonials/list', FetchTestimonialsRequest, { headers })
            .then(response => {
                setFetchTestimonialList(response.data.data);
                setTotalPageCount(Math.ceil(response.data.recordsTotal / rows))
            });
   }

   const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: htmlDecode(rawHTML) } });

   const htmlDecode = (input) => {
      var e = document.createElement('div');
      e.innerHTML = input;
      if(input!==undefined){
         if(e.childNodes.length > 0){
            return e.childNodes[0].nodeValue ? e.childNodes[0].nodeValue : input;
         } else {
            return input;
         }
      } else {
         return "";
      }
      //return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
   }

   useEffect(() => {
      fetchNews();
      fetchAboutUs();
      fetchProductList();
      fetchHome();
      fetchFeatures();
      fetchTestimonials();
   }, [])

   return (
      <React.Fragment>
         <section className="section-main-slider" id="home">
            <div className="slider-info banner-view bg bg2" data-selector=".bg.bg2">
               <div className="banner-info">
                  <div className="container">
                     {homePage &&
                        <div className="banner-info-bg mr-auto">
                           <p>{renderHTML(homePage.header_title)}</p>
                           <p>{renderHTML(homePage.header_content)}</p>
                           <a className="btn btn-theme1 mt-md-5 mt-4" href="/product"> Our Products</a>
                        </div>
                     }
                  </div>
               </div>
            </div>
         </section>
         {/*Features Section Start*/}
         <section className="section-features-1">
            <div className="features py-5">
               <div className="container">
                  <div className="fea-gd-vv" id="featureBox">
                     <Slider {...featureSettings}>
                     {fetchFeatureList &&
                        fetchFeatureList.map((item, i) => {
                           return (
                              <div className="slideItem col-lg-3 col-sm-6" key={i}>
                                 <div className="float-lt feature-gd">
                                    <div className="icon"> <img src={item.feature_image ? item.feature_image : download_file_url+'/no-preview.jpg'} alt="Wholesale auto parts supply"/></div>
                                    <div className="icon-info">
                                       <h5>{item.feature_title}</h5>
                                       <p className="mt-2">{item.feature_text}</p>
                                    </div>
                                 </div>
                              </div>
                           );      
                        })
                     }
                     </Slider>
                  </div>
               </div>
            </div>
         </section>
         {/*Features Section End*/}

         {/*About Section Start*/}
         <div className="section-about-box py-5" id="about">
            <div className="container py-lg-3">
               <div className="aboutgrids row">
                  <div className="col-sm-12">
                     <h4 className="title">Welcome to Partsnext <span className="highlighted"></span></h4>
                  </div>
                  <div className="col-lg-6 aboutgrid1 mt-lg-0 mt-4">
                     {fetchedAboutUs &&
                        <p>{renderHTML(fetchedAboutUs.about_content)}</p>
                     }
                     <a className="btn btn-theme1" href="/about"> About Us</a>
                  </div>
                  <div className="col-lg-6 aboutgrid2">
                     <img src="assets/images/aboutUs.jpg" alt="about image" className="img-fluid" />
                  </div>
               </div>
            </div>
         </div>
         {/*About Section End*/}

         {/*Client Slider Section Start*/}
         <section className="clientBlock">
            <div className="container py-md-3">
               <div className="heading text-center mx-auto">
                  <h3 className="head">Our Brands</h3>
               </div>
               <p className="text-center">
                  You can find a vast range of original and aftermarket parts in our data base.
                  The goods are supplied worldwide from our warehouse in UAE and other countries on a daily basis. 
                  Our prices are free of taxes and considerably lower than in your country.
               </p>
               <div className="clientBlockWrapper mt-5 mb-5" id="clientBox">
                  <Slider {...settings}>
                    <div className="slideItem">
                        <img src="assets/images/client/client-1.png" alt=""/>
                     </div>
                     <div className="slideItem">
                        <img src="assets/images/client/client-2.png" alt=""/>
                     </div>
                     <div className="slideItem">
                        <img src="assets/images/client/client-3.png" alt=""/>
                     </div>
                     <div className="slideItem">
                        <img src="assets/images/client/client-4.png" alt=""/>
                     </div>
                     <div className="slideItem">
                        <img src="assets/images/client/client-5.png" alt=""/>
                     </div>
                     <div className="slideItem">
                        <img src="assets/images/client/client-6.png" alt=""/>
                     </div>
                     <div className="slideItem">
                        <img src="assets/images/client/client-7.png" alt=""/>
                     </div>
                     <div className="slideItem">
                        <img src="assets/images/client/client-8.png" alt=""/>
                     </div>
                     <div className="slideItem">
                        <img src="assets/images/client/client-9.png" alt=""/>
                     </div>
                  </Slider>
               </div>
            </div>
         </section>
         {/*Client Slider Section End*/}
         
         {/*Quote Section Start*/}
         <section className="quoteSection" id="">
            <div className="features-with-17_sur py-5">
               <div className="container py-md-5 theme-border">
                  <h4 className="lft-head text-center light-weight-font ">
                     {homePage &&
                        <>
                        <p>{renderHTML(homePage.highlight_text)}</p>
                        </>
                     }
                  </h4>
               </div>
            </div>
         </section>
         {/*Quote Section End*/}

         {/*Product Section Start*/}
         <section className="section-products">
            <div className="features py-5" id="features">
               <div className="container py-md-3">
                  <div className="heading text-center mx-auto">
                     <h3 className="head">Best Selling Products</h3>
                  </div>
                  <div className="fea-gd-vv text-center row mt-5 pt-3">
                     {productList &&
                        productList.map((item,i) => {
                           if(i<4){
                              return (
                                 <div className="col-lg-3 col-md-6" key={i}>
                                    <div className="float-top">
                                       <div className="float-lt feature-gd">
                                          <h3><a href={'/product?search='+item.part_number}>{item.make}</a> </h3>
                                          <p>{renderHTML(item.description)}</p>
                                          <p>Part Number: {item.part_number}</p>
                                       </div>
                                    </div>
                                 </div>
                              )
                           }
                        })
                     }
                     <div className="col-lg-12 col-md-12 text-right mt-3">
                        <a className="btn btn-theme1" href="/product">OUR PRODUCTS</a>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         {/*Product Section End*/}

         {/*Testimonial Section Start*/}
         <section className="section-customers-4" id="testimonials">
            <div id="customers4-block" className="py-5">
               <div className="container py-md-3">
                  <div id="customerhnyCarousel" className="carousel slide" data-ride="carousel">
                     <ol className="carousel-indicators">
                        {fetchTestimonialList &&
                            fetchTestimonialList.map((item,i) => {
                               if(i<1){
                                  return (
                                    <li key={i} data-target="#customerhnyCarousel" data-slide-to={i} className="active"></li>             
                                  );
                               } else {
                                  return (
                                     <li key={i} data-target="#customerhnyCarousel" data-slide-to={i}></li>
                                  );
                               }
                            })
                        }
                     </ol>
                     <div className="carousel-inner pb-5 mb-md-5 pt-md-5">
                        {fetchTestimonialList &&
                            fetchTestimonialList.map((item,i) => {
                               if(i<1){
                                  return (
                                     <div className="carousel-item active" key={i}>
                                       <div className="section-title">
                                          <div className="item-top">
                                             <div className="item text-center">
                                                <div className="imgTitle">
                                                   <img src={item.avatar ? item.avatar : download_file_url+'/no-preview.jpg'} className="img-responsive" alt="" width={200}/>
                                                </div>
                                                <h6 className="mt-3">{item.posted_by}</h6>
                                                <p className="">{item.designation}</p>
                                                <p><>{renderHTML(item.testimonial_text)}</></p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                  );
                               } else {
                                  return (
                                     <div className="carousel-item" key={i}>
                                       <div className="section-title">
                                          <div className="item-top">
                                             <div className="item text-center">
                                                <div className="imgTitle">
                                                   <img src={item.avatar ? item.avatar : download_file_url+'/no-preview.jpg'} className="img-responsive" alt="" width={200}/>
                                                </div>
                                                <h6 className="mt-3">{item.posted_by}</h6>
                                                <p className="">{item.designation}</p>
                                                <p><>{renderHTML(item.testimonial_text)}</></p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                  );
                               }
                            })
                        }
                        
                     </div>
                  </div>
               </div>
            </div>
         </section>
         {/*Testimonial Section End*/}


         {/*Blog Section Start*/}
         <section className="news-1" id="blog">
            <div className="news py-5">
               <div className="container py-md-3">
                  <div className="heading text-center mx-auto">
                     <h3 className="head">Latest News & Events</h3>
                  </div>
                  <div className="blog-grids row mt-5">
                     {fetchedNews &&
                        fetchedNews.map((item, i) => {
                           if(i<3){
                              let itemId = `/single-blog/${item.id}`;
                              return (
                                 <div className="col-lg-4 col-md-6 col-sm-12" key={i}>
                                    <div className="blog-grid blog-box" id="zoomIn">
                                       <a href={itemId}>
                                          <figure><img src={item.news_image ? item.news_image : download_file_url+'/no-preview.jpg'} className="img-fluid" alt="" /></figure>
                                       </a>
                                       <div className="blog-info">
                                          <h3><a href={itemId}>{item.news_title}</a> </h3>
                                          <ul>
                                             <li><a href={itemId}><span className="fa fa-calendar mr-2"></span>{utils.dateFormatter.format(new Date(item.created_at))}</a></li>
                                          </ul>
                                       </div>
                                    </div>
                                 </div>
                              )
                           }
                        })
                     }
                  </div>
               </div>
            </div>
         </section>
         {/*Blog Section End*/}
      </React.Fragment>
   )
}

export default Home
