import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useLocalStorageNonString } from '../utils/utils'
import { cartWatcher, fillCart } from '../redux/actionCreators/cartActions'

import { showNotification } from "../utils/utils";
import { translationStrings} from '../utils/locales';

import axios from 'axios'
import { base_url, customer_base_url } from '../utils/axios';
import * as constants from '../constants/constants';

import $ from 'jquery'

const ProductCard = (props) => {
    const [cart, setCart] = React.useState([]);
    const [productQty, setProductQty] = React.useState([]);

    const addToCart = (e, item) => {
        item.buy_qty = parseInt(document.getElementById("product_quantity_input" + item.id).value);
        if(item.buy_qty > 0 ){
            item.calc_price = calcPrice(item);
            item.sub_price = parseFloat(item.calc_price * item.buy_qty).toFixed(2);
            let calcTax = parseFloat(item.sub_price * (constants.TAX_PERCENTAGE/100));
            item.tax_amount = calcTax.toFixed(2);
            let calcFinalAmount = parseFloat(item.calc_price * item.buy_qty) + parseFloat(calcTax);
            item.final_amount = parseFloat(calcFinalAmount).toFixed(2);
            setCart((currentCart) => [...currentCart, item]);
            showNotification('success', 'Success', translationStrings.cart_add_msg);
        }
    }

    const removeFromCart = (productId) => {
        if(localStorage.getItem('cartItems')){
            let newCopy = JSON.parse(localStorage.getItem('cartItems'));
            newCopy = newCopy.filter((cartItem) => cartItem.id !== productId);
            new Promise((resolve, reject) => {
                props.cartWatcher(newCopy, resolve, reject)
            }).catch(e => { })
            showNotification('danger', 'Success', translationStrings.cart_remove_msg);
            /*setTimeout(function(){
                window.location.reload();
            }, 1000);*/
        }
    }

    const handleChange = (e, id) => {
        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if(value != ""){
            if(parseInt(value) < parseInt(target.min)){
                value = target.min;
            }
            if(parseInt(value) > parseInt(target.max)){
               value = target.max;
            }
        } else {
            value = 0;
        }

        let items = [...productQty];
        items[id] = value;
        setProductQty(items);
        let chkItemObjects = [...props.checkedItems];
        let currentIndex = props.checkedItems.findIndex(obj => obj.id==id);
        let qtyBoxId = 'product_quantity_input'+id;
        if(currentIndex>=0){
            chkItemObjects[currentIndex].buy_qty = document.getElementById(qtyBoxId).value;
            props.setCheckedItems(chkItemObjects);
        }
    }

    const incrementQuantity = (productId, maxQty) => {
      let quanityInputName = "product_quantity_input" + productId;
      let quanityInputValue = parseInt(document.getElementById(quanityInputName).value);
      let updatedValue = parseInt(quanityInputValue + 1);
      if(updatedValue <= maxQty){
         document.getElementById(quanityInputName).value = updatedValue;
      }
    }

    const decrementQuantity = (productId, maxQty) => {
      let quanityInputName = "product_quantity_input" + productId;
      let quanityInputValue = parseInt(document.getElementById(quanityInputName).value);
      let updatedValue = parseInt(quanityInputValue - 1);
      if(updatedValue >= 1){
          document.getElementById(quanityInputName).value = updatedValue;
      }
    }

    const calcPrice = (item) => {
        if(item.discount_percentage && item.discount_percentage!==null){
            let calcAmount = parseFloat(item.unit_price*((100+item.discount_percentage)/100));
            return calcAmount.toFixed(2);
        } else {
            return item.final_unit_price.toFixed(2);
        }
    }

    const getBuyQty = (itemId) => {
        let currentObj = props.cart.data.find(e => e.id === itemId);
        return currentObj.buy_qty;
    }

    const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

    useEffect(() => {
        if(localStorage.getItem('cartItems')) {
            setCart(JSON.parse(localStorage.getItem('cartItems')));
        }
    },[]);

    useEffect(() => {
        new Promise((resolve, reject) => {
            props.cartWatcher(cart, resolve, reject)
        }).catch(e => { })
    }, [cart])


    return (
        <React.Fragment>
            <table className="table table-bordered table-responsive-md">
                <thead className="thead-theme">
                    <tr>
                        <th width="2%"></th>
                        <th width="10%">Make</th>
                        <th width="10%">Warehouse</th>
                        <th width="12%">Part Number</th>
                        <th width="14%">Description</th>
                        <th width="12%">Handling Time</th>
                        {props.isExcelSearch &&
                            <th width="10%">Requested Qty</th>
                        }
                        <th width="5%">Weight</th>
                        <th width="10%">Availability</th>
                        <th width="10%">Price</th>
                        <th width="8%">Qty</th>
                        <th width="7%"></th>
                    </tr>
               </thead>
               <tbody>
                {props.productAll.length > 0 ?
                    props.productAll.map((productItem, index) => {
                        if(index<250){
                            let quantityInputId = "product_quantity_input" + productItem.id;
                            let priceInputId = "product_price" + productItem.id;
                            let checkboxId = "checkbox" + productItem.id;
                            let requestedQty = 0;
                            let displayQty = 0;
                            let availableQty = productItem.available_qty;
                            props.excelData.filter(function(val) {
                                if(val[0]){
                                    let replaceStr = val[0].toString().replace(/[^\w\s]/gi, '');
                                    if(replaceStr == productItem.search_part_number){
                                        requestedQty=val[2];
                                        if(requestedQty <= availableQty){
                                            availableQty = requestedQty;
                                        }
                                        displayQty = availableQty;
                                    }
                                }
                            });
                            return (
                                <tr key={index}>
                                    {availableQty > 0 && productItem.description && productItem.weight > 0 && productItem.unit_price > 0 ?
                                        <td><input type="checkbox" id={checkboxId} className="select_product" data-productid={productItem.id} onChange={(e) => props.handleCheck(e, productItem, productItem.id)} checked={props.chkChecked.includes(productItem.id) ? true: false}/></td> :
                                        <td>-</td>
                                    }
                                    <td>{productItem.brand}</td>
                                    <td>{productItem.supplier_code_name}</td>
                                    <td>{productItem.part_number}</td>
                                    <td>{renderHTML(productItem.description)}</td>
                                    <td>{productItem.handling_time}</td>
                                    {props.isExcelSearch &&
                                        <td>{requestedQty}</td>
                                    }
                                    <td>{productItem.weight.toFixed(3)}</td>
                                    <td>{availableQty}</td>
                                    <td>AED <span id={priceInputId}>{calcPrice(productItem)}</span></td>
                                    { availableQty > 0 ?
                                        props.cart.data.length > 0 && props.cart.data.filter(e => e.id === productItem.id).length > 0 ?
                                        <React.Fragment>
                                            <td>
                                                <div>
                                                    <input type="number" readOnly className="number-product" id={quantityInputId} min="1" name="qty" value={getBuyQty(productItem.id)} />
                                                </div>
                                            </td>
                                           <td><button className="btn btn-theme1-remove" onClick={() => removeFromCart(productItem.id)}><i className="fa fa-close"></i></button></td>
                                        </React.Fragment> :
                                        <React.Fragment>
                                            <td>
                                                {requestedQty > 0 ?
                                                    <div>
                                                        {/*<button className="button-count" onClick={() => decrementQuantity(productItem.id, productItem.available_qty)}>-</button>*/}
                                                            <input type="number" className="number-product" min={1} max={availableQty} onChange={(e) => handleChange(e, productItem.id)} id={quantityInputId} name="qty" value={productQty.length > 0 && productQty[productItem.id] ? productQty[productItem.id]: displayQty} />
                                                        {/*<button className="button-count" onClick={() => incrementQuantity(productItem.id, productItem.available_qty)}>+</button>*/}
                                                    </div>: <div>
                                                        {/*<button className="button-count" onClick={() => decrementQuantity(productItem.id, productItem.available_qty)}>-</button>*/}
                                                            <input type="number" className="number-product" min={1} max={availableQty} onChange={(e) => handleChange(e, productItem.id)} id={quantityInputId} name="qty" value={productQty.length > 0 && productQty[productItem.id] ? productQty[productItem.id]: 1} />
                                                        {/*<button className="button-count" onClick={() => incrementQuantity(productItem.id, productItem.available_qty)}>+</button>*/}
                                                    </div>
                                                }
                                            </td>
                                            {availableQty > 0 && productItem.description && productItem.weight > 0 && productItem.unit_price > 0 ?
                                                <td><button className="btn btn-theme1" type="submit" disabled={false} onClick={(e) => addToCart(e, productItem)}><i className="fa fa-shopping-cart"></i></button></td> :
                                                <td>Not Available</td>
                                            }
                                        </React.Fragment> :
                                        <React.Fragment>
                                            <td>Not Available</td>
                                            <td>Not Available</td>
                                        </React.Fragment>
                                    }
                                </tr>
                            )
                        }
                    }) : <tr><td colSpan="10"></td></tr>
                }
                </tbody>
            </table>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    requestUIHandler: state.requestUIHandler,
    cart: state.cart
})

const mapDispatchToProps = dispatch => bindActionCreators(
    { cartWatcher }, dispatch);

export default connect(null, mapDispatchToProps)(ProductCard)