import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { NavLink, Redirect } from 'react-router-dom'
import { logoutWatcher } from '../redux/actionCreators/authActions'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import { base_url } from '../utils/axios';
import $ from 'jquery';

const Navbar = ({ isAuthenticated }) => {
   const [cartTotal, setCartTotal] = React.useState(0);
   const [headerFilter, setHeaderFilter] = React.useState(null);
   const [multiHeaderFilter, setMultiHeaderFilter] = React.useState(null);
   const [searchArr, setSearchArr] = React.useState([{"search": ""}]);
   //var uniqueTags = [];

   let search = window.location.search;
   let params = new URLSearchParams(search);
   let searchParam = params.get('search');
   let isMultiSearch = params.get('multi');

   var base_path = window.location.origin;

   const logout = (e) => {
      e.preventDefault();
      localStorage.clear();
      window.location.href = '/login';
   }

   const formSubmit = (e) => {
      e.preventDefault();
      if($('#multiRowSearch').is(':hidden')){
         var splitArr = $('#multiSearchBox').val().split("\n");
         var arrStr = '';
         splitArr.map((x, i) => {
            if(i == splitArr.length-1){
               arrStr += x;
            } else {
               arrStr += x+',';
            }
         });
         window.location.href = '/product?search='+ arrStr + '&multi=true';
      }
      else if($('#multiSearchBox').is(':hidden')){
         window.location.href = '/product?search='+ $('#multiRowSearch').val();
      }
   }

   const formMultipleSubmit = (e) => {
      e.preventDefault();
      var splitArr = $('#multi-search').val().split("\n");
      var arrStr = '';
      splitArr.map((x, i) => {
         if(i == splitArr.length-1){
            arrStr += x;
         } else {
            arrStr += x+',';
         }
      });
      window.location.href = '/product?search='+ arrStr + '&multi=true';
      /*var arrStr = [];
      searchArr.map((x, i) => {
         arrStr.push(x.search);
      });
      var arrStr = encodeURIComponent(JSON.stringify(arrStr));*/
      /*var arrStr = '';
      searchArr.map((x, i) => {
         if(i == searchArr.length-1){
            arrStr += x.search;
         } else {
            arrStr += x.search+',';
         }
      });
      window.location.href = '/product?search='+ arrStr*/
   }

   const handleChange = (e) => {
      if(e.target.name=='multiRowSearch'){
         setHeaderFilter(e.target.value);
      } else if(e.target.name="multiSearchBox"){
         setMultiHeaderFilter(e.target.value);
      }
   }

   const handleSearchChange = (e, index) => {
      const { name, value } = e.target;
      const list = [...searchArr];
      list[index][name] = value;
      setSearchArr(list);
  }

   const handleAddClick = () => {
      setSearchArr([...searchArr, {'search':""}]);
   }

   const handleRemoveClick = index => {
      const list = [...searchArr];
      list.splice(index, 1);
      setSearchArr(list);
      //this.setState({ searchArr: list});
   };

   useEffect(() => {
      if(isMultiSearch){
         var splitArr = searchParam.split(",");
         var arrStr = '';
         splitArr.map((x, i) => {
            if(i == splitArr.length-1){
               arrStr += x;
            } else {
               arrStr += x+'\n';
            }
         });
         setMultiHeaderFilter(arrStr);
         $('#multiSearchButton .fa-bars').toggleClass("fa-minus", "slow");
         $('#multiRowSearch').toggleClass("hide", "slow");
         $('#multiSearchBox').toggleClass("hide", "slow");
      } else {
         setHeaderFilter(searchParam);
      }
   }, []);

   /*useEffect(() => {
      updateTotalPrice();
   }, [removeFromCart])*/

   return (
      <React.Fragment>
         {/*<section className="section-bootstrap-header">
            <nav className="navbar navbar-expand-lg navbar-light whiteHeader wrapBox">
               <div className="lowerMenuWrapper fullWidth" id="header">
                  <div className="container py-lg-3">
                     <a className="navbar-brand" href="/">
                     <img src="assets/images/logo.png" alt="PARTSNEXT" style={{height:"35px"}} className="brandSign" />
                     </a>
                     <div className="searchBoxWrapper">
                        <form onSubmit={(e) => formSubmit(e)} className="form-inline position-relative my-2 my-lg-0 searchBox">
                           <input className="form-control search" id="search" type="search" placeholder="Search here..." aria-label="Search" required="" onChange={(e) => handleChange(e)} value={headerFilter}/>
                           <button className="btn btn-search position-absolute" type="submit"><span className="fa fa-search" aria-hidden="true"></span></button>
                        </form>
                        <div className="multiRowSearch" id="multiSearchBox">
                           <form onSubmit={(e) => formMultipleSubmit(e)}>   
                              <textarea id="multi-search" name="multi-search" className="searchTerm" placeholder="Multiple search" onChange={(e) => handleChange(e)} value={headerFilter}></textarea>
                              <button type="submit">{'Search'}</button>
                           </form>
                         </div>
                         <button className="btn btn-search multiSearch" type="submit" id="multiSearchButton" title="Multi Row search">
                            <span className="fa fa-list-ul" aria-hidden="true"></span>
                         </button>
                     </div>
                     <div className="loginBoxWrapper">
                        <ul className="flex">
                           <li className="nav-item">
                              <a className="nav-link" href="/cart">
                                 <i className="fa fa-shopping-cart"></i>
                                 <span>Cart</span>
                              </a>
                           </li>
                           <li className="nav-item">
                              {localStorage.getItem('accessToken') ?
                                 <div className="btn-group">
                                    <NavLink className="nav-link dropdown-toggle" to="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                       <i className="fa fa-user"></i>
                                       <span>My Account</span>
                                    </NavLink>
                                    <div className="dropdown-menu">
                                       <a className="dropdown-item" href="/profile">Profile</a>
                                       <a className="dropdown-item" href="/orderlist">Order List</a>
                                       //<a className="dropdown-item" href="/accountdetails">Account Details</a>
                                       <div className="dropdown-divider"></div>
                                       <a className="dropdown-item" onClick={e => logout(e)} style={{cursor:'pointer'}}>Log out</a>
                                    </div>
                                 </div> :
                                 <a className="nav-link" href="/login">
                                    <i className="fa fa-user"></i>
                                    <span>Login</span>
                                 </a>
                              }
                           </li>
                        </ul>
                     </div>
                     <div className="sidebarWrapper">
                        <div className="menubtn">
                           <span className="fa fa-bars"></span>
                        </div>
                        <nav className="sidebar">
                           <div className="menubtn">
                               <span className="fa fa-close"></span>
                            </div>
                           <ul className="mt-5">
                              <li className="active"><NavLink to="/">Home</NavLink></li>
                              <li><NavLink to="/about">About Us</NavLink></li>
                              <li><NavLink to="/product">Products</NavLink></li>
                              <li><NavLink to="/blog">Latest News</NavLink></li>
                              <li><NavLink to="/contact">Contact</NavLink></li>
                           </ul>
                        </nav>
                     </div>
                  </div>
               </div>
            </nav>
         </section>*/}
         <section className="section-bootstrap-header">
            <nav className="navbar navbar-expand-lg navbar-light whiteHeader wrapBox">
               <div className="row fullWidth upperMenuWrapper">
                  <div className="flex align-items-center container">
                     <div className="col-md-8 col-sm-12 nopadding">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#upperMenu" aria-controls="upperMenu" aria-expanded="false" aria-label="Toggle navigation">
                           <span className="navbar-toggler-icon"><i className="fa fa-bars"></i></span>
                        </button>
                        <div className="collapse navbar-collapse upperMenu" id="upperMenu">
                           <ul className="navbar-nav mr-auto py-4 py-md-0">
                              <li className="nav-item pl-4 pl-md-0 active">
                                 <a className="nav-link" href="/">Home</a>
                              </li>
                              <li className="nav-item pl-4 pl-md-0">
                                 <a className="nav-link" href="/about">About Us</a>
                              </li>
                              <li className="nav-item pl-4 pl-md-0">
                                 <a className="nav-link" href="/product">Products</a>
                              </li>
                              <li className="nav-item pl-4 pl-md-0">
                                 <a className="nav-link" href="/blog">Latest News</a>
                              </li>
                              <li className="nav-item pl-4 pl-md-0">
                                 <a className="nav-link" href="/contact">Contact</a>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div className="col-md-4 col-sm-12 mobileVisibility">
                        <h6 className="text-right">+971 57 345 54545</h6>
                     </div>
                  </div>
               </div>
               <div className="lowerMenuWrapper fullWidth" id="header">
                  <div className="container py-lg-3">
                     <a className="navbar-brand" href="/">
                     <img src={base_path+"/assets/images/logo.png"} alt="PARTSNEXT" style={{height:"35px"}} className="brandSign" />
                     </a>
                     <div className="searchBoxWrapper flex">
                        <form onSubmit={(e) => formSubmit(e)} className="form-inline my-2 my-lg-0 searchBox flex">
                           <input className="form-control search" id="multiRowSearch" name="multiRowSearch" type="text" placeholder="Search here..." aria-label="Search" required="" autoComplete="off" onChange={(e) => handleChange(e)} value={headerFilter}/>
                           <textarea className="searchTerm" id="multiSearchBox" name="multiSearchBox" placeholder="Multiple search" onChange={(e) => handleChange(e)} value={multiHeaderFilter}></textarea>
                           <button type="button" className="btn multiSearch" id="multiSearchButton" title="Multi Row search">
                              <span className="fa fa-bars" aria-hidden="true"></span>
                           </button>
                        </form>
                        <div className="buttonBox ml-auto my-2 my-lg-0">
                           <button className="btn singlesearch" type="submit" onClick={(e) => formSubmit(e)}><span className="fa fa-search" aria-hidden="true"></span></button>
                        </div>
                     </div>
                     <div className="loginBoxWrapper">
                        <ul className="flex">
                           <li className="nav-item">
                              <a className="nav-link" href="/cart">
                              <i className="fa fa-shopping-cart"></i>
                              <span>Cart</span>
                              </a>
                           </li>
                           <li className="nav-item">
                              {localStorage.getItem('accessToken') ?
                              <div className="btn-group">
                                 <a className="nav-link dropdown-toggle" href="/login" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-user"></i>
                                    <span>My Account</span>
                                 </a>
                                 <div className="dropdown-menu">
                                    <a className="dropdown-item" href="/profile">Profile</a>
                                    <a className="dropdown-item" href="/orderlist">Order List</a>
                                    <a className="dropdown-item" href="/accountdetails">Account Details</a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" href="/changepassword">Change Password</a>
                                    <a className="dropdown-item" onClick={e => logout(e)} style={{cursor:'pointer'}}>Log out</a>
                                 </div>
                              </div>:
                              <a className="nav-link" href="/login">
                                 <i className="fa fa-user"></i>
                                 <span>Login</span>
                              </a>
                              }
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </nav>
         </section>
      </React.Fragment>
   )
}

const mapStateToProps = (state) => ({
   isAuthenticated: state.auth.isAuthenticated,
   requestUIHandler: state.requestUIHandler
})

const mapDispatchToProps = dispatch => bindActionCreators(
   {
      logoutWatcher
   }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
