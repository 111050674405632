import React from 'react'

const ThankYou = () => {
    return (
        <React.Fragment>
            <section class="profileBox backBox orderDetailsBox">
                <div class="profileWrapper container">
                    <div class="thankBox">
                        <img src="assets/images/cartIcon.png" class="img-responsive cartImage" alt="" />
                        <h3 class="my-4">Thank You For Shopping.</h3>
                        <a class="btn btn-theme1 my-2" href="/product">Continue Shopping</a>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default ThankYou;