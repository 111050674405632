// UI Handler Watcher Actions
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_UI = 'LOADING_UI';

//  Auth Watcher Actions
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const LOGOUT = 'LOGOUT';

export const LOG_IN_WATCHER = 'LOG_IN_WATCHER';
export const SIGN_UP_WATCHER = 'SIGN_UP_WATCHER'
export const LOG_OUT_WATCHER = 'LOG_OUT_WATCHER';
export const FORGET_PWD_WATCHER = 'FORGET_PWD_WATCHER';
export const CHECK_VALID_TOKEN_WATCHER = 'CHECK_VALID_TOKEN_WATCHER';
export const RESET_PWD_WATCHER = 'RESET_PWD_WATCHER';
export const CHANGE_PWD_WATCHER = 'CHANGE_PWD_WATCHER';
export const GET_USER_PROFILE_WATCHER = 'GET_USER_PROFILE_WATCHER';
export const UPDATE_USER_PROFILE_WATCHER = 'UPDATE_USER_PROFILE_WATCHER';
export const LOG_IN_AS_USER_WATCHER = 'LOG_IN_AS_USER_WATCHER';
export const CHECK_EMAIL_WATCHER = 'CHECK_EMAIL_WATCHER';

// Auth Reducer Actions
export const FILL_USER_PROFILE = 'FILL_USER_PROFILE';
export const FILL_USER_DATA = 'FILL_USER_DATA';
export const CHANGE_USER_PROFILE = 'CHANGE_USER_PROFILE';
export const AUTH_ERROR = 'AUTH_ERROR';
export const CLEAR_SESSION = 'CLEAR_SESSION';

// Manage Admin Users Watcher Actions
export const MANAGE_ADMIN_USERS_WATCHER = 'MANAGE_ADMIN_USERS_WATCHER';
export const CHANGE_ADMIN_USER_STATUS_WATCHER = 'CHANGE_ADMIN_USER_STATUS_WATCHER';
export const GET_ADMIN_USER_WATCHER = 'GET_ADMIN_USER_WATCHER';
export const GET_ADD_ADMIN_USER_WATCHER = 'GET_ADD_ADMIN_USER_WATCHER';
export const ADD_ADMIN_USER_WATCHER = 'ADD_ADMIN_USER_WATCHER';
export const UPDATE_ADMIN_USER_WATCHER = 'UPDATE_ADMIN_USER_WATCHER';
export const DELETE_ADMIN_USER_WATCHER = 'DELETE_ADMIN_USER_WATCHER';
export const RESET_ADMIN_USER_PWD_WATCHER = 'RESET_ADMIN_USER_PWD_WATCHER';

// Admin Users Reducer Actions
export const FILL_ADMIN_USERS_DATATABLE = 'FILL_ADMIN_USERS_DATATABLE';
export const FILL_ADD_ADMIN_USER = 'FILL_ADD_ADMIN_USER';
export const FILL_ADMIN_USER = 'FILL_ADMIN_USER';
export const SET_ADMIN_USERS_ERRORS = 'SET_ADMIN_USERS_ERRORS';
export const CLEAR_ADMIN_USERS_ERRORS = 'CLEAR_ADMIN_USERS_ERRORS';

// Admin Users Duplicate Check Watcher Actions
export const CHECK_ADMIN_USER_EMAIL_WATCHER = 'CHECK_ADMIN_USER_EMAIL_WATCHER';
export const CHECK_ADMIN_USER_EMAIL_IN_EDIT_WATCHER = 'CHECK_ADMIN_USER_EMAIL_IN_EDIT_WATCHER';

// Manage Customers Watcher Actions
export const MANAGE_CUSTOMERS_WATCHER = 'MANAGE_CUSTOMERS_WATCHER';
export const CHANGE_CUSTOMER_STATUS_WATCHER = 'CHANGE_CUSTOMER_STATUS_WATCHER';
export const GET_CUSTOMER_WATCHER = 'GET_CUSTOMER_WATCHER';
export const GET_ADD_CUSTOMER_WATCHER = 'GET_ADD_CUSTOMER_WATCHER';
export const ADD_CUSTOMER_WATCHER = 'ADD_CUSTOMER_WATCHER';
export const UPDATE_CUSTOMER_WATCHER = 'UPDATE_CUSTOMER_WATCHER';
export const DELETE_CUSTOMER_WATCHER = 'DELETE_CUSTOMER_WATCHER';
export const RESET_CUSTOMER_PWD_WATCHER = 'RESET_CUSTOMER_PWD_WATCHER';

// Customer Reducer Actions
export const FILL_CUSTOMERS_DATATABLE = 'FILL_CUSTOMERS_DATATABLE';
export const FILL_ADD_CUSTOMER = 'FILL_ADD_CUSTOMER';
export const FILL_CUSTOMER = 'FILL_CUSTOMER';
export const SET_CUSTOMERS_ERRORS = 'SET_CUSTOMERS_ERRORS';
export const CLEAR_CUSTOMERS_ERRORS = 'CLEAR_CUSTOMERS_ERRORS';

// Customers Duplicate Check Watcher Actions
export const CHECK_CUSTOMER_EMAIL_WATCHER = 'CHECK_CUSTOMER_EMAIL_WATCHER';
export const CHECK_CUSTOMER_EMAIL_IN_EDIT_WATCHER = 'CHECK_CUSTOMER_EMAIL_IN_EDIT_WATCHER';

// Manage Suppliers Watcher Actions
export const MANAGE_SUPPLIERS_WATCHER = 'MANAGE_SUPPLIERS_WATCHER';
export const CHANGE_SUPPLIER_STATUS_WATCHER = 'CHANGE_SUPPLIER_STATUS_WATCHER';
export const GET_SUPPLIER_WATCHER = 'GET_SUPPLIER_WATCHER';
export const GET_ADD_SUPPLIER_WATCHER = 'GET_ADD_SUPPLIER_WATCHER';
export const ADD_SUPPLIER_WATCHER = 'ADD_SUPPLIER_WATCHER';
export const UPDATE_SUPPLIER_WATCHER = 'UPDATE_SUPPLIER_WATCHER';
export const DELETE_SUPPLIER_WATCHER = 'DELETE_SUPPLIER_WATCHER';
export const RESET_SUPPLIER_PWD_WATCHER = 'RESET_SUPPLIER_PWD_WATCHER';

// Suppliers Reducer Actions
export const FILL_SUPPLIERS_DATATABLE = 'FILL_SUPPLIERS_DATATABLE';
export const FILL_ADD_SUPPLIER = 'FILL_ADD_SUPPLIER';
export const FILL_SUPPLIER = 'FILL_SUPPLIER';
export const SET_SUPPLIERS_ERRORS = 'SET_SUPPLIERS_ERRORS';
export const CLEAR_SUPPLIERS_ERRORS = 'CLEAR_SUPPLIERS_ERRORS';

// Suppliers Duplicate Check Watcher Actions
export const CHECK_SUPPLIER_EMAIL_WATCHER = 'CHECK_SUPPLIER_EMAIL_WATCHER';
export const CHECK_SUPPLIER_EMAIL_IN_EDIT_WATCHER = 'CHECK_SUPPLIER_EMAIL_IN_EDIT_WATCHER';

// Manage Suppliers Watcher Actions
export const MANAGE_PRODUCTS_WATCHER = 'MANAGE_PRODUCTS_WATCHER';
export const CHANGE_PRODUCT_STATUS_WATCHER = 'CHANGE_PRODUCT_STATUS_WATCHER';
export const GET_PRODUCT_WATCHER = 'GET_PRODUCT_WATCHER';
export const GET_ADD_PRODUCT_WATCHER = 'GET_ADD_PRODUCT_WATCHER';
export const ADD_PRODUCT_WATCHER = 'ADD_PRODUCT_WATCHER';
export const UPDATE_PRODUCT_WATCHER = 'UPDATE_PRODUCT_WATCHER';
export const DELETE_PRODUCT_WATCHER = 'DELETE_PRODUCT_WATCHER';
export const RESET_PRODUCT_PWD_WATCHER = 'RESET_PRODUCT_PWD_WATCHER';

// Products Reducer Actions
export const FILL_PRODUCTS_DATATABLE = 'FILL_PRODUCTS_DATATABLE';
export const FILL_ADD_PRODUCT = 'FILL_ADD_PRODUCT';
export const FILL_PRODUCT = 'FILL_PRODUCT';
export const SET_PRODUCTS_ERRORS = 'SET_PRODUCTS_ERRORS';
export const CLEAR_PRODUCTS_ERRORS = 'CLEAR_PRODUCTS_ERRORS';

// Products Duplicate Check Watcher Actions
export const CHECK_PRODUCT_EMAIL_WATCHER = 'CHECK_PRODUCT_EMAIL_WATCHER';
export const CHECK_PRODUCT_EMAIL_IN_EDIT_WATCHER = 'CHECK_PRODUCT_EMAIL_IN_EDIT_WATCHER';

export const MANAGE_CART_WATCHER = 'MANAGE_CART_WATCHER';
export const FILL_CART = 'FILL_CART';
export const SET_CART_ERRORS = 'SET_CART_ERRORS';
export const CLEAR_CART_ERRORS = 'CLEAR_CART_ERRORS';
