import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ProductCard from '../components/ProductCard';
import axios from 'axios'
import { base_url, customer_base_url, download_file_url } from '../utils/axios';
import { cartWatcher } from '../redux/actionCreators/cartActions'
import $ from 'jquery';
import { showNotification } from "../utils/utils";
import { translationStrings} from '../utils/locales';
import readXlsxFile from 'read-excel-file'
import * as constants from '../constants/constants';

const Product = (props) => {

    const [multiCart, setMultiCart] = React.useState([]);
    const [offset, setOffset] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [sortOrder, setSortOrder] = React.useState(0);
    const [sortField, setSortField] = React.useState(null);
    const [globalFilter, setGlobalFilter] = React.useState("");
    const [headerFilter, setHeaderFilter] = React.useState([]);
    const [rows, setRows] = React.useState(250);
    const [totalPageCount, setTotalPageCount] = React.useState(0);
    const [previousDisable, setPreviousDisable] = React.useState(true);
    const [nextDisable, setNextDisable] = React.useState(true);
    const [fetchedProductDetails, setFetchedProductDetails] = React.useState([]);
    const [check, setCheck] = React.useState(false);
    const [searchFile, setSearchFile] = React.useState(null);
    const [uploadError, setUploadError] = React.useState(false);
    const [chkChecked, setChkChecked] = React.useState([]);
    const [checkedItems, setCheckedItems] = React.useState([]);
    const [download, setDownload] = React.useState(false);
    const [isExcelSearch, setIsExcelSearch] = React.useState(false);
    const [excelData, setExcelData] = React.useState([]);

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let isMultiSearch = params.get('multi');
    let excelArr = [];

    const input = document.getElementById('excel_search');

    if(input){
        input.addEventListener('change', () => {
          readXlsxFile(input.files[0]).then((rows) => {
              setExcelData(rows);
            // `rows` is an array of rows
            // each row being an array of cells.
          })
        })
    }

    async function mapOrder (array, order) {
        var sortedArray = [];
        /* back up 23-dec-2022 */
        // if(order){
        //     order.forEach(orderNo => {
        //       sortedArray = sortedArray.concat(array.filter(item => item.part_number === orderNo));
        //     });
        // }
        if(order){
            order.forEach(orderNo => {
                let order_new = orderNo.replace(/[^a-zA-Z0-9]/g, "");
                let order = order_new.trim();
                sortedArray = sortedArray.concat(array.filter(item => item.search_part_number === order));
            });
        }

        return sortedArray;
    };

    const fetchProduct = () => {
        const headers = {
            'x-auth-token': localStorage.getItem('accessToken')
        };

        // Set Defined Values
        setRows(250);
        setSortOrder(-1);

        const FetchProductRequest = {
            event: {
                first: offset,
                rows: rows,
                page: page,
                pageCount: 1,
                sortOrder: sortOrder,
                sortField: sortField,
                filters: {},
                globalFilter: globalFilter,
                customFilters: "",
                isFront: true
            }
        }

        axios.post(customer_base_url + 'supplier-stock/list', FetchProductRequest, { headers })
            .then(response => {
                const productArr = response.data.data;
                if(globalFilter instanceof Array){
                    mapOrder(productArr, globalFilter).then(results => {
                        setFetchedProductDetails(results);
                        setTotalPageCount(Math.ceil(response.data.recordsTotal / rows))
                    });
                } else {
                    setFetchedProductDetails(productArr);
                    setTotalPageCount(Math.ceil(response.data.recordsTotal / rows))
                }
            });
    }

    const onChange = e => setGlobalFilter(e.target.value)

    const paginate = (pageId) => {
      var arr = document.getElementsByClassName('page-item');
      for (var i = 0; i < arr.length; i++) {
         arr[i].classList.remove('active');
      }

      document.getElementById('pagination_button_' + pageId).classList.add('active');
      if (pageId < 1) {
         setPreviousDisable(true);
         setOffset(0);
      } else {
         setPreviousDisable(false);
         setRows(250);
         setOffset(parseInt(pageId * rows));
      }

      if (pageId + 1 == totalPageCount) {
         setNextDisable(true);
      } else {
         setNextDisable(false);
      }

      setPage(pageId + 1);

      setCheck(true);
   }

   const previousClick = () => {
      let newPage;
      var arr = document.getElementsByClassName('page-item');
      for (var i = 0; i < arr.length; i++) {
         arr[i].classList.remove('active');
      }
      if (page == 2) {
         setPreviousDisable(true);
         setOffset(0);
         newPage = 1;
         let newPaginateElement = newPage - 1;
         document.getElementById('pagination_button_' + newPaginateElement).classList.add('active');
      } else {
         newPage = page - 1;
         let newPaginateElement = newPage - 1;
         document.getElementById('pagination_button_' + newPaginateElement).classList.add('active');
         setOffset(parseInt((newPaginateElement) * 250));
      }

      setPage(newPage);


      if ((page - 1) == totalPageCount) {
         setNextDisable(true);
      } else {
         setNextDisable(false);
      }

      setRows(250);
      setCheck(true);
   }

   const nextClick = () => {
      let newPage;
      var arr = document.getElementsByClassName('page-item');
      for (var i = 0; i < arr.length; i++) {
         arr[i].classList.remove('active');
      }
      if (page == 0) {
         if (page + 1 !== totalPageCount) {
            newPage = 1;
            setPage(newPage);
            document.getElementById('pagination_button_' + newPage).classList.add('active');
            setOffset(0);
         }
      } else {
         newPage = page + 1;
         let newPaginateElement = newPage - 1;
         document.getElementById('pagination_button_' + newPaginateElement).classList.add('active');
         setOffset(parseInt((newPaginateElement) * 250));
         setPage(newPage);
         setPreviousDisable(false);
      }

      if ((page + 1) == totalPageCount) {
         setNextDisable(true);
      } else {
         setNextDisable(false);
      }

      setRows(250);
      setCheck(true);
   }

    const selectAll = (e) => {
        let currentText = e.target.innerHTML;
        if(fetchedProductDetails.length > 0){
            setChkChecked([]);
            setCheckedItems([]);
            if(currentText=='Select All'){
                let itemObj = {};
                let chkItems = [...chkChecked];
                let chkItemObjects = [...checkedItems];
                fetchedProductDetails.map((item,index) => {
                    if(item.available_qty > 0 && item.description && item.weight > 0 && item.unit_price > 0){
                        document.getElementById('checkbox'+item.id).checked = true;
                        chkItems.push(item.id);
                        itemObj = item;
                        let qtyBoxId = 'product_quantity_input'+item.id;
                        itemObj.buy_qty = document.getElementById(qtyBoxId).value;
                        chkItemObjects.push(itemObj);
                    }
                });
                setChkChecked(chkItems);
                setCheckedItems(chkItemObjects);
                e.target.innerHTML = 'Remove All';
            } else {
                e.target.innerHTML = 'Select All';
                document.getElementsByClassName('').checked = false;
            }
        }
    }

    const addMultiCart = (e) => {
        setMultiCart([]);
        let itemArr = [];
        let currentCartText = e.target.innerHTML;
        if(currentCartText=='Add to Cart'){
            if(checkedItems.length > 0){
                checkedItems.map((item,index) => {
                    if(item.buy_qty > 0 ){
                        item.calc_price = calcPrice(item);
                        item.sub_price = parseFloat(item.calc_price * item.buy_qty).toFixed(2);
                        let calcTax = parseFloat(item.sub_price * (constants.TAX_PERCENTAGE/100));
                        item.tax_amount = calcTax.toFixed(2);
                        let calcFinalAmount = parseFloat(item.calc_price * item.buy_qty) + parseFloat(calcTax);
                        item.final_amount = calcFinalAmount.toFixed(2);
                    }
                    itemArr.push(item);
                });
            }

            setMultiCart((currentMultiCart) => [...currentMultiCart, itemArr]);
            showNotification('success', 'Success', translationStrings.cart_add_msg);
            e.target.innerHTML = 'Clear Cart';
        } else {
            e.target.innerHTML = 'Add to Cart';
            setMultiCart([]);
            new Promise((resolve, reject) => {
                props.cartWatcher([], resolve, reject)
            }).catch(e => { })
            showNotification('danger', 'Success', translationStrings.cart_remove_msg);
        }

        /*$(chkChecked).each(function(i, val){
            let currentProductId = val;
            if(fetchedProductDetails.length > 0){
                fetchedProductDetails.map((item,index) => {
                    if(item.id==currentProductId){
                        item.buy_qty = parseInt(document.getElementById("product_quantity_input" + item.id).value);
                        if(item.buy_qty > 0 ){
                            item.calc_price = calcPrice(item);
                            item.sub_price = parseFloat(item.calc_price * item.buy_qty).toFixed(2);
                        }
                        itemArr.push(item);
                    }
                });
            }
        });*/


    }

    const calcPrice = (item) => {
        if(item.discount_percentage && item.discount_percentage!==null){
            let calcAmount = parseFloat(item.unit_price*((100+item.discount_percentage)/100));
            return calcAmount.toFixed(2);
        } else {
            return item.final_unit_price.toFixed(2);
        }
    }

    const handleChange = (e) => {
        setSearchFile(e.target.files[0]);
    }

    const handleCheck = (e, itemObj, id) => {
        let target = e.target;
        let chkItems = [...chkChecked];
        let chkItemObjects = [...checkedItems];
        if(e.target.checked){
            if(!chkItems.includes(id)){
                chkItems.push(id);
                let qtyBoxId = 'product_quantity_input'+id;
                itemObj.buy_qty = document.getElementById(qtyBoxId).value;
                chkItemObjects.push(itemObj);
            }
        } else {
            var filteredArr = chkItems.filter(function(val) { return val !== id });
            chkItems = filteredArr;
            var filteredObjects = chkItemObjects.filter(function(val) { return val.id !== id });
            chkItemObjects = filteredObjects;

        }
        setChkChecked(chkItems);
        setCheckedItems(chkItemObjects);
    }

    const handleExcelSubmit = (e) => {
        e.preventDefault();
        if(searchFile===null){
            setUploadError(true);
          } else {
            setUploadError(false);
            let formData = new FormData();
            formData.append('file', searchFile, searchFile.name);

            formData.append('event', JSON.stringify({
                first: offset,
                rows: rows,
                page: page,
                pageCount: 1,
                sortOrder: sortOrder,
                sortField: sortField,
                filters: {},
                globalFilter: globalFilter,
                customFilters: ""
            }));

            const headers = {
                'x-auth-token': localStorage.getItem('accessToken'),
                'Content-Type': 'multipart/form-data'
            };

            axios.post(customer_base_url + 'supplier-stock/list', formData, { headers })
                .then(response => {
                    setFetchedProductDetails(response.data.data);
                    setTotalPageCount(Math.ceil(response.data.recordsTotal / rows));
                    $('#btnCancelModal').click();
                    setDownload(true);
                    setIsExcelSearch(true);
                });
            }
    }

    const downloadQuotation = (e) => {
        const headers = {
            'x-auth-token': localStorage.getItem('accessToken')
        };

        let downloadData = [];
        fetchedProductDetails.map((productItem, index) => {
            if(document.getElementById("product_quantity_input" + productItem.id)){
                productItem.buy_qty = parseInt(document.getElementById("product_quantity_input" + productItem.id).value);
            } else {
                productItem.buy_qty = 0;
            }
            productItem.calc_price = calcPrice(productItem);
            productItem.sub_price = parseFloat(productItem.calc_price * productItem.buy_qty).toFixed(2);
            let calcTax = parseFloat(productItem.sub_price * (constants.TAX_PERCENTAGE/100));
            productItem.tax_amount = calcTax.toFixed(2);
            let calcFinalAmount = parseFloat(productItem.calc_price * productItem.buy_qty) + parseFloat(calcTax);
            productItem.final_amount = calcFinalAmount.toFixed(2);
            downloadData.push(productItem);
        });
        const downloadParams = {
            'search': downloadData
        }
        axios.post(customer_base_url + 'download-quotation', downloadParams, { headers, responseType: 'blob' })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Quotation.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    }

    useEffect(() => {
        if (page == totalPageCount) {
            setNextDisable(true);
        } else {
            setNextDisable(false);
        }
    },[totalPageCount]);

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let searchParam = params.get('search');
        if(searchParam){
            if(searchParam.includes(',')){
                searchParam = params.get('search').split(',');
            }

            if(Array.isArray(searchParam)){
                setHeaderFilter(searchParam[0]);
                setGlobalFilter(searchParam);
            } else {
                setHeaderFilter(searchParam);
                setGlobalFilter(searchParam);
            }
            setDownload(true);
        } else {
            setDownload(false);
        }
        if(check==true){
            fetchProduct();
        }
        setCheck(false);
    }, [check])

    useEffect(() => {
        setFetchedProductDetails([]);
        setTotalPageCount(0);
        setCheck(true);
    },[headerFilter.length]);

    useEffect(() => {
        if(multiCart.length > 0){
            new Promise((resolve, reject) => {
                props.cartWatcher(multiCart[0], resolve, reject)
            }).catch(e => { })
        }
    }, [multiCart])

    return (
        <React.Fragment>
            <section className="section-products">
                {/* quote section */}
                <div className="features" id="features">
                    <div className="container py-md-3">
                        <div className="fea-gd-vv text-center row mt-3 pt-3">
                            <div className="col-sm-3 mb-4 pr-0 text-left pl-0">
                            {(isExcelSearch || isMultiSearch) &&
                                <button type="button" className="btn btn-theme btn-multicart" onClick={(e) => selectAll(e)}>Select All</button>
                            }
                            </div>
                            <div className="col-sm-3 mb-4 pr-0 text-left pl-0">
                            {chkChecked.length > 0 &&
                                <button type="button" className="btn btn-theme btn-multicart" onClick={(e) => addMultiCart(e)}>Add to Cart</button>
                            }
                            </div>
                            {localStorage.getItem('accessToken') &&
                                <>
                                <div className="col-sm-3 mb-4 pr-0 text-right">
                                    {download &&
                                        <button type="button" className="btn btn-theme" onClick={(e) => downloadQuotation(e)}>Download Quotation</button>
                                    }
                                </div>
                                <div className="col-sm-3 mb-4 pr-0 text-right">
                                    <button type="button" className="btn btn-theme" data-toggle="modal" data-target="#excelSearchUploadModal">Upload Excel</button>
                                </div>
                                </>
                            }
                            <ProductCard
                                {...props}
                                imgPath="assets/images/product1.jpg"
                                productAll={fetchedProductDetails}
                                handleCheck={handleCheck}
                                chkChecked={chkChecked}
                                checkedItems={checkedItems}
                                setCheckedItems={setCheckedItems}
                                excelData={excelData}
                                isExcelSearch={isExcelSearch}
                            />
                        </div>
                    </div>
                </div>
                {/* features */}
            </section>
            <section className="paginationBox mb-4">
                {searchFile===null &&
                    <div className="container">
                        <div className="text-right">
                            <div style={{width:'100%'}}>
                            <nav aria-label="...">
                                <ul className="pagination">
                                    {previousDisable ?
                                        <li className="page-item disabled">
                                            <span className="page-link">Previous</span>
                                        </li> :
                                        <li className="page-item">
                                            <span className="page-link" style={{ cursor: 'pointer' }} onClick={() => previousClick()}>Previous</span>
                                        </li>
                                    }

                                    {
                                        Array.apply(null, { length: totalPageCount }).map((e, index) => {
                                            let buttonId = "pagination_button_" + index;
                                            return (
                                                index==0 ?
                                                <li className="page-item active" id={buttonId} key={index}><button className="page-link" style={{ cursor: 'pointer' }} onClick={() => paginate(index)}>{index + 1}</button></li>
                                                : <li className="page-item " id={buttonId} key={index}><button className="page-link" style={{ cursor: 'pointer' }} onClick={() => paginate(index)}>{index + 1}</button></li>
                                            )
                                        })
                                    }

                                    {nextDisable ?
                                        <li className="page-item disabled">
                                            <button className="page-link" style={{ cursor: 'pointer' }}>Next</button>
                                        </li> :
                                        <li className="page-item">
                                            <button className="page-link" style={{ cursor: 'pointer' }} onClick={() => nextClick()}>Next</button>
                                        </li>
                                    }
                                </ul>
                            </nav>
                            </div>
                        </div>
                    </div>
                }
                <div className="modal fade" id="excelSearchUploadModal" tabIndex="-1" role="dialog" aria-labelledby="excelSearchUploadModal" aria-hidden="true">
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Excel Search</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                               <form action="" method="post" encType="multipart/form-data" onSubmit={(e) => handleExcelSubmit(e)}>
                                  <div className="form-group">
                                     <input type="file" id="excel_search" name="excel_search" onChange={(e) => handleChange(e)}/>
                                  </div>
                                  {uploadError &&
                                      <label style={{color:'red'}}>Please select file</label>
                                  }
                                  <div className="form-group">
                                      <a href={download_file_url+'SearchPartExample.xlsx'}>Download Format</a>
                                  </div>
                                  <div className="form-group">
                                     <button type="submit" className="btn btn-primary">Search</button>&nbsp;&nbsp;
                                     <button type="submit" id="btnCancelModal" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                                  </div>
                               </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    requestUIHandler: state.requestUIHandler,
    cart: state.cart
})

const mapDispatchToProps = dispatch => bindActionCreators(
    { cartWatcher }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Product)