import * as actions from '../types';

// Worker triggering actionCreators
export function loginWatcher(session, resolve, reject) {
	//return { type: actions.LOG_IN_WATCHER, payload: session, resolve, reject };
	return { type: actions.LOG_IN_WATCHER, payload: session, resolve, reject };
}
export function signupWatcher(payload, resolve, reject) {
	return { type: actions.SIGN_UP_WATCHER, payload:payload, resolve, reject}
}
export function logoutWatcher() {
	return { type: actions.LOG_OUT_WATCHER, payload: null };
}
export function forgetPwdWatcher(payload, resolve, reject) {
	return { type: actions.FORGET_PWD_WATCHER, payload: payload, resolve, reject };
}
export function checkValidTokenWatcher(token, resolve, reject) {
	return { type: actions.CHECK_VALID_TOKEN_WATCHER, token: token, resolve, reject};
}
export function resetPwdWatcher(payload) {
	return { type: actions.RESET_PWD_WATCHER, payload: payload };
}
export function changePwdWatcher(payload, resolve, reject) {
	return { type: actions.CHANGE_PWD_WATCHER, payload: payload, resolve, reject };
}
export function getUserProfileWatcher(resolve,reject) {
	console.log('In Auth Actions');
	return { type: actions.GET_USER_PROFILE_WATCHER, payload: null, resolve, reject };
}
export function updateProfileWatcher(id, payload, resolve, reject) {
	return { type: actions.UPDATE_USER_PROFILE_WATCHER, id: id, payload: payload, resolve, reject };	
}

export function loginAsUserWatcher(id, resolve, reject) {
	//return { type: actions.LOG_IN_WATCHER, payload: session, resolve, reject };
	return { type: actions.LOG_IN_AS_USER_WATCHER, id: id, resolve, reject };
}

export function checkEmailWatcher(id, resolve,reject){
  return { type: actions.CHECK_EMAIL_WATCHER, id: id, resolve, reject};
}

// Redux state changing actionCreators

export function fillUserData(payload) {
  	return { type: actions.FILL_USER_DATA, payload: payload };
}

export function fillUserProfile(payload) {
  	return { type: actions.FILL_USER_PROFILE, payload: payload };
}

export function changeUserProfile(payload) {
  	return { type: actions.CHANGE_USER_PROFILE, payload: payload };
}

export function authError(error) {
	return { type: actions.AUTH_ERROR, payload: error };
}
export function clearSession() {
	return { type: actions.CLEAR_SESSION, payload: null };
}