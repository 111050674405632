import { all } from 'redux-saga/effects';
import {
  loginActionWatcher,
  signupActionWatcher,
  logoutActionWatcher,
  forgetPwdActionWatcher,
  checkValidTokenActionWatcher,
  resetPwdActionWatcher,
  changePwdActionWatcher,
  fetchProfileActionWatcher,
  updateProfileActionWatcher,
  loginAsUserActionWatcher,
  checkEmailWatcher
} from '../sagas/auth';

import {
  manageAdminUsersActionWatcher,
  changeAdminUserStatusActionWatcher,
  fetchAddAdminUserActionWatcher,
  addAdminUserActionWatcher,
  fetchAdminUserActionWatcher,
  updateAdminUserActionWatcher,
  deleteAdminUserActionWatcher,
  checkAdminUserEmailWatcher,
  checkAdminUserEmailInEditWatcher,
  resetAdminUserPwdActionWatcher
} from '../sagas/adminUsers';

import {
  cartActionWatcher
} from '../sagas/cart';

export default function* rootSaga() {
  yield all([
    loginActionWatcher(),
    signupActionWatcher(),
    loginAsUserActionWatcher(),
    checkEmailWatcher(),
    logoutActionWatcher(),
    forgetPwdActionWatcher(),
    checkValidTokenActionWatcher(),
    resetPwdActionWatcher(),
    changePwdActionWatcher(),
    fetchProfileActionWatcher(),
    updateProfileActionWatcher(),
    manageAdminUsersActionWatcher(),
    changeAdminUserStatusActionWatcher(),
    fetchAddAdminUserActionWatcher(),
    addAdminUserActionWatcher(),
    fetchAdminUserActionWatcher(),
    updateAdminUserActionWatcher(),
    deleteAdminUserActionWatcher(),
    checkAdminUserEmailWatcher(),
    checkAdminUserEmailInEditWatcher(),
    resetAdminUserPwdActionWatcher(),
    cartActionWatcher()
  ]);
}